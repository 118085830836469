import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Placement } from "react-bootstrap/Overlay";

interface IHelpPopoverProps {
  style?: React.CSSProperties;
  title: string;
  children: React.ReactNode;
  placement?: Placement;
}

export default ({
  style,
  title,
  children,
  placement = "top",
}: IHelpPopoverProps) => {
  const [show, setShow] = React.useState(false);
  const buttonRef = React.useRef(null);
  const popoverRef = React.useRef(null);
  React.useEffect(() => {
    function onMouseUp(event: MouseEvent) {
      // @ts-ignore
      if (popoverRef.current && popoverRef.current.contains(event.target)) {
        return;
      }
      setShow(false);
    }

    // Bind the event listener
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [buttonRef]);
  return (
    <>
      <FontAwesomeIcon
        icon={["far", "question-circle"]}
        role="button"
        className="ml-1"
        forwardedRef={buttonRef}
        onClick={() => setShow(!show)}
      />
      <Overlay target={buttonRef.current} show={show} placement={placement}>
        {(props) => (
          <Popover
            id={title}
            {...props}
            style={{
              ...props.style,
              ...style,
            }}
            // @ts-ignore
            ref={(ref) => {
              popoverRef.current = ref;
              props.ref(ref);
            }}
          >
            <Popover.Title as="h3">{title}</Popover.Title>
            <Popover.Content>{children}</Popover.Content>
          </Popover>
        )}
      </Overlay>
    </>
  );
};
