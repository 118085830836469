import { Button, Modal, Spinner, Form } from "react-bootstrap";
import React from "react";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import axios, { AxiosError } from "axios";
import { ajv, stxt as t } from "shared.wenckebachfonds.nl";
import localizeAjv from "ajv-i18n/localize/nl";
import { useParams } from "react-router-dom";

export default () => {
  const { addToast } = useToasts();
  const [show, setShow] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const handleClose = React.useCallback(() => {
    setInProgress(false);
    setShow(false);
  }, []);
  const { register, getValues } = useForm();
  const { public_edit_token } = useParams<{ public_edit_token: string }>();

  if (public_edit_token) {
    return null;
  }

  return (
    <React.Fragment>
      <Button
        variant="outline-primary"
        className="float-md-right my-3 my-sm-0"
        onClick={() => {
          setShow(true);
        }}
      >
        Bestaande aanvragen bekijken?
      </Button>
      <Modal show={show} onHide={handleClose}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const { email } = getValues();
            const emailAddressValid = ajv.validate(
              {
                type: "string",
                format: "email",
              },
              email
            ) as boolean;
            setInProgress(true);
            if (!emailAddressValid && ajv.errors) {
              localizeAjv(ajv.errors);
              setInProgress(false);
              addToast(
                ajv.errors
                  .reduce((prev: string[], ajvError) => {
                    prev.push(t(ajvError.message || "Controleer uw invoer"));
                    return prev;
                  }, [])
                  .join(" "),
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
              return;
            }
            axios
              .request({
                method: "post",
                url: "/claim/requestNewLink",
                data: {
                  email,
                },
              })
              .then(() => {
                setInProgress(false);
                addToast(
                  "Indien uw e-mailadres bekend is, heeft u een e-mail met gegevens ontvangen.",
                  {
                    appearance: "success",
                    autoDismiss: true,
                  }
                );
                handleClose();
              })
              .catch((err: AxiosError) => {
                setInProgress(false);
                addToast(
                  t(err.response?.data.message) ||
                    t(err.message) ||
                    "Aanvraag mislukt",
                  {
                    appearance: "error",
                    autoDismiss: true,
                  }
                );
              });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Heeft u al eerder een aanvraag gedaan?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {inProgress ? (
              <Spinner animation="border" />
            ) : (
              <React.Fragment>
                Heeft u al eerder aanvragen ingediend met een e-mailadres dan
                kunt u een kijk-link opvragen die naar uw e-mailadres wordt
                gestuurd.
                <hr />
                <Form.Group controlId="email">
                  <Form.Label>E-mailadres</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="bijv. info@wenckebachfonds.nl"
                    ref={(e: any) => {
                      register(e);
                      setTimeout(() => {
                        if (e) {
                          e.focus();
                        }
                      }, 1);
                    }}
                  />
                </Form.Group>
              </React.Fragment>
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <Button
              className="mr-auto"
              variant="secondary"
              onClick={handleClose}
              disabled={inProgress}
            >
              Annuleren
            </Button>
            <Button disabled={inProgress} variant="primary" type="submit">
              Vraag link aan
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </React.Fragment>
  );
};
