import { stxt as t } from "shared.wenckebachfonds.nl/dist/stxt";
import React, { Dispatch, SetStateAction } from "react";
import {
  ACTION_COLUMN_WIDTH,
  DEFAULT_COLUMN_WIDTH,
  ISchemaTableConfig,
  SELECT_COLUMN_WIDTH,
} from "../index";
import { SchemaObject } from "openapi3-ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-common-types";
import "./index.scss";

let lastPageX: number = 0;

interface IHeaderTrProps {
  isConfigurable?: boolean;
  isSelectable?: boolean;
  schema: SchemaObject;
  schemaTableConfig: ISchemaTableConfig;
  setConfig: Dispatch<SetStateAction<Partial<ISchemaTableConfig>>>;
}

export default ({
  isConfigurable,
  isSelectable,
  schema,
  schemaTableConfig,
  setConfig,
}: IHeaderTrProps) => {
  const [draggedColumnProp, setDraggedColumnProp] = React.useState<string>();
  const [resizedColumnProp, setResizedColumnProp] = React.useState<string>();
  const { properties } = schema;
  const { columnsConfig, sortBy, isSortAsc } = schemaTableConfig;

  /**
   * Start resizing
   */
  const onResizerMouseDown = React.useCallback(
    (e: any) => {
      const { currentTarget, pageX } = e;
      lastPageX = pageX;
      const { columnProp } = currentTarget.dataset;

      // Ensure the column has a "width" to calculate changes from
      setConfig((config) => ({
        ...config,
        columnsConfig: (config.columnsConfig || []).map((columnConfig) =>
          columnConfig.propName === columnProp
            ? {
                ...columnConfig,
                width:
                  columnConfig.width || currentTarget.parentNode.offsetWidth,
              }
            : columnConfig
        ),
      }));
      setResizedColumnProp(columnProp);
    },
    [setConfig]
  );
  const onWindowAndResizerMouseUp = React.useCallback((e) => {
    // do not trigger re-sort on Resizer mouseUp
    e.stopPropagation();
    setResizedColumnProp(undefined);
  }, []);
  const onDocumentMouseMove = React.useCallback(
    (e: any) => {
      const { pageX } = e;
      const diffX = pageX - lastPageX;
      lastPageX = pageX;
      if (!resizedColumnProp) {
        return;
      }
      setConfig((config) => ({
        ...config,
        columnsConfig: (config.columnsConfig || []).map((columnConfig) =>
          columnConfig.propName === resizedColumnProp && columnConfig.width
            ? {
                ...columnConfig,
                width: Math.max(80, columnConfig.width + diffX),
              }
            : columnConfig
        ),
      }));
    },
    [resizedColumnProp, setConfig]
  );

  /**
   * End Resizing
   */

  /**
   * Start Sorting
   */
  const onThClick = React.useCallback(
    (e: any) => {
      const { currentTarget } = e;
      const { columnProp } = currentTarget.dataset;
      if (sortBy === columnProp) {
        setConfig((config) => ({ ...config, isSortAsc: !isSortAsc }));
        return;
      }
      setConfig((config) => ({ ...config, sortBy: columnProp }));
    },
    [isSortAsc, setConfig, sortBy]
  );
  /**
   * End sorting
   */

  /**
   * Start Reordering
   */
  const onDragStart = React.useCallback((e) => {
    setDraggedColumnProp(e.currentTarget.dataset.columnProp);
  }, []);
  const onDragOver = React.useCallback((e) => e.preventDefault(), []);
  const onDrop = React.useCallback(
    (e) => {
      if (!draggedColumnProp) {
        return;
      }
      const droppedColumnProp = e.currentTarget.dataset.columnProp;
      const dragIndex = columnsConfig.findIndex(
        (columnConfig) => columnConfig.propName === draggedColumnProp
      );
      const dropIndex = columnsConfig.findIndex(
        (columnConfig) => columnConfig.propName === droppedColumnProp
      );
      if (dragIndex < 0 || dropIndex < 0) {
        return;
      }
      const column = columnsConfig.splice(dragIndex, 1);
      columnsConfig.splice(dropIndex, 0, column[0]);
      setConfig({
        ...schemaTableConfig,
        columnsConfig,
      });
    },
    [columnsConfig, draggedColumnProp, schemaTableConfig, setConfig]
  );
  /**
   * End Reordering
   */

  /**
   * Install event handlers and start render
   */
  React.useEffect(() => {
    if (isConfigurable) {
      document.addEventListener("mousemove", onDocumentMouseMove);
      window.addEventListener("mouseup", onWindowAndResizerMouseUp);
    }
    return () => {
      if (isConfigurable) {
        document.removeEventListener("mousemove", onDocumentMouseMove);
        window.removeEventListener("mouseup", onWindowAndResizerMouseUp);
      }
    };
  }, [isConfigurable, onDocumentMouseMove, onWindowAndResizerMouseUp]);

  return (
    <tr className="components__schema-table__header-tr">
      {isSelectable && <th style={{ width: SELECT_COLUMN_WIDTH }} />}
      {columnsConfig.map((columnConfig) => {
        const columnProp = columnConfig.propName;
        const propSchema = properties
          ? (properties[columnProp] as SchemaObject)
          : undefined;
        if (!propSchema) {
          return null;
        }
        return (
          <th
            data-column-prop={columnProp}
            key={columnProp}
            onClick={onThClick}
            style={{
              width: columnConfig.width || DEFAULT_COLUMN_WIDTH,
            }}
          >
            <span
              title={propSchema.description || t(columnProp)}
              data-column-prop={columnProp}
              draggable={isConfigurable}
              className="components__schema-table__header-tr__th--draggable"
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
            >
              {sortBy === columnProp && (
                <FontAwesomeIcon
                  icon={`sort-${isSortAsc ? "up" : "down"}` as IconName}
                  className="ml-1"
                />
              )}
              {propSchema.description || t(columnProp)}
            </span>
            {isConfigurable ? (
              <div
                className="components__schema-table__header-tr__th__resizer"
                data-column-prop={columnProp}
                onMouseDown={onResizerMouseDown}
                onMouseUp={onWindowAndResizerMouseUp}
              />
            ) : null}
          </th>
        );
      })}
      <th style={{ width: ACTION_COLUMN_WIDTH }}></th>
    </tr>
  );
};
