import React from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { FieldError, useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { ajv, openapi, stxt as t } from "shared.wenckebachfonds.nl";
import Spinner from "react-bootstrap/Spinner";
import ForgotPasswordControl from "./ForgotPasswordControl";
import { AuthContext } from "../../provider/AuthProvider";
import SchemaFormGroup from "../../components/SchemaFormGroup";
import { SchemaObject } from "openapi3-ts";
import { Link } from "react-router-dom";

interface IFormData {
  username: string;
  password: string;
}

const parentSchena = openapi.paths["/auth/login"].post.requestBody.content[
  "application/json"
].schema as SchemaObject;

const validationResolver = (values: IFormData) => {
  const isValid = ajv.validate(parentSchena, values);
  const errors: { [path: string]: FieldError } = {};
  if (!isValid && ajv.errors) {
    ajv.errors.forEach((ajvError) => {
      errors[ajvError.dataPath.substr(1)] = {
        type: "AjvError",
        message: t(ajvError.message || "Controleer uw invoer"),
      };
    });
  }
  return { values, errors };
};

export default () => {
  const { login } = React.useContext(AuthContext);
  const { register, handleSubmit, errors, watch } = useForm<IFormData>({
    validationResolver: validationResolver as any,
  });
  const [submitPending, setSubmitPending] = React.useState(false);
  const [submitError, setSubmitError] = React.useState("");

  const { properties, required = [] } = parentSchena;

  if (!properties) {
    throw new Error("Missing LoginRequest schema");
  }

  return (
    <div
      className="flex-grow-1 d-flex align-items-center justify-content-center"
      style={{
        background: "transparent url('/img/bodyBg.jpg') no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Card style={{ width: 400, padding: 16 }}>
        <div className="m-2">
          <Link to="/">
            <Card.Img
              variant="top"
              src="/img/stichting-wenckebachfonds-logo.png"
              alt="Portal Stichting Wenckebachfonds"
            />
          </Link>
        </div>
        <Card.Body>
          <Card.Title>Inloggen</Card.Title>
          {submitError && <Alert variant="danger">{submitError}</Alert>}
          <div className="card-text">
            {submitPending && (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}

            <Form
              onSubmit={handleSubmit((values) => {
                setSubmitError("");
                setSubmitPending(true);
                return login(values).catch((err: AxiosError) => {
                  setSubmitPending(false);
                  setSubmitError(
                    t(err.response?.data.message) || "Inloggen mislukt"
                  );
                });
              })}
            >
              {Object.keys(properties).map((propertyName) => (
                <SchemaFormGroup
                  key={propertyName}
                  register={register}
                  isRequired={required.indexOf(propertyName) >= 0}
                  schema={properties[propertyName] as SchemaObject}
                  name={propertyName}
                  error={errors[propertyName as "username"]}
                />
              ))}
              <div className="d-flex align-items-center mt-2">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={submitPending}
                >
                  Inloggen
                </Button>
                <ForgotPasswordControl username={watch("username")} />
              </div>
            </Form>
          </div>
          <div className="mt-4" style={{ color: "#CCC" }}>
            Built: {process.env.REACT_APP_BUILD_VERSION}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
