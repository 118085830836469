import { stxt as t } from "shared.wenckebachfonds.nl/dist/stxt";
import number_format from "locutus/php/strings/number_format";
import React from "react";
import { Components } from "shared.wenckebachfonds.nl";
import "./index.scss";

interface IPartTdBodyProps {
  part: Components.Schemas.DyslexiaClaimPart;
}

export default ({ part }: IPartTdBodyProps) => (
  <>
    {t(part.type)}
    {part.calculation && part.calculation.amount ? (
      <strong> € {number_format(part.calculation.amount, 2, ",", ".")}</strong>
    ) : null}
    {part.calculation && part.calculation.internalComment ? (
      <div className="components__schema-table__part-td-body__caclulation-internal-comment">
        {part.calculation.internalComment}
      </div>
    ) : null}
  </>
);
