import { Card, Container } from "react-bootstrap";
import ClaimForm from "./ClaimForm";
import React from "react";
import RequestLinkDialog from "./RequestLinkDialog";
import { Link } from "react-router-dom";

export default () => (
  <Container>
    <Card>
      <div className="card-header">
        <Link to="/">
          <img
            src="/img/stichting-wenckebachfonds-logo-dark.png"
            alt="Aanvraag Wenckebachfonds"
            className="img-fluid mb-5"
          />
        </Link>
        <RequestLinkDialog />
        <h3>Aanvraagformulier vergoeding Wenckebachfonds</h3>
      </div>
      <Card.Body>
        <ClaimForm />
      </Card.Body>
    </Card>
  </Container>
);
