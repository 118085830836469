import React from "react";
import { Alert, Button, Card, Spinner } from "react-bootstrap";
import { ApiContext } from "../../../provider/ApiProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import SchemaTable from "../../../components/SchemaTable";
import { Components, openapi } from "shared.wenckebachfonds.nl";
import mergeAllOf from "json-schema-merge-allof";
import { SchemaObject } from "openapi3-ts";
import { getInitialSchemaConfig } from "../../../components/SchemaTable/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { ClaimPart } from "../../../types/api";
import { rapCodeToLedgerNumber } from "../../../components/form/RapCodeFormControl";

export default () => {
  const { jwtUser } = React.useContext(AuthContext);
  const { claims, hydrateClaims } = React.useContext(ApiContext);
  const mergedClaimSchema = mergeAllOf(openapi.components.schemas.Claim);
  // @ts-ignore
  const ungroupedClaimSchema = {
    ...mergedClaimSchema,
    properties: {
      ...mergedClaimSchema.properties,
      part: {
        description: "Aanvraag",
        // @ts-ignore
        oneOf: mergedClaimSchema.properties.parts.items.oneOf,
      },
      parts: undefined,
    },
  } as SchemaObject;
  React.useEffect(() => {
    if (claims === undefined) {
      hydrateClaims();
    }
  }, [hydrateClaims, claims]);

  if (!claims || !jwtUser) {
    return <Spinner animation={"border"} />;
  }
  if (jwtUser.role !== "auditor" && jwtUser.role !== "administrator") {
    return (
      <div className="flex-grow-1">
        <Card>
          <Card.Body>
            <Alert variant="warning">
              <Alert.Heading>Onvoldoende rechten</Alert.Heading>
              <p>
                U dient een administrator te zijn voor het openen van
                rapportages
              </p>
            </Alert>
          </Card.Body>
        </Card>
      </div>
    );
  }
  return (
    <div className="flex-grow-1">
      <Card>
        <Card.Body>
          <SchemaTable
            isConfigurable={true}
            isFilterable={true}
            isExportable={true}
            config={getInitialSchemaConfig("UngroupedClaim")}
            schema={ungroupedClaimSchema}
            schemaTitle="UngroupedClaim"
            extraExportColumns={{
              "Grootboek nr": (row: typeof ungroupedClaimSchema) =>
                `${
                  rapCodeToLedgerNumber[row.part?.calculation?.rapCode] || ""
                }`,
            }}
            data={claims.reduce<any[]>((prev, claim) => {
              prev.push(
                ...claim.parts.map((part: ClaimPart) => ({
                  ...claim,
                  parts: undefined,
                  part,
                }))
              );
              return prev;
            }, [])}
            renderAction={(claim: Components.Schemas.Claim) => (
              <Link to={`/admin/claim/${claim.id}`}>
                <Button variant="primary" size="sm">
                  <FontAwesomeIcon
                    icon="file-alt"
                    fixedWidth
                    className="mr-2"
                  />
                  Openen
                </Button>
              </Link>
            )}
          />
        </Card.Body>
      </Card>
    </div>
  );
};
