import { Dropdown, Modal } from "react-bootstrap";
import UserSettingsForm from "./UserSettingsForm";
import React from "react";
import { ApiContext } from "../../../../provider/ApiProvider";
import { AuthContext } from "../../../../provider/AuthProvider";
import { ISchemaTableConfig } from "../../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ISaveButtonProps {
  schemaTitle: string;
  schemaTableConfig: ISchemaTableConfig;
}

export const SaveButton = ({
  schemaTableConfig,
  schemaTitle,
}: ISaveButtonProps) => {
  const { users } = React.useContext(ApiContext);
  const { jwtUser } = React.useContext(AuthContext);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = React.useState(false);
  const toggleSaveDialog = React.useCallback(() => {
    setIsSaveDialogOpen((open) => !open);
  }, []);
  if (!users || !jwtUser) {
    return null;
  }
  const user = users.find((user) => user.id === jwtUser.id);
  if (!user) {
    return null;
  }
  return (
    <>
      <Dropdown.Item size="sm" onClick={toggleSaveDialog}>
        <span
          className="fa-layers fa-fw mr-1 text-success"
          title="Opslaan als favoriet"
        >
          <FontAwesomeIcon icon="bookmark" fixedWidth className="mr-1" />
          <FontAwesomeIcon
            icon="plus"
            className="text-body"
            transform="shrink-4 down-1 right-5"
          />
        </span>
        Opslaan als favoriet
      </Dropdown.Item>
      <Modal show={isSaveDialogOpen} onHide={toggleSaveDialog}>
        <UserSettingsForm
          schemaTitle={schemaTitle}
          schemaTableConfig={schemaTableConfig}
          toggleSaveDialog={toggleSaveDialog}
        />
      </Modal>
    </>
  );
};
