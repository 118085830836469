import { Button } from "react-bootstrap";
import { download } from "../../../../../../inc/file";
import React from "react";

interface IDownloadButtonProps {
  fileId: string;
}

export default ({ fileId }: IDownloadButtonProps) => (
  <Button
    size="sm"
    className="m-1"
    onClick={() =>
      download({ id: fileId }).catch((err) => {
        window.alert(err.message);
      })
    }
  >
    Download
  </Button>
);
