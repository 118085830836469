import CreatableSelect from "react-select/creatable";
import React from "react";
import { FieldError } from "react-hook-form";
import { ValueType } from "react-select/src/types";

interface IRapCodeFormControlProps {
  disabled?: boolean;
  error?: FieldError;
  name: string;
  value?: number;
  onChange: (newValue?: number) => void;
}

export const rapCodeOptions: { [rapCode: number]: string } = {
  1006: "Thuiszorg 2006",
  1007: "Gezinszorg medisch",
  1059: "Pgb onbekend",
  1101: "Ploegentoeslag",
  1201: "Verspreiding van het gezin",
  1301: "Verzuimdagen",
  1302: "Verzuimuren",
  2001: "Acupunctuur",
  2002: "Homeopathie",
  2003: "Psychotherapie",
  2004: "Epilatie volgens blend methode",
  2006: "Manuele therapie",
  2008: "Flebologie",
  2009: "Osteopathie",
  2010: "Chiropractie",
  2012: "Chelatietherapie",
  2013: "Dyslexie / Dyscalculie",
  2014: "Senso-motorische therapie",
  2015: "Podotherapie",
  2016: "Prothese BH / Badpak",
  2017: "Bechterew",
  2018: "Kinesiologie",
  2019: "Natuurgeneeswijze",
  2020: "Fysiotherapie",
  2021: "Kuurreizen",
  2023: "Voedingssupplementen",
  2024: "Tinnitus",
  2099: "Overige onderzoeken en behandelingen",
  2101: "Verpleegkosten / instelling",
  2102: "Gepensioneerde verpleegkosten / instelling",
  2199: "Overige verpleegkosten",
  3001: "Fiets met electrische ondersteunde",
  3002: "Aanschaf en of aanpassing auto",
  3003: "Gehoorapparaten",
  3004: "Pruiken",
  3005: "Gebitsprothese",
  3006: "Orthopedische voorzieningen",
  3007: "Tandregulatie",
  3008: "Haartransplantatie",
  3009: "Steunzolen",
  3010: "Sta- op relaxfauteuil",
  3011: "Hoog- laagbed",
  3012: "Scootmobiel",
  3099: "Overige voorzieningen",
  4001: "Reiskosten buitenland ziekte gezinslid",
  4002: "Reiskosten buitenland overleden gezinslid",
  4099: "Reiskosten buitenland onbekend",
  4101: "Reiskosten Nederland bezoek gezinslid",
  4102: "Reiskosten Nederland onderwijs gezinslid",
  4103: "Verblijfkosten i.v.m. ziekte",
  4104: "Parkeerkosten",
  4105: "Mantelzorgreiskosten",
  4106: "Mantelzorgverblijfkosten",
  4199: "Reiskosten Nederland onbekend",
  5002: "Verhuiskosten",
  5005: "Brillenglazen",
  5006: "Dieetkostenen extra voeding",
  5099: "Overige buitengewone lasten",
  5101: "Leningen",
  5106: "Leningen kortlopend",
  5201: "Schenking i.v.m. ziekte",
  5202: "Kinderopvang",
  5203: "Begrafeniskosten pasgeboren baby",
  5204: "Hospice",
  5205: "Mantelzorgdagen",
  5207: "Vrijwilligerswerkdagen",
  5299: "Overige financiele moeilijkheden",
  5300: "Rente",
};
export const rapCodeToLedgerNumber: { [rapCode: number]: number } = {
  1006: 4301,
  1007: 4301,
  1059: 4301,
  1101: 4300,
  1201: 4304,
  1301: 4300,
  1302: 4300,
  2001: 4301,
  2002: 4301,
  2003: 4301,
  2004: 4301,
  2006: 4301,
  2008: 4301,
  2009: 4301,
  2010: 4301,
  2012: 4301,
  2013: 4301,
  2014: 4301,
  2015: 4301,
  2016: 4302,
  2017: 4301,
  2018: 4301,
  2019: 4301,
  2020: 4301,
  2021: 4301,
  2023: 4301,
  2024: 4301,
  2099: 4301,
  2101: 4306,
  2102: 4306,
  2199: 4306,
  3001: 4303,
  3002: 4303,
  3003: 4302,
  3004: 4302,
  3005: 4302,
  3006: 4302,
  3007: 4301,
  3008: 4302,
  3009: 4302,
  3010: 4303,
  3011: 4303,
  3012: 4303,
  3099: 4303,
  4001: 4305,
  4002: 4305,
  4099: 4305,
  4101: 4305,
  4102: 4305,
  4103: 4306,
  4104: 4305,
  4105: 4305,
  4106: 4306,
  4199: 4305,
  5002: 4304,
  5005: 4302,
  5006: 4301,
  5099: 4308,
  5101: 4307,
  5106: 4307,
  5201: 4304,
  5202: 4304,
  5203: 4304,
  5204: 4306,
  5205: 4304,
  5207: 4304,
  5299: 4307,
  5300: 4307,
};

interface IOption {
  value: number | string;
  label: string;
}

export default ({
  disabled,
  name,
  onChange,
  value,
}: IRapCodeFormControlProps) => (
  <CreatableSelect
    isDisabled={disabled}
    name={name}
    onChange={(newValue: ValueType<IOption>) => {
      const selectedOption = newValue as IOption;
      const rapCode =
        selectedOption && typeof selectedOption.value === "number"
          ? selectedOption.value
          : parseInt(`${selectedOption.value}`, 10);
      onChange(rapCode > 0 ? rapCode : undefined);
    }}
    options={Object.keys(rapCodeOptions).map((rapCode) => {
      const value = parseInt(rapCode, 10);
      return {
        value,
        label: `${value} - ${rapCodeOptions[value] || "NIEUW"}`,
      };
    })}
    value={
      value
        ? { value, label: `${value} - ${rapCodeOptions[value] || "NIEUW"}` }
        : null
    }
  />
);
