import SepaXML from "sepa-xml";
import { ClaimPart } from "../types/api";
import axios from "axios";
import Handlebars from "handlebars";
import { format } from "date-fns";
import { rapCodeOptions } from "../components/form/RapCodeFormControl";
import FileSaver from "file-saver";
import { Components } from "shared.wenckebachfonds.nl";
import sha256 from "node-forge/lib/sha256";
import sha1 from "node-forge/lib/sha1";

export const hash = (crypto: "sha1" | "sha256", message: string): string => {
  const shaCreator = (crypto === "sha1" ? sha1 : sha256).create();
  shaCreator.update(message);
  return shaCreator.digest().toHex().toUpperCase();
};

const constants = {
  SEPA_XML_FORMAT: "pain.001.001.03",
  WBF_BANK_ACCOUNT_NUMBER: "NL40BNPA0227682777",
  WBF_BANK_ACCOUNT_NAME: "TATA STEEL NL IJMUIDEN B.V.",
};

export const getPreviousApprovedClaims = (
  claim: Components.Schemas.Claim,
  claims: Components.Schemas.Claim[]
) =>
  claims.filter(
    (otherClaim) =>
      otherClaim.id !== claim.id &&
      ((claim.email !== "info@wenckebachfonds.nl" &&
        otherClaim.email.toLowerCase() === claim.email.toLowerCase()) ||
        otherClaim.personnelNumber === claim.personnelNumber)
  );

const getTransactionDescription = (
  claim: Components.Schemas.Claim,
  part: ClaimPart
) =>
  `WBF vergoeding inzake ${
    rapCodeOptions[part.calculation?.rapCode || 0] || part.type
  } - Aanvraag ${claim.id}`;

export const downloadPaymentFile = (
  claims: Components.Schemas.Claim[],
  paymentDate: Date,
  userId: string
): Promise<Components.Schemas.LoggedPayment> => {
  const sepaXml = new SepaXML(constants.SEPA_XML_FORMAT); // takes a single argument which is the format, default is 'pain.001.001.03'

  // overload built-in compile to load templates via axios.
  sepaXml.compile = (cb: any) => {
    sepaXml.verifyCurrentInfo((err: any) => {
      if (err) return cb(err);
      axios
        .get(
          `${window.location.protocol}//${window.location.host}/assets/${sepaXml.outputFormat}.hbs`
        )
        .then((result) => {
          const template = Handlebars.compile(result.data);
          cb(null, template(sepaXml.templateData()));
        });
    });
  };

  // This sets the header data in the file
  sepaXml.setHeaderInfo({
    messageId: `${format(paymentDate, `YmdHis`)}/${userId}`.substr(0, 27),
    initiator: constants.WBF_BANK_ACCOUNT_NAME,
  });

  sepaXml.setPaymentInfo({
    id: format(paymentDate, `YmdHis`),
    method: "TRF",
    batchBooking: true,
    senderName: constants.WBF_BANK_ACCOUNT_NAME,
    senderIBAN: constants.WBF_BANK_ACCOUNT_NUMBER,
    bic: "BNPANL2A",
  });

  claims.forEach((claim) => {
    claim.parts.forEach((part, index) => {
      if (!part.calculation) {
        return;
        // throw new Error(
        //   `missing calculation in claim(${claim.id}).part(${index})`
        // );
      }
      sepaXml.addTransaction({
        id: `${claim.id}.${index}`,
        amount: part.calculation.amount,
        name: claim.bankAccountName,
        // fix: https://jira.tools.kingsquare.nl/browse/WBF-7
        iban: claim.bankAccount.replace(/ /g, "").toUpperCase(),
        description: getTransactionDescription(claim, part),
      });
    });
  });

  const createConverter = () => {
    const charRange = (start: number, end: number) => Array.from(Array(end - start),
        (v, i) => String.fromCharCode(i + start));
    const digits = charRange(48, 48 + 10)// 0 to 9
        .concat(charRange(97, 97 + 26))  // a to Z
        .concat(charRange(65, 65 + 26)); // A to Z
    const base = digits.length;

    return function(decimal: number) {
      let result = "";
      while (decimal >= base) {
        result = digits[decimal % base] + result
        decimal = parseInt(String(decimal / base), 10)
      }
      result = digits[decimal] + result
      return result;
    }
  };

  return new Promise<Components.Schemas.LoggedPayment>((resolve, reject) => {
    sepaXml.compile((err: Error, out: string) => {
      if (err) {
        reject(err);
        return;
      }
      // WBF-11: unify line endings to WINDOWS line endings to prevent SHA collisions
      const cleanXmlString = out.replace("\r\n", "\n").replace("\n", "\r\n");
      const blob = new Blob([cleanXmlString], {
        type: "text/xml;charset=utf-8",
      });
      const convertor = createConverter();
      const filename = `TPY-WBF-SEPA-${convertor(Number(format(paymentDate, "t")))}-CCT`;
      FileSaver.saveAs(blob, filename);

      // this makes sure the SHAsum is correct when handling weird utf8 issues
      const binaryCleanXmlString = Buffer.from(
        cleanXmlString,
        "utf-8"
      ).toString("binary");

      resolve({
        filename,
        claimIds: claims.map((claim) => claim.id as string),
        checksum: {
          sha1: hash("sha1", binaryCleanXmlString),
          sha256: hash("sha256", binaryCleanXmlString),
        },
      });
    });
  });
};
