import axios from "axios";

export const getOnBlurHandler = (setValue: any, getValues: any) => () => {
  const { street, city, streetNumber, zipcode } = getValues();
  if (!street && !city && typeof zipcode === "string" && zipcode.length >= 6)
    axios
      .post("https://www.square-management.nl/gateway/static/zipcode", {
        zipcode: zipcode,
        streetNumber: streetNumber,
        countryCode: "NL",
      })
      .then((res) => {
        if (res.data.street) {
          setValue("street", res.data.street);
        }
        if (res.data.city) {
          setValue("city", res.data.city);
        }
      });
};
