import React from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ApiContext } from "../../../provider/ApiProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import "./index.scss";
import { Components, dereferencedSchema } from "shared.wenckebachfonds.nl";
import SchemaTable, {
  ISchemaTableConfig,
} from "../../../components/SchemaTable";
import { useToasts } from "react-toast-notifications";
import { getInitialSchemaConfig } from "../../../components/SchemaTable/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";

export default () => {
  const { jwtUser } = React.useContext(AuthContext);
  const history = useHistory<{
    schemaTableConfigPreset?: ISchemaTableConfig;
  }>();
  const { addToast } = useToasts();
  const { claimClaim, claims, deleteClaim, hydrateClaims } = React.useContext(
    ApiContext
  );
  React.useEffect(() => {
    if (claims === undefined) {
      hydrateClaims();
    }
  }, [hydrateClaims, claims]);

  if (!claims || !jwtUser || !dereferencedSchema) {
    return <Spinner animation={"border"} />;
  }

  const config =
    (history.location.state &&
      history.location.state.schemaTableConfigPreset) ||
    getInitialSchemaConfig("Claim");

  return (
    <div className="pages__admin__claims">
      <Card>
        <Card.Body>
          <SchemaTable
            isConfigurable={true}
            isFilterable={true}
            schema={dereferencedSchema.components.schemas.Claim}
            schemaTitle="Claim"
            data={claims}
            config={config}
            renderAction={(claim: Components.Schemas.Claim) => (
              <React.Fragment>
                <Button
                  variant={claim.status === "open" ? "success" : "primary"}
                  size="sm"
                  style={{ width: 100 }}
                  onClick={() => {
                    if (claim.status === "open") {
                      claimClaim(claim)
                        .then(() => {
                          addToast("Deze aanvraag is in behandeling genomen.", {
                            appearance: "success",
                            autoDismiss: true,
                          });
                          history.push(`/admin/claim/${claim.id}`);
                        })
                        .catch((err: AxiosError) => {
                          addToast(err.response?.data.message || err.message, {
                            appearance: "error",
                            autoDismiss: true,
                          });
                        });
                      return;
                    }
                    history.push(`/admin/claim/${claim.id}`);
                  }}
                >
                  {claim.status === "open" ? (
                    "Start intake"
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon="file-alt"
                        fixedWidth
                        className="mr-1"
                      />
                      Openen
                    </>
                  )}
                </Button>

                {jwtUser.role === "administrator" &&
                  !claim.paymentFileCreatedAt && (
                    <Button
                      variant="danger"
                      size="sm"
                      className="ml-1"
                      onClick={() => {
                        if (
                          claim.id &&
                          window.confirm(
                            "Weet je zeker dat je deze claim wilt verwijderen?"
                          )
                        ) {
                          deleteClaim(claim.id)
                            .then(() => {
                              addToast("Deze aanvraag is verwijderd.", {
                                appearance: "success",
                                autoDismiss: true,
                              });
                            })
                            .catch((err) => {
                              addToast(err.message, {
                                appearance: "error",
                                autoDismiss: true,
                              });
                            });
                          return;
                        }
                        history.push(`/admin/claim/${claim.id}`);
                      }}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  )}
              </React.Fragment>
            )}
          />
        </Card.Body>
      </Card>
      {jwtUser.role === "administrator" && (
        <Button
          variant={"success"}
          onClick={() => {
            history.push(`/admin/claim/`);
          }}
        >
          <span className="fa-layers fa-fw mr-2" title="Opslaan als favoriet">
            <FontAwesomeIcon icon="file-alt" fixedWidth className="mr-1" />
            <FontAwesomeIcon icon="plus" transform="shrink-4 down-1 right-5" />
          </span>
          Nieuwe aanvraag
        </Button>
      )}
    </div>
  );
};
