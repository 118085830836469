import { Card, Col, Form, Row } from "react-bootstrap";
import { SchemaObject } from "openapi3-ts";
import React from "react";
import {
  openapi,
  getSchemaFromClaimPartType,
  addStxt,
} from "shared.wenckebachfonds.nl";
import { useHistory } from "react-router-dom";
import RapCodeFormControl from "../../form/RapCodeFormControl";
import ControlledSchemaFormGroup from "../../ControlledSchemaFormGroup";
import TypeLabel from "./TypeLabel";
import RapCodeLabel from "./RapCodeLabel";
import { ClaimPart } from "../../../types/api";
import HelpPopover from "../../../components/popover/Help";

interface IClaimPartFormControlProps {
  disabled?: boolean;
  error?: any; // FieldError
  name: string;
  value?: ClaimPart;
  onChange: (newValue: ClaimPart) => void;
}
const { mapping } = (openapi.components.schemas.Claim.allOf[1]
  .properties as any).parts.items.discriminator as any;

Object.keys(mapping).forEach((claimPartType) => {
  addStxt(
    claimPartType,
    getSchemaFromClaimPartType(claimPartType).description as string
  );
});

function getLabel(propName: string, isRequired = false) {
  switch (propName) {
    case "declarationFileIds":
      return (
        <span>
          Ingevuld declaratieformulier{isRequired ? " *" : null}. Het sjabloon
          wat u hiervoor kunt gebruiken vindt u{" "}
          <a href="/Berekening_Parkeerkosten.xls" target="_blank">
            hier
          </a>
          .
        </span>
      );
    case "vergoedingsspecificatieFileIds":
      return (
        <span>
          Vergoedingsspecificatie van ziektekostenverzekeraar inclusief de
          vergoeding uit de aanvullende verzekering. Bekijk voorbeelden{" "}
          {isRequired ? "*" : null}
          <a
            href="/Voorbeeld_Zilverenkruis_aanvraag_hulpmiddel.pdf"
            target="_blank"
          >
            1
          </a>
          ,{" "}
          <a
            href="/Voorbeeld_Ziverenkruis_Declaratieoverzicht.pdf"
            target="_blank"
          >
            2
          </a>
          ,{" "}
          <a
            href="/Voorbeeld_Zilverenkruis_Declaratieoverzicht_Specificatie.pdf"
            target="_blank"
          >
            3
          </a>
          .
          <HelpPopover title="Vergoedingsspecificatie">
            <p>
              Hierbij een voorbeeld van het document die VERPLICHT aangeleverd
              dient te worden.
            </p>
            <p>
              Vergoedingsspecificatie van ziektekostenverzekeraar inclusief de
              vergoeding uit de aanvullende verzekering
            </p>
          </HelpPopover>
        </span>
      );

    case "isNotSelfBeneficiary":
      return (
        <span>
          Deze aanvraag is niet voor mijzelf {isRequired ? "*" : null}
          <HelpPopover
            title="Voor wie kunt U aanvragen?"
            style={{ maxWidth: "80%" }}
          >
            <p>Een deelnemer kan een aanvraag tot bijstand indienen voor:</p>
            <ul>
              <li>zichzelf,</li>
              <li>
                zijn of haar partner (getrouwd, samenlevingscontract of duurzaam
                samenwonend),
              </li>
              <li>minderjarige kinderen van de deelnemer en/of partner,</li>
              <li>
                meerderjarige kinderen van de deelnemer en/of partner als deze
                kinderen:
                <ul>
                  <li>studeren en niet ouder zijn dan 27 jaar,</li>
                  <li>beschikkingsonbevoegd zijn,</li>
                  <li>thuis wonen en financieel afhankelijk zijn.</li>
                </ul>
              </li>
            </ul>
          </HelpPopover>
        </span>
      );

    case "visiting":
      return (
        <span>
          Bezoek aan {isRequired ? "*" : null}
          <HelpPopover title="Bij welk bezoek kunt u dit aanvragen?">
            <p>
              Een deelnemer kan bij een bezoek aan een van de volgende personen
              een verzoek indienen:
            </p>
            <ul>
              <li>
                zijn of haar partner (getrouwd, samenlevingscontract of duurzaam
                samenwonend),
              </li>
              <li>minderjarige kinderen van de deelnemer en/of partner,</li>
              <li>
                meerderjarige kinderen van de deelnemer en/of partner als deze
                kinderen:
                <ul>
                  <li>studeren en niet ouder zijn dan 27 jaar,</li>
                  <li>beschikkingsonbevoegd zijn,</li>
                  <li>thuis wonen en financieel afhankelijk zijn.</li>
                </ul>
              </li>
            </ul>
          </HelpPopover>
        </span>
      );
    case "situationDescriptionComment":
      return (
        <span>
          Situatie beschrijving {isRequired ? "*" : null}
          <HelpPopover title="Heeft u vragen over leningen aanvragen?">
            <p>
              Geef hier de nodige achtergrond over de redenen voor de aanvraag
              e.d.
            </p>
            <p>
              Leningen worden terugbetaald via de salaris administratie. Indien
              u niet (meer) werkzaam bent bij TataSteel of andere vragen hebt,
              neem dan contact op met het Wenckebachfonds.
            </p>
          </HelpPopover>
        </span>
      );

    default:
      return undefined;
  }
}

export default ({
  disabled,
  error,
  name,
  onChange,
  value,
}: IClaimPartFormControlProps) => {
  const history = useHistory();
  if (!value) {
    return null;
  }
  const claimPartIndex = parseInt(name.replace(/\D/g, ""), 10);
  const { calculation, isNotSelfBeneficiary, type } = value;
  const claimPartSchema = getSchemaFromClaimPartType(type);
  const { properties, required } = claimPartSchema;
  const calculationProperties =
    openapi.components.schemas.ClaimPart.properties.calculation.properties;
  const isAdmin = history.location.pathname.startsWith("/admin/");

  if (!properties) {
    throw new Error(`Missing properties for ClaimPart ${type}`);
  }
  return (
    <Card style={{ width: "100%" }}>
      <Card.Body>
        <h5>Aanvraag {claimPartIndex + 1}</h5>
        <hr />
        <Row>
          <Col sm={isAdmin ? 6 : 12}>
            <Row>
              <Col>
                <ControlledSchemaFormGroup
                  label={
                    isAdmin ? (
                      <TypeLabel claimPartType={value.type} />
                    ) : undefined
                  }
                  disabled={disabled}
                  isRequired={true}
                  name={`${name}.type`}
                  schema={{
                    type: "string",
                    description: "Soort aanvraag",
                    enum: Object.keys(mapping),
                  }}
                  value={type}
                  onChange={(type) => {
                    onChange({
                      ...value,
                      type,
                    });
                  }}
                  error={error ? error.type : undefined}
                />
              </Col>
            </Row>
            {Object.keys(properties)
              .filter(
                (propName) => ["type", "calculation"].indexOf(propName) === -1
              )
              .map((propName) => (
                <Row
                  key={propName}
                  className={
                    !isNotSelfBeneficiary &&
                    ["beneficiaryName", "beneficiaryBirthdate"].indexOf(
                      propName
                    ) >= 0
                      ? "d-none"
                      : undefined
                  }
                >
                  <Col>
                    <ControlledSchemaFormGroup
                      disabled={disabled}
                      label={getLabel(
                        propName,
                        required && required.indexOf(propName) >= 0
                      )}
                      isRequired={required && required.indexOf(propName) >= 0}
                      name={`${name}.${propName}`}
                      value={(value as any)[propName]}
                      schema={properties[propName]}
                      error={error ? (error as any)[propName] : undefined}
                      onChange={(newPropValue) => {
                        onChange({
                          ...value,
                          [propName]: newPropValue,
                        });
                      }}
                    />
                  </Col>
                </Row>
              ))}
          </Col>
          {isAdmin ? (
            <Col sm={6} style={{ backgroundColor: "#EEEEEE" }}>
              {Object.keys(calculationProperties).map((propName) => {
                const propSchema: SchemaObject =
                  // @ts-ignore
                  calculationProperties[propName];
                const nestedName = `${name}.calculation.${propName}`;
                return (
                  <Row key={propName}>
                    <Col>
                      {propName === "rapCode" ? (
                        <Form.Group>
                          <RapCodeLabel
                            claimPartType={value.type}
                            rapCode={calculation?.rapCode}
                          />
                          <RapCodeFormControl
                            disabled={disabled}
                            name={nestedName}
                            error={
                              error && error.calculation
                                ? error.calculation["rapCode"]
                                : undefined
                            }
                            value={
                              calculation ? calculation.rapCode : undefined
                            }
                            onChange={(newPropValue) => {
                              onChange({
                                ...value,
                                calculation: {
                                  ...calculation,
                                  // @ts-ignore
                                  rapCode: newPropValue,
                                },
                              });
                            }}
                          />
                          {error &&
                          error.calculation &&
                          error.calculation["rapCode"] ? (
                            <Form.Control.Feedback type="invalid">
                              {error.calculation["rapCode"].message}
                            </Form.Control.Feedback>
                          ) : null}
                        </Form.Group>
                      ) : (
                        <ControlledSchemaFormGroup
                          isRequired={
                            required && required.indexOf(propName) >= 0
                          }
                          disabled={disabled}
                          name={nestedName}
                          schema={propSchema}
                          error={
                            error && error.calculation
                              ? error.calculation[propName]
                              : undefined
                          }
                          value={
                            calculation
                              ? (calculation as any)[propName]
                              : undefined
                          }
                          onChange={(newPropValue) => {
                            onChange({
                              ...value,
                              // @ts-ignore
                              calculation: {
                                ...calculation,
                                [propName]: newPropValue,
                              },
                            });
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          ) : null}
        </Row>
      </Card.Body>
    </Card>
  );
};
