import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import axios from "axios";
import { ToastProvider } from "react-toast-notifications";
import AuthProvider from "./provider/AuthProvider";
import ApiProvider from "./provider/ApiProvider";
import * as Sentry from "@sentry/browser";
import { dereferencedSchemaPromise } from "shared.wenckebachfonds.nl";

axios.defaults.baseURL = "http://localhost:5000/";

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://api.wenckebachfonds.nl/";
  Sentry.init({
    dsn:
      "https://aeef1d3e482043a38a49467d339520d1@o38325.ingest.sentry.io/5286936",
  });
}

dereferencedSchemaPromise.then(() => {
  ReactDOM.render(
    <ToastProvider>
      <AuthProvider>
        <ApiProvider>
          <App />
        </ApiProvider>
      </AuthProvider>
    </ToastProvider>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
