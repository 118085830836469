import { Dropdown } from "react-bootstrap";
import { SchemaObject } from "openapi3-ts";
import { stxt as t } from "shared.wenckebachfonds.nl/dist/stxt";
import React, { Dispatch, SetStateAction } from "react";
import { ISchemaTableConfig, TColumnsConfig } from "../../index";
import "./index.scss";

export interface IColumnSelectionDropdown {
  columnsConfig: TColumnsConfig;
  schemaProperties: SchemaObject["properties"];
  setConfig: Dispatch<SetStateAction<Partial<ISchemaTableConfig>>>;
}

function getLabel(columnProp: string, propSchema: SchemaObject) {
  return propSchema.description || t(columnProp);
}

export const ColumnSelectionDropdown = ({
  columnsConfig,
  schemaProperties,
  setConfig,
}: IColumnSelectionDropdown) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const onToggle = React.useCallback((e: any, event: any) => {
    if (event === false) {
      // Seems to work when clicking outside of menu?
      setIsMenuOpen(false);
    }
  }, []);
  const onToggleClick = React.useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);
  const availableColumnProps: string[] = React.useMemo(() => {
    if (!schemaProperties || !isMenuOpen) {
      return [];
    }
    return Object.keys(schemaProperties as Object)
      .filter((columnProp) => {
        const propSchema = schemaProperties
          ? (schemaProperties[columnProp] as SchemaObject)
          : undefined;
        return (
          propSchema && ["deletedAt", "agreeToTerms"].indexOf(columnProp) === -1
        );
      })
      .sort((columnPropA, columnPropB) =>
        getLabel(columnPropA, schemaProperties[columnPropA]) <
        getLabel(columnPropB, schemaProperties[columnPropB])
          ? -1
          : 1
      );
  }, [isMenuOpen, schemaProperties]);

  if (!schemaProperties) {
    return null;
  }
  return (
    <span
      className="dropdown-item components__schema-table__menu-button__column__selection__dropdown"
      onClick={onToggleClick}
    >
      <Dropdown onToggle={onToggle} show={isMenuOpen} drop="right">
        Zichtbare kolommen
        <Dropdown.Toggle id="columnSelectionDrop" variant="link" />
        <Dropdown.Menu>
          {availableColumnProps.map((columnProp) => {
            const propSchema = schemaProperties[columnProp] as SchemaObject;
            const isVisible =
              columnsConfig.findIndex(
                (columnConfig) => columnConfig.propName === columnProp
              ) >= 0;
            return (
              <Dropdown.Item
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setConfig((config) => ({
                    ...config,
                    columnsConfig: isVisible
                      ? columnsConfig.filter(
                          (columnConfig) => columnConfig.propName !== columnProp
                        )
                      : [...columnsConfig, { propName: columnProp }],
                  }));
                }}
                key={columnProp}
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`columnConfig_${columnProp}`}
                    checked={isVisible}
                    readOnly
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`columnConfig_${columnProp}`}
                  >
                    {getLabel(columnProp, propSchema)}
                  </label>
                </div>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </span>
  );
};
