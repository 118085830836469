import { Alert, Button, Card, Table } from "react-bootstrap";
import React from "react";
import { format } from "date-fns";
import { Components, stxt as t } from "shared.wenckebachfonds.nl";
import number_format from "locutus/php/strings/number_format";

interface IPreviousClaimsCard {
  previousClaims: Components.Schemas.Claim[];
}

export default (props: IPreviousClaimsCard) => {
  const { previousClaims } = props;
  const [isMoreVisible, setIsMoreVisible] = React.useState(false);

  const previousClaimRows = previousClaims.reduce<JSX.Element[]>(
    (prev, claim, claimIndex) => {
      prev.unshift(
        ...claim.parts.map((part, partIndex) => {
          const { internalComment = "" } = part.calculation || {};
          return (
            <tr key={`${claimIndex}_${partIndex}`}>
              <td>
                {claim.createdAt
                  ? format(new Date(claim.createdAt), "dd-MM-yyyy")
                  : ""}
              </td>
              <td>{t(part.type)}</td>
              <td>
                {part.calculation?.amount
                  ? `€ ${number_format(part.calculation?.amount, 2, ",", ".")}`
                  : "-"}
              </td>
              <td>{internalComment.split("\n")[0]}</td>
              <td>
                <a
                  href={`/admin/claim/${claim.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t(claim.status || "")}
                </a>
              </td>
            </tr>
          );
        })
      );
      return prev;
    },
    []
  );

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          Eerdere aanvragen vanaf e-mail adres / personeelsnummer
        </Card.Title>
        {previousClaims.length ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ width: 135 }}>Datum</th>
                <th>Inhoud</th>
                <th>Toegekend bedrag</th>
                <th>Interne opmerking</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {previousClaimRows.slice(0, isMoreVisible ? 1000 : 10)}
            </tbody>
          </Table>
        ) : (
          <Alert variant="primary" className="mt-4">
            Er zijn geen eerdere aanvragen gevonden
          </Alert>
        )}
        {!isMoreVisible && previousClaims.length > 10 && (
          <Button
            variant="secondary"
            onClick={() => {
              setIsMoreVisible(true);
            }}
          >
            Meer tonen...
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};
