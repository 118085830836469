import { EFilterCompare, ISchemaTableConfig } from "./index";

export const getSchemaTableConfigLocalStorageIdentifier = (
  schemaTitle: "Claim" | "ClaimPart" | "UngroupedClaim" | "ToBePaidClaims"
) => `schemaTableConfig.${schemaTitle}.v3`;

export const getInitialSchemaConfig = (
  schemaTitle: "Claim" | "ClaimPart" | "UngroupedClaim" | "ToBePaidClaims"
): Partial<ISchemaTableConfig> => {
  const storedConfig = localStorage.getItem(
    getSchemaTableConfigLocalStorageIdentifier(schemaTitle)
  );
  if (storedConfig) {
    // @see https://www.npmjs.com/package/serialize-javascript --- we need to support e.g. Date-objects in filters
    // eslint-disable-next-line no-eval
    return eval(`(${storedConfig})`);
  }

  return getDefaultSchemaConfig(schemaTitle);
};

export const getDefaultSchemaConfig = (
  schemaTitle: "Claim" | "ClaimPart" | "UngroupedClaim" | "ToBePaidClaims"
): Partial<ISchemaTableConfig> => {
  switch (schemaTitle) {
    case "Claim":
      return {
        columnsConfig: [
          { propName: "id" },
          { propName: "name" },
          { propName: "createdAt" },
          { propName: "updatedAt" },
          { propName: "personnelNumber" },
          { propName: "status" },
        ],
      };

    case "ClaimPart":
      return {};

    case "UngroupedClaim": {
      return {
        columnsConfig: [
          { propName: "id" },
          { propName: "name" },
          { propName: "part" },
        ],
      };
    }

    case "ToBePaidClaims": {
      return {
        columnsConfig: [
          { propName: "id" },
          { propName: "name" },
          { propName: "parts" },
          { propName: "paymentFileCreatedAt" },
          { propName: "status" },
        ],
        filtersConfig: {
          parts: [
            {
              compare: EFilterCompare.CHECK_MIN_AMOUNT,
              value: 0.01,
            },
          ],
          status: [
            {
              compare: EFilterCompare.EQUALS,
              value: "approved",
            },
          ],
          paymentFileCreatedAt: [
            {
              compare: EFilterCompare.EQUALS,
              value: undefined,
            },
          ],
        },
      };
    }
  }
};
