import { Components, stxt as t } from "shared.wenckebachfonds.nl";
import { Button, Table } from "react-bootstrap";
import { format, isAfter, isBefore } from "date-fns";
import React, { Dispatch, SetStateAction } from "react";
import { SchemaObject } from "openapi3-ts";
import { ApiContext } from "../../provider/ApiProvider";
import { Spinner, Form } from "react-bootstrap";
import mergeAllOf from "json-schema-merge-allof";
import number_format from "locutus/php/strings/number_format";
import { download } from "../../inc/file";
import { useToasts } from "react-toast-notifications";
import { AuthContext } from "../../provider/AuthProvider";
import HeaderTr from "./HeaderTr";
import FilterTr from "./FilterTr";
import PartTdBody from "./PartTdBody";
import ExportButton from "./ExportButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSchemaTableConfigLocalStorageIdentifier } from "./config";
import serialize from "serialize-javascript";
import { useForm } from "react-hook-form";
import MenuButton from "./MenuButton";
import SchemaTablePagination from "./SchemaTablePagination";

import "./index.scss";

export const DEFAULT_COLUMN_WIDTH = 208;
export const SELECT_COLUMN_WIDTH = 70;
export const ACTION_COLUMN_WIDTH = 160;

export interface IColumnConfig {
  propName: string;
  width?: number;
}

export type TColumnsConfig = IColumnConfig[];
export type TExtraExportColumnDecoratorConfig = IExtraExportColumnDecoratorConfig;

export enum EFilterCompare {
  CONTAINS,
  EQUALS,
  GREATER_THAN,
  LESS_THAN,
  CHECK_TYPE,
  CHECK_MIN_AMOUNT,
}

export interface IFilterConfig {
  compare: EFilterCompare;
  value?: string | Date | number | boolean;
}

export interface IFiltersConfig {
  [column: string]: Array<IFilterConfig>;
}

export interface ISchemaTableConfig {
  title?: string;
  columnsConfig: TColumnsConfig;
  filtersConfig: IFiltersConfig;
  isSortAsc: boolean;
  sortBy: string;
}

interface IExtraExportColumnDecoratorConfig {
  [columnName: string]: (rowData: {}) => string;
}

interface ISchemaTableProps {
  isConfigurable?: boolean;
  isExportable?: boolean;
  isFilterable?: boolean;
  renderAction?: (rowData: any) => React.ReactElement;
  config?: Partial<ISchemaTableConfig>;
  data: Array<any>;
  onSubmit?: (ids: string[]) => void;
  schema: SchemaObject;
  schemaTitle: "Claim" | "ClaimPart" | "UngroupedClaim" | "ToBePaidClaims";
  extraExportColumns?: IExtraExportColumnDecoratorConfig;
}

interface IFormData {
  [id: string]: boolean;
}

export default function SchemaTable(props: ISchemaTableProps) {
  const { data } = props;
  const schema = React.useMemo(() => mergeAllOf(props.schema as any), [
    props.schema,
  ]) as SchemaObject;
  const isSelectable = !!props.onSubmit;
  const { properties = {} } = schema;
  const { addToast } = useToasts();
  const { handleSubmit, register, watch, getValues, setValue } = useForm<
    IFormData
  >();
  const propertyNames: string[] = Object.keys(properties);
  const partialToFullSchemaTableConfig = React.useCallback(
    (
      partialSchemaTableConfig: Partial<ISchemaTableConfig>
    ): ISchemaTableConfig => ({
      columnsConfig:
        partialSchemaTableConfig.columnsConfig ||
        propertyNames.map<IColumnConfig>((propName) => ({ propName })),
      filtersConfig: partialSchemaTableConfig.filtersConfig || {},
      sortBy: partialSchemaTableConfig.sortBy || "createdAt",
      isSortAsc: !!partialSchemaTableConfig.isSortAsc,
    }),
    [propertyNames]
  );
  const [schemaTableConfig, rawSetSchemaTableConfig] = React.useState<
    ISchemaTableConfig
  >(partialToFullSchemaTableConfig(props.config || {}));

  const { isLoggedIn } = React.useContext(AuthContext);
  const { users, hydrateUsers } = React.useContext(ApiContext);
  const [paginationPage, setPaginationPage] = React.useState<number>(1);
  const [itemsPerPaginationPage, setItemsPerPaginationPage] = React.useState<
    number
  >(isSelectable ? 500 : 10);
  React.useEffect(() => {
    if (isLoggedIn() && users === undefined) {
      hydrateUsers();
    }
  }, [hydrateUsers, isLoggedIn, users]);
  const watchAllFields = watch();
  const setSchemaTableConfig: Dispatch<SetStateAction<
    Partial<ISchemaTableConfig>
  >> = React.useCallback(
    (valueOrSetter) => {
      const isSetter =
        valueOrSetter &&
        {}.toString.call(valueOrSetter) === "[object Function]";
      const newConfig = (isSetter
        ? (valueOrSetter as any)(schemaTableConfig)
        : valueOrSetter) as ISchemaTableConfig;
      if (schemaTableConfig.filtersConfig !== newConfig.filtersConfig) {
        setPaginationPage(1);
      }
      localStorage.setItem(
        getSchemaTableConfigLocalStorageIdentifier(props.schemaTitle),
        serialize(newConfig)
      );
      rawSetSchemaTableConfig(partialToFullSchemaTableConfig(newConfig));
    },
    [schemaTableConfig, props.schemaTitle, partialToFullSchemaTableConfig]
  );
  React.useEffect(() => {
    Object.keys(getValues()).forEach((id) => {
      setValue(id, watchAllFields.selectAll);
    });
  }, [getValues, watchAllFields.selectAll, setValue]);
  const { columnsConfig, sortBy, isSortAsc, filtersConfig } = schemaTableConfig;
  const results = React.useMemo(() => {
    const sortMultiplier = isSortAsc ? 1 : -1;
    const sortSchema = properties[sortBy] as SchemaObject;
    return data
      .filter(
        (rowData) =>
          !Object.keys(filtersConfig).some((filterConfigProp) => {
            const filters = filtersConfig[filterConfigProp];
            const propSchema = properties
              ? (properties[filterConfigProp] as SchemaObject)
              : undefined;
            if (!filters.length || !propSchema) {
              return false;
            }
            const value = rowData[filterConfigProp];
            return filters.some((filter) => {
              switch (filter.compare) {
                case EFilterCompare.CONTAINS:
                  return (
                    !value ||
                    value.toLowerCase().indexOf(filter.value as string) === -1
                  );

                case EFilterCompare.GREATER_THAN:
                  return propSchema.format &&
                    propSchema.format.startsWith("date")
                    ? !value || isBefore(new Date(value), filter.value as Date)
                    : value < (filter.value || 0);

                case EFilterCompare.LESS_THAN:
                  return propSchema.format &&
                    propSchema.format.startsWith("date")
                    ? !value || isAfter(new Date(value), filter.value as Date)
                    : value < (filter.value || 0);

                case EFilterCompare.EQUALS:
                  // Special handling of undefined booleans, e.g. isWidow
                  return (
                    (propSchema.type === "boolean" ? Boolean(value) : value) !==
                    filter.value
                  );

                case EFilterCompare.CHECK_TYPE:
                  return Array.isArray(value)
                    ? !value.find((part) => part.type === filter.value)
                    : value.type !== filter.value;

                case EFilterCompare.CHECK_MIN_AMOUNT:
                  // "parts" or "part"?
                  const isArray = Array.isArray(value);
                  return (
                    !value ||
                    (isArray &&
                      value.findIndex(
                        (part: any) =>
                          part.calculation?.amount >= (filter.value as number)
                      ) === -1) ||
                    (!isArray &&
                      (!value.calculation ||
                        value.calculation.amount < (filter.value as number)))
                  );
              }
              throw new Error("Unsupported filter");
            });
          })
      )
      .sort((a, b) => {
        let aVal: any;
        let bVal: any;
        if (
          sortSchema.format &&
          ["date", "date-time"].indexOf(sortSchema.format) > -1
        ) {
          aVal = new Date(a[sortBy] as string);
          bVal = new Date(b[sortBy] as string);
        } else if (sortSchema.enum) {
          aVal = t(a[sortBy] as string);
          bVal = t(b[sortBy] as string);
        } else if (sortSchema.type === "string") {
          aVal = a[sortBy] ? (a[sortBy] as string).toLowerCase() : "";
          bVal = b[sortBy] ? (b[sortBy] as string).toLowerCase() : "";
        } else {
          aVal = a[sortBy];
          bVal = b[sortBy];
        }

        if (aVal === undefined) {
          return sortMultiplier;
        }
        if (bVal === undefined) {
          return -sortMultiplier;
        }
        return (aVal < bVal ? -1 : 1) * sortMultiplier;
      });
  }, [filtersConfig, data, properties, sortBy, isSortAsc]);
  const hasPagination =
    !isSelectable && results.length > itemsPerPaginationPage;

  if (!properties) {
    return <Spinner animation="border" />;
  }
  return (
    <form
      className="components__schema-table"
      onSubmit={handleSubmit((values, e: any) => {
        if (!props.onSubmit) {
          return;
        }
        if (
          e &&
          e.target &&
          e.target.tagName === "FORM" &&
          e.target.className !== "components__schema-table"
        ) {
          // https://trello.com/c/fINUTAZ7/101-als-administrator-wil-ik-een-klant-per-telefoon-snel-beantwoorden-dmv-personeelsnummer-in-te-voeren-en-een-overzicht-met-vergoed
          // nested forms in react-hooks-form suck
          return;
        }
        props.onSubmit(Object.keys(values).filter((id) => values[id]));
      })}
    >
      {props.isConfigurable ? (
        <div style={{ position: "absolute", top: 0, left: -12, zIndex: 1 }}>
          <MenuButton
            schemaTitle={props.schemaTitle}
            setConfig={setSchemaTableConfig}
            schemaTableConfig={schemaTableConfig}
            schemaProperties={properties}
            columnsConfig={columnsConfig}
          />
        </div>
      ) : null}
      <Table
        striped
        bordered
        hover
        style={{
          width: columnsConfig.reduce(
            (prev, columnConfig) =>
              prev + (columnConfig.width || DEFAULT_COLUMN_WIDTH),
            ACTION_COLUMN_WIDTH + (isSelectable ? SELECT_COLUMN_WIDTH : 0)
          ),
        }}
      >
        <thead>
          <HeaderTr
            isConfigurable={props.isConfigurable}
            isSelectable={isSelectable}
            schema={schema}
            schemaTableConfig={schemaTableConfig}
            setConfig={setSchemaTableConfig}
          />
          {props.isFilterable ? (
            <FilterTr
              columnsConfig={columnsConfig}
              filtersConfig={filtersConfig}
              isSelectable={isSelectable}
              setConfig={setSchemaTableConfig}
              schema={schema}
              schemaTitle={props.schemaTitle}
              resultCount={results.length}
              selectAllRegister={register}
            />
          ) : null}
        </thead>
        <tbody>
          {results
            .slice(
              (paginationPage - 1) * itemsPerPaginationPage,
              paginationPage * itemsPerPaginationPage
            )
            .map((rowData, index) => (
              <tr key={index}>
                {isSelectable && (
                  <td key={`selectRow_${index}`}>
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        name={rowData.id}
                        ref={register}
                      />
                    </Form.Group>
                  </td>
                )}
                {columnsConfig.map((columnConfig) => {
                  const columnProp = columnConfig.propName;
                  const propSchema = schema.properties
                    ? (schema.properties[columnProp] as SchemaObject)
                    : undefined;
                  if (!propSchema) {
                    return null;
                  }

                  if (columnProp.endsWith("UserId")) {
                    const user = users
                      ? users.find((user) => user.id === rowData[columnProp])
                      : undefined;
                    return (
                      <td
                        key={columnProp}
                        className={`components__schema-table__td--${columnProp}`}
                      >
                        {user?.name}
                      </td>
                    );
                  }

                  if (columnProp.endsWith("FileIds")) {
                    return (
                      <td key={columnProp}>
                        {(rowData[columnProp] || []).map((id: string) => (
                          <Button
                            key={id}
                            size="sm"
                            onClick={() =>
                              download({ id }).catch((err) => {
                                addToast(err.message, {
                                  appearance: "error",
                                  autoDismiss: true,
                                });
                              })
                            }
                          >
                            Download
                          </Button>
                        ))}
                      </td>
                    );
                  }

                  if (propSchema.enum) {
                    return (
                      <td
                        key={columnProp}
                        className={`components__schema-table__td--${columnProp}`}
                      >
                        {t(rowData[columnProp])}
                      </td>
                    );
                  }

                  switch (columnProp) {
                    case "calculation":
                      const { calculation = {} } = rowData;
                      return (
                        <td key={columnProp}>
                          <dl>
                            <dt>RAP code</dt>
                            <dd>{calculation.rapCode}</dd>
                            <dt>Bedrag</dt>
                            <dd>
                              € {number_format(calculation.amount, 2, ",", ".")}
                            </dd>
                            <dt>Opmerking</dt>
                            <dd>{calculation.internalComment}</dd>
                          </dl>
                        </td>
                      );

                    case "parts":
                      return (
                        <td
                          key={columnProp}
                          className="components__schema-table__td--parts"
                        >
                          {rowData[columnProp].map(
                            (
                              part: Components.Schemas.DyslexiaClaimPart,
                              partIndex: number
                            ) => (
                              <PartTdBody part={part} key={partIndex} />
                            )
                          )}
                        </td>
                      );

                    case "part":
                      return (
                        <td
                          key={columnProp}
                          className="components__schema-table__td--part"
                        >
                          <PartTdBody part={rowData[columnProp]} />
                        </td>
                      );
                  }

                  switch (propSchema.type) {
                    case "boolean":
                      return (
                        <td key={columnProp} style={{ textAlign: "center" }}>
                          {rowData[columnProp] ? (
                            <FontAwesomeIcon
                              icon="check"
                              className="text-success"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon="times"
                              className="text-danger"
                            />
                          )}
                        </td>
                      );

                    case "string":
                      switch (propSchema.format) {
                        case "date-time":
                          return (
                            <td key={columnProp}>
                              {rowData[columnProp]
                                ? format(
                                    new Date(rowData[columnProp]),
                                    "dd-MM-yyyy HH:mm:ss"
                                  )
                                : ""}
                            </td>
                          );

                        case "date":
                          return (
                            <td key={columnProp}>
                              {rowData[columnProp]
                                ? format(
                                    new Date(rowData[columnProp]),
                                    "dd-MM-yyyy"
                                  )
                                : ""}
                            </td>
                          );
                      }
                      return (
                        <td
                          key={columnProp}
                          className={`components__schema-table__td--${columnProp}`}
                        >
                          {rowData[columnProp]}
                        </td>
                      );
                  }

                  return (
                    <td key={columnProp}>
                      {JSON.stringify(rowData[columnProp])}
                    </td>
                  );
                })}
                <td>
                  {props.renderAction ? props.renderAction(rowData) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="d-flex">
        {hasPagination ? (
          <div style={{ flex: 1 }}>
            Resultaat {paginationPage} t/m{" "}
            {paginationPage + itemsPerPaginationPage} van {results.length}. Toon{" "}
            <select
              style={{ width: "auto", display: "inline" }}
              className="form-control mr-1"
              value={itemsPerPaginationPage}
              onChange={(e) => {
                setItemsPerPaginationPage(parseInt(e.target.value, 10));
              }}
            >
              <option>5</option>
              <option>10</option>
              <option>20</option>
              <option>50</option>
              <option>100</option>
            </select>
            rijen per pagina
          </div>
        ) : null}
        {props.isExportable || isSelectable ? (
          <div style={{ flex: 1 }}>
            {props.isExportable ? (
              <ExportButton
                results={results}
                columnsConfig={columnsConfig}
                extraColumns={props.extraExportColumns}
              />
            ) : null}
            {isSelectable ? (
              <Button
                type="submit"
                disabled={
                  !results.length ||
                  !Object.keys(watchAllFields).filter(
                    (id) => watchAllFields[id]
                  ).length
                }
              >
                <FontAwesomeIcon icon="check" fixedWidth className="mr-1" />
                Selectie verwerken
              </Button>
            ) : null}
          </div>
        ) : null}
        <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          {hasPagination ? (
            <SchemaTablePagination
              currentPage={paginationPage}
              totalPages={Math.ceil(results.length / itemsPerPaginationPage)}
              onChange={setPaginationPage}
            />
          ) : null}
        </div>
      </div>
    </form>
  );
}
