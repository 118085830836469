import React from "react";
import { ApiContext } from "../../../provider/ApiProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import { Alert, Card, Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import ClaimForm from "./ClaimForm";
import ClaimLogPane from "./ClaimLogPane";
import { Components, openapi } from "shared.wenckebachfonds.nl";
import { SchemaObject } from "openapi3-ts";
import { getEmptyObject } from "../../../inc/schema";

import "./index.scss";

interface IClaimProps {
  match: {
    params: {
      id: string | undefined;
    };
  };
}

export default (props: IClaimProps) => {
  const { claims, hydrateClaims, hydrateUsers, users } = React.useContext(
    ApiContext
  );

  const { jwtUser } = React.useContext(AuthContext);
  const [submitPending, setSubmitPending] = React.useState(false);
  React.useEffect(() => {
    if (claims === undefined) {
      hydrateClaims();
    }
  }, [hydrateClaims, claims]);
  React.useEffect(() => {
    if (users === undefined) {
      hydrateUsers();
    }
  }, [hydrateUsers, users]);
  const { id } = props.match.params;
  const claim: Components.Schemas.Claim | undefined = React.useMemo(() => {
    if (!claims) {
      return undefined;
    }
    if (!id) {
      return getEmptyObject<Components.Schemas.Claim>(
        openapi.components.schemas.Claim
      );
    }
    return claims.find((claim) => claim.id === id);
  }, [claims, id]);

  if (!claims || submitPending || !jwtUser) {
    return <Spinner animation={"border"} />;
  }

  if (id && !claim) {
    return <Redirect to="/admin/claims" />;
  }

  const [, parentSchema] = openapi.components.schemas.Claim.allOf;
  const { properties } = parentSchema as SchemaObject;

  if (!properties || !claim || !users) {
    return null;
  }

  let warning;

  if (claim.id) {
    if (!claim.adminUserId) {
      warning = (
        <Alert variant="warning">
          Let op: deze aanvraag heeft nog geen beheerder
        </Alert>
      );
    } else if (claim.status === "intake" && claim.adminUserId !== jwtUser?.id) {
      const adminUser = users.find((user) => user.id === claim.adminUserId);
      warning = (
        <Alert variant="danger">
          Let op: u bent niet de aangewezen beheerder
          {adminUser ? `, dat is ${adminUser.name}.` : "."}
        </Alert>
      );
    }
  }

  return (
    <Card className={undefined} style={{ width: "100%" }}>
      <Card.Body>
        {warning}
        {submitPending && <Spinner animation="border" />}
        {/* Force a remount after claim change AND claim claim: use claim.id and status as key here! */}
        <div className="d-flex" key={`${claim.id}_${claim.status}`}>
          <ClaimForm claim={claim} setSubmitPending={setSubmitPending} />
          <ClaimLogPane claim={claim} />
        </div>
      </Card.Body>
    </Card>
  );
};
