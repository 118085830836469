import { Form } from "react-bootstrap";
import React from "react";
import { stxt as t } from "shared.wenckebachfonds.nl";
import Control from "./Control";
import { BsPrefixProps } from "react-bootstrap/helpers";
import ClaimPartsFormControl from "../form/ClaimPartsFormControl";
import { SchemaObject } from "openapi3-ts";
import { Controller } from "react-hook-form";

interface ISchemaFormGroupProps extends BsPrefixProps<any> {
  className?: string;
  control?: any;
  defaultValue?: any;
  disabled?: boolean;
  error?: any; //FieldError;
  register?: any;
  isRequired?: boolean;
  label?: any;
  name: string;
  schema: SchemaObject;
  trafficLight?: React.ReactElement;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
}

export default ({
  as,
  className,
  control,
  defaultValue,
  disabled,
  error,
  label,
  register,
  isRequired,
  schema,
  trafficLight,
  name,
  onBlur,
  onChange,
}: ISchemaFormGroupProps) => {
  if (!schema) {
    throw new Error(`Missing schema for ${name}`);
  }
  const { items } = schema;
  // @ts-ignore
  if (items && items !== true && items.discriminator) {
    return (
      <Controller
        as={ClaimPartsFormControl}
        disabled={disabled}
        name={name}
        control={control}
        error={error}
      />
    );
  }

  const isUserInputRequired = !!(
    isRequired &&
    (schema.minLength ||
      schema.enum ||
      schema.format ||
      schema.pattern ||
      schema.minimum)
  );

  return (
    <Form.Group
      as={as}
      className={`components__schema-form-group components__schema-form-group--${name}${
        className ? ` ${className}` : ""
      }`}
    >
      <React.Fragment>
        {trafficLight}
        {schema.type === "boolean"
          ? null
          : label || (
              <Form.Label>
                {schema.description || t(name)}
                {isUserInputRequired ? " *" : ""}
              </Form.Label>
            )}
      </React.Fragment>
      <Control
        control={control}
        defaultValue={defaultValue}
        disabled={disabled}
        inputRef={register}
        isRequired={isRequired}
        label={label}
        name={name}
        schema={schema}
        error={error}
        isUserInputRequired={isUserInputRequired}
        onBlur={onBlur}
        onChange={onChange}
      />
      {error ? (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};
