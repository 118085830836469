import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import React from "react";
import { EFilterCompare, IFilterConfig } from "../../index";

import "./index.scss";

interface IDateFilterThProps {
  propName: string;
  onChange: (newValue: IFilterConfig[]) => void;
  value: IFilterConfig[];
}

export default ({ onChange, value, propName }: IDateFilterThProps) => {
  const gtFilter = value.find(
    (filter) => filter.compare === EFilterCompare.GREATER_THAN
  );
  const ltFilter = value.find(
    (filter) => filter.compare === EFilterCompare.LESS_THAN
  );

  const emptyFilter = value.find(
    (filter) => filter.compare === EFilterCompare.EQUALS
  );

  return (
    <th className="components__schema-table__filter-tr__date-filter-th">
      <Form.Group className="d-flex" controlId={`${propName}_gt`}>
        <Form.Label style={{ width: 80 }}>Na</Form.Label>
        <DatePicker
          id={`${propName}_gt`}
          selected={gtFilter?.value as Date}
          onChange={(value: Date) => {
            const newValue = [];
            if (ltFilter) {
              newValue.push(ltFilter);
            }
            if (value) {
              newValue.push({
                compare: EFilterCompare.GREATER_THAN,
                value,
              });
            }
            return onChange(newValue);
          }}
          dateFormat="dd-MM-yyyy"
          className="form-control"
          wrapperClassName="flex-grow-1"
        />
      </Form.Group>
      <Form.Group className="d-flex mb-0" controlId={`${propName}_lt`}>
        <Form.Label style={{ width: 80 }}>Voor</Form.Label>
        <DatePicker
          id={`${propName}_lt`}
          selected={ltFilter?.value as Date}
          onChange={(value: Date) => {
            const newValue = [];
            if (gtFilter) {
              newValue.push(gtFilter);
            }
            if (value) {
              newValue.push({
                compare: EFilterCompare.LESS_THAN,
                value,
              });
            }
            return onChange(newValue);
          }}
          dateFormat="dd-MM-yyyy"
          className="form-control"
          wrapperClassName="flex-grow-1"
        />
      </Form.Group>
      <Form.Group className="d-flex mt-2 mb-0" controlId={`${propName}_eq`}>
        <Form.Check
          type="checkbox"
          checked={!!emptyFilter}
          onChange={() =>
            onChange(
              emptyFilter
                ? []
                : [
                    {
                      compare: EFilterCompare.EQUALS,
                      value: undefined,
                    },
                  ]
            )
          }
        />{" "}
        <Form.Label>Geen datum</Form.Label>
      </Form.Group>
    </th>
  );
};
