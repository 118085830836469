import React, { Dispatch, SetStateAction } from "react";
import {
  EFilterCompare,
  IFilterConfig,
  IFiltersConfig,
  ISchemaTableConfig,
  TColumnsConfig,
} from "../index";
import DateFilterTh from "./DateFilterTh";
import { SchemaObject } from "openapi3-ts";
import { ApiContext } from "../../../provider/ApiProvider";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EnumStringFormControl from "../../form/EnumStringFormControl";
import "./index.scss";
import PartsFilterTh from "./PartsFilterTh";
import { getDefaultSchemaConfig } from "../config";

interface IFilterTrProps {
  columnsConfig: TColumnsConfig;
  filtersConfig: IFiltersConfig;
  resultCount: number;
  isSelectable?: boolean;
  schema: SchemaObject;
  schemaTitle: "Claim" | "ClaimPart" | "UngroupedClaim" | "ToBePaidClaims";
  selectAllRegister?: any;
  setConfig: Dispatch<SetStateAction<Partial<ISchemaTableConfig>>>;
}

export default ({
  columnsConfig,
  filtersConfig,
  isSelectable,
  resultCount,
  schema,
  schemaTitle,
  selectAllRegister,
  setConfig,
}: IFilterTrProps) => {
  const { users } = React.useContext(ApiContext);

  return (
    <tr className="components__schema-table__filter-tr">
      {isSelectable ? (
        <th>
          <Form.Group>
            <Form.Check
              type="checkbox"
              name="selectAll"
              defaultChecked={true}
              ref={selectAllRegister}
            />
          </Form.Group>
        </th>
      ) : null}
      {columnsConfig.map(({ propName }) => {
        const propSchema = schema.properties
          ? (schema.properties[propName] as SchemaObject)
          : undefined;
        if (!propSchema) {
          return null;
        }
        const columnFilters: IFilterConfig[] = filtersConfig[propName] || [];

        if (["part", "parts"].indexOf(propName) > -1) {
          return (
            <PartsFilterTh
              key={propName}
              propName={propName}
              onChange={(filterConfigs) =>
                setConfig((config) => ({
                  ...config,
                  filtersConfig: {
                    ...filtersConfig,
                    [propName]: filterConfigs,
                  },
                }))
              }
              value={columnFilters}
            />
          );
        }

        if (propName.endsWith("UserId")) {
          return (
            <th key={propName}>
              <select
                className="form-control"
                id={propName}
                value={columnFilters[0]?.value as string}
                onChange={(e) => {
                  const userId = e.target.value;
                  setConfig((config) => ({
                    ...config,
                    filtersConfig: {
                      ...filtersConfig,
                      [propName]: [
                        {
                          compare: EFilterCompare.EQUALS,
                          value: userId,
                        },
                      ],
                    },
                  }));
                }}
              >
                <option value="" />
                {(users || []).map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </th>
          );
        }

        if (propSchema.enum) {
          return (
            <th key={propName}>
              <EnumStringFormControl
                isClearable={true}
                onChange={(value) =>
                  setConfig((config) => ({
                    ...config,
                    filtersConfig: {
                      ...filtersConfig,
                      [propName]: value
                        ? [
                            {
                              compare: EFilterCompare.EQUALS,
                              value,
                            },
                          ]
                        : [],
                    },
                  }))
                }
                schema={propSchema}
                value={columnFilters[0]?.value as string}
              />
            </th>
          );
        }

        switch (propSchema.type) {
          case "string":
            if (propSchema.format && propSchema.format.startsWith("date")) {
              return (
                <DateFilterTh
                  key={propName}
                  propName={propName}
                  onChange={(filterConfigs) =>
                    setConfig((config) => ({
                      ...config,
                      filtersConfig: {
                        ...filtersConfig,
                        [propName]: filterConfigs,
                      },
                    }))
                  }
                  value={columnFilters}
                />
              );
            }
            return (
              <th key={propName}>
                <input
                  type="text"
                  className="form-control"
                  name={propName}
                  value={(columnFilters[0]?.value as string) || ""}
                  onChange={(e) => {
                    const value = e.target.value.toLowerCase();
                    setConfig((config) => ({
                      ...config,
                      filtersConfig: {
                        ...filtersConfig,
                        [propName]: [
                          {
                            compare: EFilterCompare.CONTAINS,
                            value,
                          },
                        ],
                      },
                    }));
                  }}
                />
              </th>
            );

          case "number":
            return (
              <th key={propName}>
                <input
                  type="number"
                  className="form-control"
                  name={propName}
                  value={`${columnFilters[0].value}`}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    setConfig((config) => ({
                      ...config,
                      filtersConfig: {
                        ...filtersConfig,
                        [propName]: [
                          {
                            compare: EFilterCompare.EQUALS,
                            value,
                          },
                        ],
                      },
                    }));
                  }}
                />
              </th>
            );

          case "boolean":
            return (
              <th key={propName}>
                <EnumStringFormControl
                  isClearable={true}
                  onChange={(value) => {
                    setConfig((config) => ({
                      ...config,
                      filtersConfig: {
                        ...filtersConfig,
                        [propName]: value
                          ? [
                              {
                                compare: EFilterCompare.EQUALS,
                                value: value === "Ja",
                              },
                            ]
                          : [],
                      },
                    }));
                  }}
                  schema={{ enum: ["Ja", "Nee"] }}
                  value={
                    typeof columnFilters[0]?.value === "boolean"
                      ? columnFilters[0]?.value
                        ? "Ja"
                        : "Nee"
                      : ""
                  }
                />
              </th>
            );
          case "array":
          case "object":
          default:
            return <th key={propName} />;
        }
        // return <th key={propName}>{JSON.stringify(propSchema)}</th>;
      })}
      <th>
        <div className="mb-1">
          <em style={{ fontWeight: "normal" }}>{resultCount} items gevonden</em>
        </div>
        <Button
          size="sm"
          variant="secondary"
          disabled={Object.keys(filtersConfig).length === 0}
          onClick={() => {
            setConfig((config) => ({
              ...config,
              filtersConfig: getDefaultSchemaConfig(schemaTitle).filtersConfig,
            }));
          }}
        >
          <span className="fa-layers fa-fw mr-1" title="Legen">
            <FontAwesomeIcon icon="filter" fixedWidth className="mr-1" />
            <FontAwesomeIcon
              icon="times"
              transform="shrink-4 down-1 right-5"
              className="text-danger"
            />
          </span>
          Legen
        </Button>
      </th>
    </tr>
  );
};
