/// <reference path="../../../node_modules/shared.wenckebachfonds.nl/dist/index.d.ts" />

import { Form } from "react-bootstrap";
import React from "react";
import { stxt as t } from "shared.wenckebachfonds.nl";
import ControlledControl from "./ControlledControl";
import { BsPrefixProps } from "react-bootstrap/helpers";
import { SchemaObject } from "openapi3-ts";

interface IControlledSchemaFormGroupProps extends BsPrefixProps<any> {
  className?: string;
  disabled?: boolean;
  error?: any; //FieldError;
  isRequired?: boolean;
  label?: any;
  name: string;
  schema: SchemaObject;
  onBlur?: (e: any) => void;
  onChange: (e: any) => void;
  value: any;
}

export default ({
  as,
  className,
  disabled,
  error,
  label,
  isRequired,
  schema,
  name,
  onBlur,
  onChange,
  value,
}: IControlledSchemaFormGroupProps) => {
  if (!schema) {
    throw new Error(`Missing schema for ${name}`);
  }
  const isUserInputRequired = !!(
    isRequired &&
    (schema.minLength ||
      schema.enum ||
      schema.format ||
      schema.pattern ||
      schema.minimum)
  );

  return (
    <Form.Group as={as} className={className}>
      {schema.type === "boolean"
        ? null
        : label || (
            <Form.Label>
              {schema.description || t(name)}
              {isUserInputRequired ? " *" : ""}
            </Form.Label>
          )}
      <ControlledControl
        disabled={disabled}
        schema={schema}
        isRequired={isRequired}
        label={label}
        name={name}
        error={error}
        isUserInputRequired={isUserInputRequired}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
      {error ? (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};
