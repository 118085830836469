import { Button, Modal, Spinner } from "react-bootstrap";
import React from "react";
import { ApiContext } from "../../../provider/ApiProvider";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

export default (props: { token: string }) => {
  const { token } = props;
  const { addToast } = useToasts();
  const [show, setShow] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const { deleteMyClaim } = React.useContext(ApiContext);
  const history = useHistory();

  const handleClose = React.useCallback(() => {
    setInProgress(false);
    setShow(false);
  }, []);

  if (!token) {
    return null;
  }

  return (
    <React.Fragment>
      <Button
        disabled={inProgress}
        variant="danger"
        className="float-right"
        onClick={() => {
          setShow(true);
        }}
      >
        Verwijderen
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Verwijder aanvraag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {inProgress ? (
            <Spinner animation="border" />
          ) : (
            <React.Fragment>
              <div className="form-group">
                <p>
                  De aanvraag zal worden verwijderd en verder niet in
                  behandeling worden genomen. Weet u zeker dat u deze aanvraag
                  wilt annuleren?
                </p>
              </div>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button
            disabled={inProgress}
            variant="danger"
            onClick={() => {
              setInProgress(true);
              deleteMyClaim(token)
                .then(() => {
                  addToast("De aanvraag is verwijderd.", {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  history.push(`/`);
                  handleClose();
                })
                .catch((err) => {
                  handleClose();
                  addToast(
                    err.message ||
                      "Er is iets mis gegaan bij het weigeren van de aanvraag.",
                    {
                      appearance: "error",
                      autoDismiss: true,
                    }
                  );
                });
            }}
          >
            Definitief verwijderen
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
