import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChartLine,
  faFileAlt,
  faFileInvoiceDollar,
  faHandHoldingUsd,
  faSearch,
  faTrash,
  faTrashAlt,
  faSquare,
  faUser,
  faFileContract,
  faTrafficLight,
  faCheck,
  faPlus,
  faTimes,
  faUserPlus,
  faUserEdit,
  faSortUp,
  faSortDown,
  faFilter,
  faBookmark,
  faEnvelopeOpenText,
  faChevronLeft,
  faChevronRight,
  faFingerprint,
  faFileCsv,
  faShare,
  faBars,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCalendarAlt,
  faQuestionCircle,
  faCopy,
} from "@fortawesome/free-regular-svg-icons";

export const buildIconLibrary = () => {
  library.add(faFileAlt);
  library.add(faUser);
  library.add(faFileInvoiceDollar);
  library.add(faHandHoldingUsd);
  library.add(faChartLine);
  library.add(faSearch);
  library.add(faTrash);
  library.add(faTrashAlt);
  library.add(faCalendarAlt);
  library.add(faFileContract);
  library.add(faQuestionCircle);
  library.add(faSquare);
  library.add(faTrafficLight);
  library.add(faCheck);
  library.add(faPlus);
  library.add(faTimes);
  library.add(faUserPlus);
  library.add(faUserEdit);
  library.add(faSortUp);
  library.add(faSortDown);
  library.add(faFilter);
  library.add(faBookmark);
  library.add(faEnvelopeOpenText);
  library.add(faCopy);
  library.add(faChevronLeft);
  library.add(faChevronRight);
  library.add(faFingerprint);
  library.add(faFileCsv);
  library.add(faShare);
  library.add(faBars);
  library.add(faBroom);
};
