import React from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { History } from "history";
import { Components, Paths } from "shared.wenckebachfonds.nl";

interface IAuthContextValues {
  jwt?: string;
  jwtUser?: Components.Schemas.User;
  jwtExpiresDate?: Date;
}

interface IAuthContext extends IAuthContextValues {
  login: (values: Paths.AuthLogin.Post.RequestBody) => Promise<void>;
  logout: (history: History) => void;
  isLoggedIn: () => boolean;
}

const authContextValuesIntialState: IAuthContextValues = {};
let token = sessionStorage.getItem("token");
if (token) {
  const jwtData = jwtDecode<{
    user: Components.Schemas.User;
    exp?: number;
    iat: number;
  }>(token);
  authContextValuesIntialState.jwt = token;
  authContextValuesIntialState.jwtUser = jwtData.user;
  authContextValuesIntialState.jwtExpiresDate = new Date(
    (jwtData.exp || jwtData.iat + 3600) * 1000
  );
}

export const AuthContext = React.createContext<IAuthContext>({
  ...authContextValuesIntialState,
  login: () => Promise.resolve(),
  logout: (history: History) => {},
  isLoggedIn: () => false,
});

axios.interceptors.request.use((config) => {
  if (token && !config.headers.Authorization) {
    return {
      ...config,
      ...{ headers: { Authorization: `Bearer ${token}` } },
    };
  }
  return config;
});

const AuthProvider = ({ children }: any) => {
  const [authContextValues, setAuthContextValues] = React.useState<
    IAuthContextValues
  >(authContextValuesIntialState);

  const isLoggedIn = React.useCallback(() => {
    const { jwt, jwtExpiresDate } = authContextValues;
    return !!(jwt && jwtExpiresDate && jwtExpiresDate > new Date());
  }, [authContextValues]);

  const login = React.useCallback(
    (values) =>
      axios
        .post<Paths.AuthLogin.Post.Responses.$200>("/auth/login", values)
        .then((res) => {
          // eslint-disable-next-line prefer-destructuring
          token = res.data.token as string;
          const jwtData = jwtDecode<{
            user: Components.Schemas.User;
            exp?: number;
            iat: number;
          }>(token);
          sessionStorage.setItem("token", token);
          setAuthContextValues({
            jwt: token,
            jwtUser: jwtData.user,
            jwtExpiresDate: new Date(
              (jwtData.exp || jwtData.iat + 3600) * 1000
            ),
          });
        }),
    []
  );
  const logout = React.useCallback((history: History) => {
    sessionStorage.removeItem("token");
    setAuthContextValues({});
    history.replace("/login", null);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...authContextValues,
        login,
        logout,
        isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
