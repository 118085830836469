import React from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { AuthContext } from "../../provider/AuthProvider";
import { NavLink, Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./Dashboard";
import Users from "./Users";
import User from "./User";
import Claims from "./Claims";
import Claim from "./Claim";
import Reports from "./Reports";
import Paymentfiles from "./Paymentfiles";
import Spinner from "react-bootstrap/Spinner";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default () => {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);
  const { jwtUser } = React.useContext(AuthContext);
  const { logout } = React.useContext(AuthContext);
  const history = useHistory();
  const onCollapseToggleClick = React.useCallback(() => {
    setIsCollapsed((isCollapsed) => !isCollapsed);
  }, []);

  if (!jwtUser) {
    return <Spinner animation="border" />;
  }
  const isMinimalAuditorRole =
    jwtUser.role === "auditor" || jwtUser.role === "administrator";

  return (
    <div
      className={`pages__admin pages__admin--${
        isCollapsed ? "collapsed" : "expanded"
      }`}
    >
      <Navbar expand="lg" style={{ backgroundColor: "white" }}>
        <Navbar.Brand href="/admin">
          <img
            className="img-fluid"
            src="/img/stichting-wenckebachfonds-logo.png"
            alt="Wenckebachfonds"
            title={`Built: ${process.env.REACT_APP_BUILD_VERSION}`}
            style={{ height: 50 }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingRight: 10,
              }}
            >
              {jwtUser.name} ({jwtUser.role})
            </div>
            <Button onClick={() => logout(history)}>Log uit</Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="d-flex">
        <div>
          <Nav className="flex-column">
            <div className="pages__admin__collapse-button-container">
              <Button
                variant="outline-primary"
                onClick={onCollapseToggleClick}
                size="sm"
              >
                <FontAwesomeIcon
                  icon={isCollapsed ? "chevron-right" : "chevron-left"}
                />
              </Button>
            </div>
            <Nav.Link as={NavLink} to="/admin/claims">
              <FontAwesomeIcon icon="file-alt" fixedWidth className="mr-2" />
              <span>Aanvragen</span>
            </Nav.Link>
            {isMinimalAuditorRole && (
              <Nav.Link as={NavLink} to="/admin/reports">
                <FontAwesomeIcon
                  icon="chart-line"
                  fixedWidth
                  className="mr-2"
                />
                <span>Rapportage</span>
              </Nav.Link>
            )}
            {isMinimalAuditorRole && (
              <Nav.Link as={NavLink} to="/admin/paymentfiles">
                <FontAwesomeIcon
                  icon="file-invoice-dollar"
                  fixedWidth
                  className="mr-2"
                />
                <span>Betalingen</span>
              </Nav.Link>
            )}
            <Nav.Link as={NavLink} to="/admin/users">
              <FontAwesomeIcon icon="user" fixedWidth className="mr-2" />
              <span>Gebruikers</span>
            </Nav.Link>
          </Nav>
        </div>
        <Container className="pages__admin__content" fluid>
          <Switch>
            <Route path="/admin/users" component={Users} />
            <Route path="/admin/user/:id?" component={User} />
            <Route path="/admin/claims" component={Claims} />
            <Route path="/admin/claim/:id?" component={Claim} />
            {isMinimalAuditorRole && (
              <Route path="/admin/reports" component={Reports} />
            )}
            {isMinimalAuditorRole && (
              <Route path="/admin/paymentfiles" component={Paymentfiles} />
            )}
            <Route path="/admin" component={Dashboard} />
          </Switch>
        </Container>
      </div>
    </div>
  );
};
