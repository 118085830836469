import React from "react";
import { FieldError } from "react-hook-form";
import { stxt as t } from "shared.wenckebachfonds.nl";
import Select from "react-select";
import { SchemaObject } from "openapi3-ts";
import { Option } from "react-select/src/filters";

interface IEnumStringFormControlProps {
  disabled?: boolean;
  error?: FieldError;
  isClearable?: boolean;
  name?: string;
  value?: string;
  onChange: (newValue: string) => void;
  schema: SchemaObject;
}

export default ({
  disabled,
  error,
  isClearable,
  name,
  onChange,
  schema,
  value,
}: IEnumStringFormControlProps) => (
  <Select
    isDisabled={disabled}
    isInvalid={!!error}
    isClearable={isClearable}
    placeholder="Kies een waarde..."
    name={name}
    styles={{
      container: (provided) => ({
        ...provided,
        minWidth: 190,
      }),
      singleValue: (provided) => ({
        ...provided,
        fontWeight: "normal",
      }),
      option: (provided) => ({
        ...provided,
        fontWeight: "normal",
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 2,
      }),
      placeholder: (provided) => ({
        ...provided,
        fontWeight: "normal",
      }),
    }}
    onChange={(selectedOption) => {
      // @ts-ignore
      onChange(selectedOption ? (selectedOption as Option).value : null);
    }}
    value={
      value
        ? {
            value: value,
            label: t(value),
          }
        : null
    }
    options={(schema.enum || []).map((option) => ({
      value: option,
      label: t(option),
    }))}
  />
);
