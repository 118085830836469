import { FieldError } from "react-hook-form";
import { ajv, Components, stxt as t } from "shared.wenckebachfonds.nl";
import unflatten from "unflatten";
import localizeAjv from "ajv-i18n/localize/nl";
import { isBefore } from "date-fns";

const ajvClaimValidator = ajv.compile({
  $ref: "openapi#/components/schemas/Claim",
});

export function scrollToError() {
  const elements = document.getElementsByClassName("is-invalid");
  const element = elements[0] as HTMLElement;
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
    if (element.focus) {
      element.focus();
    }
  }
}

export function getClaimValidationResolver(
  claim: Partial<Components.Schemas.Claim>
): any {
  return (formValues: Partial<Components.Schemas.Claim>) => {
    const updatedClaim = {
      ...claim,
      ...formValues,
    } as Components.Schemas.Claim;
    const isValid = ajvClaimValidator(updatedClaim);
    const errors: { [path: string]: FieldError } = {};
    if (!isValid && ajvClaimValidator.errors) {
      localizeAjv(ajvClaimValidator.errors);
      ajvClaimValidator.errors.forEach((ajvError) => {
        const { keyword, dataPath, message, params } = ajvError;
        const partsMatch = dataPath.match(/parts\[(\d+)]/);
        if (partsMatch && updatedClaim.parts) {
          const selectedPart = updatedClaim.parts[parseInt(partsMatch[1], 10)];
          if (!selectedPart) {
            return;
          }
          if (
            (selectedPart.type && ajvError.dataPath.endsWith(".type")) ||
            ajvError.message?.indexOf("oneOf") !== -1
          ) {
            // Irrelevant error. return.
            return;
          }
        }
        const errorPath = dataPath.substr(1);
        if (keyword === "required") {
          const { missingProperty } = params as any;
          errors[`${errorPath}${errorPath ? "." : ""}${missingProperty}`] = {
            type: "AjvError",
            message: "Dit veld is verplicht",
          };
          return;
        }
        errors[errorPath] = {
          type: "AjvError",
          message: t(message || "Controleer uw invoer"),
        };
      });
      console.log(updatedClaim, errors);
    }

    if (!Object.keys(errors).length && updatedClaim.parts) {
      updatedClaim.parts.forEach((part, index) => {
        if (!part.type) {
          errors[`parts[${index}].type`] = {
            type: "CustomError",
            message: "Dit veld is verplicht",
          };
        }
        const {
          startDate,
          endDate,
        } = part as Components.Schemas.TravelExpensesFamilyMemberClaimPart;
        if (
          startDate &&
          endDate &&
          isBefore(new Date(endDate), new Date(startDate))
        ) {
          errors[`parts[${index}].endDate`] = {
            type: "CustomError",
            message: "De einddatum kan niet voor de begindatum zijn",
          };
        }
      });
    }

    if (
      updatedClaim.status &&
      updatedClaim.parts &&
      ["open", "intake"].indexOf(updatedClaim.status) === -1
    ) {
      updatedClaim.parts.forEach((part, index) => {
        if (
          !part.calculation ||
          (["verzuim", "vrijwilligersdagen", "mantelzorgdagen"].indexOf(part.type) === -1
              && !part.calculation.amount)
        ) {
          errors[`parts[${index}].calculation.amount`] = {
            type: "CustomError",
            message: "Dit veld is verplicht",
          };
        }
      });
    }

    if (Object.keys(errors).length) {
      console.log(formValues, errors);
    }

    return { values: formValues, errors: unflatten(errors) };
  };
}
