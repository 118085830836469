import { Button, Card, Container, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import SchemaTable from "../../components/SchemaTable";
import {
  Components,
  dereferencedSchema,
  stxt as t,
} from "shared.wenckebachfonds.nl";
import axios, { AxiosError } from "axios";
import { useToasts } from "react-toast-notifications";
import jwtDecode from "jwt-decode";

export default () => {
  const { addToast } = useToasts();
  const [myClaims, setMyClaims] = useState<Components.Schemas.Claim[]>();
  const { public_edit_token } = useParams<{ public_edit_token: string }>();
  const { email } = jwtDecode<{ email: string }>(public_edit_token);

  React.useEffect(() => {
    if (myClaims !== undefined) {
      return;
    }
    axios
      .get("/claim/mine/all", {
        headers: { Authorization: `Bearer ${public_edit_token}` },
      })
      .then((res) => {
        setMyClaims(res.data);
      })
      .catch((err: AxiosError) => {
        addToast(
          t(err.response?.data.message) || t(err.message) || "Aanvraag mislukt",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      });
  }, [addToast, myClaims, public_edit_token]);

  if (!myClaims) {
    return <Spinner animation={"border"} />;
  }
  return (
    <Container>
      <Card>
        <div className="card-header">
          <Link to="/">
            <img
              src="/img/stichting-wenckebachfonds-logo-dark.png"
              alt="Wenckebachfonds"
              className="img-fluid mb-5"
            />
          </Link>
          <h3>Overzicht van aanvragen voor {email}</h3>
        </div>
        <Card.Body>
          <p>
            Hieronder treft u een lijst met eerdere aanvragen die vanaf {email}{" "}
            zijn aangemaakt. Deze kunt u bekijken om deze eventueel als nieuwe
            aanvraag in te dienen.
          </p>
          <SchemaTable
            schema={dereferencedSchema.components.schemas.Claim}
            schemaTitle="Claim"
            data={myClaims}
            config={{
              columnsConfig: [
                { propName: "id" },
                { propName: "createdAt" },
                { propName: "status" },
              ],
              filtersConfig: {},
            }}
            renderAction={(
              claim: Components.Schemas.Claim & { public_edit_token: string }
            ) => (
              <React.Fragment>
                <Link to={`/${claim.public_edit_token}`}>
                  <Button variant="primary" size="sm">
                    Bekijken
                  </Button>
                </Link>
              </React.Fragment>
            )}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};
