import React from "react";
import { LoadButton, ILoadButtonProps } from "./LoadButton";
import { SaveButton, ISaveButtonProps } from "./SaveButton";
import {
  ColumnSelectionDropdown,
  IColumnSelectionDropdown,
} from "./ColumnSelectionDropdown";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResetButton } from "./ResetButton";

export default ({
  schemaTitle,
  setConfig,
  schemaTableConfig,
  columnsConfig,
  schemaProperties,
}: ILoadButtonProps & ISaveButtonProps & IColumnSelectionDropdown) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const onToggle = React.useCallback((e: any, event: any) => {
    if (event === false) {
      // Seems to work when clicking outside of menu?
      setIsMenuOpen(false);
    }
  }, []);
  const onToggleClick = React.useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  return (
    <Dropdown show={isMenuOpen} onToggle={onToggle}>
      <Dropdown.Toggle
        size="sm"
        id="columnConfigMenuToggle"
        className="m-1 ml-3"
        onClick={onToggleClick}
      >
        <FontAwesomeIcon icon="bars" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <ResetButton
          schemaTitle={schemaTitle}
          setConfig={setConfig}
          close={onToggleClick}
        />
        <LoadButton schemaTitle={schemaTitle} setConfig={setConfig} />
        <SaveButton
          schemaTitle={schemaTitle}
          schemaTableConfig={schemaTableConfig}
        />
        <ColumnSelectionDropdown
          columnsConfig={columnsConfig}
          setConfig={setConfig}
          schemaProperties={schemaProperties}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};
