import { AxiosError } from "axios";
import { stxt as t } from "shared.wenckebachfonds.nl/dist/stxt";
import {
  getClaimValidationResolver,
  scrollToError,
} from "../../../../inc/react-hook-form";
import {
  Alert,
  ButtonGroup,
  Dropdown,
  Button,
  Card,
  Form,
} from "react-bootstrap";
import AdminCard from "../AdminCard";
import PreviousClaimsCard from "../PreviousClaimsCard";
import React, { Dispatch, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TrafficLightPopover, {
  IPreviousValue,
} from "../../../../components/popover/TrafficLight";
import HelpPopover from "../../../../components/popover/Help";
import { SchemaObject } from "openapi3-ts";
import SchemaFormGroup from "../../../../components/SchemaFormGroup";
import RejectDialog from "../rejectDialog";
import { useForm } from "react-hook-form";
import { getOnBlurHandler } from "../../../../inc/zipcode";
import { ApiContext } from "../../../../provider/ApiProvider";
import { useToasts } from "react-toast-notifications";
import { Prompt, useHistory } from "react-router-dom";
import { Components, openapi } from "shared.wenckebachfonds.nl";
import { AuthContext } from "../../../../provider/AuthProvider";
import { getPreviousApprovedClaims } from "../../../../inc/claim";
import "./index.scss";

const getClaimPartMissingCalculationIndex = (claim: Components.Schemas.Claim) =>
  claim.parts.findIndex((claimPart) => {
    const { calculation } = claimPart;
    const isValid =
        claimPart.calculation?.amount ||
        (["verzuim", "vrijwilligersdagen", "mantelzorgdagen"].indexOf(claimPart.type) > -1 && calculation?.amount === 0)
    return !isValid;
  });

interface IClaimFormProps {
  claim: Components.Schemas.Claim;
  setSubmitPending: Dispatch<SetStateAction<boolean>>;
}

export default (props: IClaimFormProps) => {
  const { claim, setSubmitPending } = props;
  const { jwtUser } = React.useContext(AuthContext);
  const validationResolver = React.useMemo(
    () =>
      claim
        ? getClaimValidationResolver(claim)
        : () => ({ values: {}, errors: {} } as any),
    [claim]
  );
  const {
    claims,
    claimClaim,
    startAccept,
    startEvaluation,
    restartEvaluation,
    startFinalReview,
    updateClaim,
  } = React.useContext(ApiContext);
  const {
    control,
    formState,
    errors,
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = useForm<Components.Schemas.Claim>({
    validationResolver,
    defaultValues: claim,
  });
  const { dirty } = formState;
  const onZipcodeBlur = React.useMemo(
    () => getOnBlurHandler(setValue, getValues),
    [getValues, setValue]
  );
  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setTimeout(scrollToError, 50);
    }
  }, [errors]);
  const { addToast } = useToasts();
  const [submitError, setSubmitError] = React.useState("");
  const previousClaims = React.useMemo(
    () => (claims && claim ? getPreviousApprovedClaims(claim, claims) : []),
    [claim, claims]
  );
  const previouslyApprovedClaims = React.useMemo(
    () => previousClaims.filter((claim) => claim.status === "approved"),
    [previousClaims]
  );
  const claimId = props.claim.id;
  const history = useHistory();
  const doSubmit = React.useCallback(
    (values: Components.Schemas.Claim, e: any) => {
      if (
        e &&
        e.target &&
        e.target.tagName === "FORM" &&
        e.target.className !== "pages__admin__claim__claim-form"
      ) {
        // https://trello.com/c/fINUTAZ7/101-als-administrator-wil-ik-een-klant-per-telefoon-snel-beantwoorden-dmv-personeelsnummer-in-te-voeren-en-een-overzicht-met-vergoed
        // nested forms in react-hooks-form suck
        return;
      }
      setSubmitError("");
      setSubmitPending(true);
      return updateClaim({
        ...values,
        id: claimId,
      })
        .then(() => {
          addToast("De aanvraag is opgeslagen", {
            appearance: "success",
            autoDismiss: true,
          });
          if (e && e.target && e.target.id === "saveAndDashboardBtn") {
            // https://trello.com/c/fINUTAZ7/101-als-administrator-wil-ik-een-klant-per-telefoon-snel-beantwoorden-dmv-personeelsnummer-in-te-voeren-en-een-overzicht-met-vergoed
            // nested forms in react-hooks-form suck
            history.push("/admin/");
          }
          if (e && e.target && e.target.id === "saveAndClaimsBtn") {
            // https://trello.com/c/fINUTAZ7/101-als-administrator-wil-ik-een-klant-per-telefoon-snel-beantwoorden-dmv-personeelsnummer-in-te-voeren-en-een-overzicht-met-vergoed
            // nested forms in react-hooks-form suck
            history.push("/admin/claims");
          }
          setSubmitPending(false);
        })
        .catch((err: AxiosError<Components.Responses.ApiError>) => {
          const { response } = err;
          if (response && response.data && response.data.validationErrors) {
            response.data.validationErrors.forEach((validationError) => {
              setError(
                validationError.dataPath.substr(1),
                validationError.keyword,
                t(validationError.message || "")
              );
            });
          }
          const message = t(err.message) || "Opslaan aanvraag mislukt";
          addToast(message, {
            appearance: "error",
            autoDismiss: true,
          });
          setSubmitPending(false);
          setSubmitError(message);

          setTimeout(scrollToError, 50);
          throw err;
        });
    },
    [addToast, history, claimId, setError, setSubmitPending, updateClaim]
  );

  const [, parentSchema] = openapi.components.schemas.Claim.allOf;
  const properties = (parentSchema as SchemaObject).properties as {
    [propertyName: string]: SchemaObject;
  };
  const required = (parentSchema as SchemaObject).required || [];
  const formValues = watch();
  const isDisabled = !!(
    claim.status === "rejectedConfirmed" || claim.paymentFileCreatedAt
  );
  return (
    <Form className="pages__admin__claim__claim-form">
      <Prompt
        when={dirty}
        message="Er zijn niet opgeslagen wijzigingen. Weet je zeker dat je de pagina wilt verlaten?"
      />
      {submitError && <Alert variant="danger">{submitError}</Alert>}
      <h3 className="py-3">
        Aanvraag{" "}
        {claim.id
          ? `${claim.id.substr(0, 10)} (${t(claim.status as string)})`
          : ""}
      </h3>
      <AdminCard
        disabled={isDisabled}
        errors={errors}
        control={control}
        register={register}
        adminUserId={formValues.adminUserId}
        evaluatorUserId={formValues.evaluatorUserId}
        finalReviewerUserId={formValues.finalReviewerUserId}
      />
      <PreviousClaimsCard previousClaims={previousClaims} />
      <Card>
        <Card.Body>
          <Card.Title>
            Gegevens aanvraag{claim.id ? ` (${claim.id})` : null}
          </Card.Title>
          {(Object.keys(properties as {}) as Array<
            keyof Components.Schemas.Claim
          >)
            .filter(
              (propertyName) =>
                [
                  "adminUserId",
                  "evaluatorUserId",
                  "finalReviewerUserId",
                  "status",
                  "internalComment",
                  "originalPaperEmailClaimFileIds",
                  "paymentFileCreatedAt",
                ].indexOf(propertyName) === -1
            )
            // make sure parts is rendered last
            .sort((propertyNameA, propertyNameB) =>
              propertyNameA === "parts" ? 1 : propertyNameB === "parts" ? -1 : 0
            )
            .map((propertyName) => {
              let trafficLight: React.ReactElement = (
                <FontAwesomeIcon
                  role="button"
                  icon="traffic-light"
                  className="mr-2 text-secondary"
                />
              );

              // GROEN - komt voor op een eerdere, geaccepteerde claim
              // ROOD - wijkt af van een eerdere geaccepteerde claim
              if (previouslyApprovedClaims.length) {
                // claim, datum, waarde
                const previouslyDifferentValues = previouslyApprovedClaims.reduce<
                  IPreviousValue[]
                >((prev, previousClaim) => {
                  const value = previousClaim[propertyName];
                  if (
                    value !== undefined &&
                    value !== null &&
                    value !== formValues[propertyName]
                  ) {
                    prev.push({
                      date: previousClaim.createdAt,
                      propertyName,
                      value,
                      claimId: previousClaim.id,
                    });
                  }
                  return prev;
                }, []);

                trafficLight = previouslyDifferentValues.length ? (
                  <TrafficLightPopover
                    previouslyDifferentValues={previouslyDifferentValues}
                    setValue={setValue}
                  />
                ) : (
                  <FontAwesomeIcon
                    role="button"
                    icon="traffic-light"
                    className="mr-2 text-success"
                  />
                );
              }

              let label: React.ReactElement | undefined;

              switch (propertyName) {
                case "personnelNumber":
                  label = (
                    <span>
                      Personeelsnummer
                      <HelpPopover title="Personeelsnummer">
                        <p>
                          Een personeelsnummer bestaat uit 6 cijfers en maakt
                          het mogelijk uw aanvraag sneller te behandelen.
                        </p>
                        <p>Graag invullen indien bekend</p>
                      </HelpPopover>
                    </span>
                  );
                  break;

                case "phone":
                  label = (
                    <span>
                      Telefoonnummer
                      <HelpPopover title="Telefoonnummer">
                        <p>
                          Een telefoonnummer dient te bestaan uit ten minste 10
                          karakters.
                        </p>
                        <p>
                          De karakters "+", "-", spaties en cijfers zijn
                          toegestaan
                        </p>
                      </HelpPopover>
                    </span>
                  );
              }

              const schema = properties[propertyName] as SchemaObject;
              const isRequired = required.indexOf(propertyName) >= 0;
              return (
                <SchemaFormGroup
                  disabled={isDisabled}
                  trafficLight={trafficLight}
                  label={label}
                  key={propertyName}
                  defaultValue={claim[propertyName]}
                  schema={schema}
                  name={propertyName}
                  control={control}
                  register={register}
                  isRequired={isRequired}
                  // @ts-ignore
                  error={errors[propertyName]}
                  onBlur={
                    propertyName === "zipcode" ? onZipcodeBlur : undefined
                  }
                />
              );
            })}
        </Card.Body>
      </Card>
      <React.Fragment>
        <Dropdown as={ButtonGroup}>
          <Button
            variant="primary"
            onClick={handleSubmit(doSubmit)}
            disabled={isDisabled}
          >
            Aanvraag opslaan
          </Button>
          <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={handleSubmit(doSubmit)}
              disabled={isDisabled}
            >
              <FontAwesomeIcon icon="file-alt" className="mr-2" />
              Opslaan
            </Dropdown.Item>
            <Dropdown.Item
              onClick={handleSubmit(doSubmit)}
              disabled={isDisabled}
              id="saveAndDashboardBtn"
            >
              <span
                className="fa-layers fa-fw mr-2"
                title="Opslaan en naar dashboard"
              >
                <FontAwesomeIcon icon="file-alt" fixedWidth className="mr-1" />
                <FontAwesomeIcon
                  icon="share"
                  transform="shrink-4 down-1 right-5"
                />
              </span>
              Opslaan en naar dashboard
            </Dropdown.Item>
            <Dropdown.Item
              onClick={handleSubmit(doSubmit)}
              disabled={isDisabled}
              id="saveAndClaimsBtn"
            >
              <span
                className="fa-layers fa-fw mr-2"
                title="Opslaan en naar aanvragen"
              >
                <FontAwesomeIcon icon="file-alt" fixedWidth className="mr-1" />
                <FontAwesomeIcon
                  icon="share"
                  transform="shrink-4 down-1 right-5 flip-h"
                />
              </span>
              Opslaan en naar aanvragen
            </Dropdown.Item>
            <Dropdown.Item
              onClick={handleSubmit((claim) => {
                setSubmitError("");
                setSubmitPending(true);
                return updateClaim({
                  ...claim,
                  id: undefined,
                })
                  .then((updatedClaim) =>
                    // also update the claim to set the admin only fields after creation of the copy
                    updateClaim({
                      ...updatedClaim,
                      ...claim,
                      id: updatedClaim.id,
                      // the creation also adds the public_edit_token for the frontEnd, but should be removed here
                      // @ts-ignore
                      public_edit_token: undefined,
                      // We also need to remove evaluatorIds since they may be in conflict with the new administrator
                      // This needs to be "claimed" by someone first
                      // https://trello.com/c/xQp1FZNF/133-bij-een-gekopieerde-aanvraag-kan-ik-hem-niet-ter-beoordel
                      adminUserId: undefined,
                      evaluatorUserId: undefined,
                      finalReviewerUserId: undefined,
                    })
                  )
                  .then((updatedClaim) => {
                    setSubmitPending(false);
                    addToast("De aanvraag is gekopieerd en opgeslagen.", {
                      appearance: "success",
                      autoDismiss: true,
                    });
                    history.push(`/admin/claim/${updatedClaim.id}`);
                  })
                  .catch((err: AxiosError<Components.Responses.ApiError>) => {
                    const { response } = err;
                    if (
                      response &&
                      response.data &&
                      response.data.validationErrors
                    ) {
                      response.data.validationErrors.forEach(
                        (validationError) => {
                          setError(
                            validationError.dataPath.substr(1),
                            validationError.keyword,
                            t(validationError.message || "")
                          );
                        }
                      );
                    }
                    const message =
                      t(err.message) || "Aanvraag kopiëren mislukt";
                    addToast(message, {
                      appearance: "error",
                      autoDismiss: true,
                    });
                    setSubmitPending(false);
                    setSubmitError(message);

                    setTimeout(scrollToError, 50);
                    throw err;
                  });
              })}
            >
              <FontAwesomeIcon icon={["far", "copy"]} className="mr-2" />
              Opslaan als kopie
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {claim.status === "intake" && claim.adminUserId === jwtUser?.id && (
          <React.Fragment>
            <Button
              disabled={
                !formValues.evaluatorUserId || !formValues.finalReviewerUserId
              }
              variant="success"
              className="float-right ml-3"
              onClick={handleSubmit((claim) => {
                // enforce a "calculation" on all claimParts
                // https://trello.com/c/KFwjnK0I/73-validatie-bedrag-pas-bij-status-evaluatie-niet-indien-intake
                const partMissingCalculationIndex = getClaimPartMissingCalculationIndex(
                  claim
                );
                if (partMissingCalculationIndex >= 0) {
                  setError(
                    `parts[${partMissingCalculationIndex}].calculation.amount`,
                    "PreSubmitError",
                    "Invoer is verplicht voor een volgende stap"
                  );
                  return;
                }

                setSubmitPending(true);
                startEvaluation({
                  ...claim,
                  id: props.claim.id,
                })
                  .then(() => {
                    addToast(
                      "De aanvraag is ter goedkeuring voorgelegd aan 1e beoordelaar.",
                      {
                        appearance: "success",
                        autoDismiss: true,
                      }
                    );
                    history.push(`/admin/claims/`);
                  })
                  .catch((err) => {
                    setSubmitPending(false);
                    addToast(
                      err.message ||
                        "Er is iets mis gegaan bij het starten van het evaluatie proces van de aanvraag.",
                      {
                        appearance: "error",
                        autoDismiss: true,
                      }
                    );
                  });
              })}
            >
              Opslaan en start evaluatie
            </Button>
          </React.Fragment>
        )}
        {claim.status === "evaluating" &&
          claim.evaluatorUserId === jwtUser?.id && (
            <React.Fragment>
              <Button
                disabled={
                  !formValues.evaluatorUserId || !formValues.finalReviewerUserId
                }
                variant="success"
                className="float-right ml-3"
                onClick={handleSubmit((claim) => {
                  // enforce a "calculation" on all claimParts
                  // https://trello.com/c/KFwjnK0I/73-validatie-bedrag-pas-bij-status-evaluatie-niet-indien-intake
                  const partMissingCalculationIndex = getClaimPartMissingCalculationIndex(
                    claim
                  );
                  if (partMissingCalculationIndex >= 0) {
                    setError(
                      `parts[${partMissingCalculationIndex}].calculation.amount`,
                      "PreSubmitError",
                      "Invoer is verplicht voor een volgende stap"
                    );
                    return;
                  }
                  setSubmitPending(true);
                  startFinalReview({
                    ...claim,
                    id: props.claim.id,
                  })
                    .then(() => {
                      addToast(
                        "De aanvraag is ter goedkeuring voorgelegd aan de 2e beoordelaar.",
                        {
                          appearance: "success",
                          autoDismiss: true,
                        }
                      );
                      history.push(`/admin/claims/`);
                    })
                    .catch((err) => {
                      setSubmitPending(false);
                      addToast(
                        err.message ||
                          "Er is iets mis gegaan bij het doorzetten naar de volgende beoordelaar.",
                        {
                          appearance: "error",
                          autoDismiss: true,
                        }
                      );
                    });
                })}
              >
                Accepteer aanvraag, zet door naar 2e beoordelaar
              </Button>
            </React.Fragment>
          )}
        {claim.status === "finalReviewing" &&
          claim.finalReviewerUserId === jwtUser?.id && (
            <React.Fragment>
              <Button
                disabled={
                  !formValues.evaluatorUserId || !formValues.finalReviewerUserId
                }
                variant="success"
                className="float-right ml-3"
                onClick={handleSubmit((claim) => {
                  // enforce a "calculation" on all claimParts
                  // https://trello.com/c/KFwjnK0I/73-validatie-bedrag-pas-bij-status-evaluatie-niet-indien-intake
                  const partMissingCalculationIndex = getClaimPartMissingCalculationIndex(
                    claim
                  );
                  if (partMissingCalculationIndex >= 0) {
                    setError(
                      `parts[${partMissingCalculationIndex}].calculation.amount`,
                      "PreSubmitError",
                      "Invoer is verplicht voor een volgende stap"
                    );
                    return;
                  }
                  setSubmitPending(true);
                  startAccept({
                    ...claim,
                    id: props.claim.id,
                  })
                    .then(() => {
                      addToast(
                        "De aanvraag is geaccepteerd. De aanvrager is op de hoogte gebracht.",
                        {
                          appearance: "success",
                          autoDismiss: true,
                        }
                      );
                      history.push(`/admin/claims/`);
                    })
                    .catch((err: Error) => {
                      setSubmitPending(false);
                      addToast(
                        err.message ||
                          "Er is iets mis gegaan bij het accepteren van de aanvraag.",
                        {
                          appearance: "error",
                          autoDismiss: true,
                        }
                      );
                    });
                })}
              >
                Accepteer aanvraag, start uitbetaling
              </Button>
            </React.Fragment>
          )}
        {claim.status === "rejectedUnconfirmed" &&
          claim.adminUserId === jwtUser?.id && (
            <React.Fragment>
              <Button
                variant="success"
                className="float-right ml-3"
                onClick={handleSubmit((claim) => {
                  // enforce a "calculation" on all claimParts
                  // https://trello.com/c/KFwjnK0I/73-validatie-bedrag-pas-bij-status-evaluatie-niet-indien-intake
                  const partMissingCalculationIndex = getClaimPartMissingCalculationIndex(
                    claim
                  );
                  if (partMissingCalculationIndex >= 0) {
                    setError(
                      `parts[${partMissingCalculationIndex}].calculation.amount`,
                      "PreSubmitError",
                      "Invoer is verplicht voor een volgende stap"
                    );
                    return;
                  }
                  setSubmitPending(true);
                  restartEvaluation({
                    ...claim,
                    id: props.claim.id,
                  })
                    .then(() => {
                      addToast(
                        "De aanvraag is opnieuw ingediend ter beoordeling. De beoordelaar is op de hoogte gebracht.",
                        {
                          appearance: "success",
                          autoDismiss: true,
                        }
                      );
                      history.push(`/admin/claims/`);
                    })
                    .catch((err: Error) => {
                      setSubmitPending(false);
                      addToast(
                        err.message ||
                          "Er is iets mis gegaan bij het opnieuw indienen van de aanvraag.",
                        {
                          appearance: "error",
                          autoDismiss: true,
                        }
                      );
                    });
                })}
              >
                Opslaan, start evaluatie (opnieuw)
              </Button>
            </React.Fragment>
          )}
        {claim.status === "open" ? (
          <Button
            variant="primary"
            className="float-right"
            onClick={() => {
              if (claim.status === "open") {
                claimClaim(claim)
                  .then(() => {
                    addToast("Deze aanvraag is in behandeling genomen.", {
                      appearance: "success",
                      autoDismiss: true,
                    });
                  })
                  .catch((err: AxiosError) => {
                    addToast(err.response?.data.message || err.message, {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  });
                return;
              }
              history.push(`/admin/claim/${claim.id}`);
            }}
          >
            Start intake
          </Button>
        ) : null}
        <RejectDialog claim={claim} />
      </React.Fragment>
    </Form>
  );
};
