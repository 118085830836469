import { Form } from "react-bootstrap";
import React from "react";
import { FieldError } from "react-hook-form";

interface IIntegerFormControlProps {
  disabled?: boolean;
  error?: FieldError;
  name?: string;
  value?: number;
  onChange: (newValue: number | undefined) => void;
}

export default ({
  disabled,
  error,
  name,
  onChange,
  value,
}: IIntegerFormControlProps) => (
  <Form.Control
    disabled={disabled}
    type="number"
    name={name}
    isInvalid={!!error}
    value={value || ""}
    onChange={(e) => {
      onChange(parseInt(e.currentTarget.value, 10));
    }}
  />
);
