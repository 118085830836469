import { Form } from "react-bootstrap";
import React from "react";
import { FieldError } from "react-hook-form";
import { SchemaObject } from "openapi3-ts";
import TextareaAutosize from "react-textarea-autosize";

interface IClearableStringFormControlProps {
  disabled?: boolean;
  error?: FieldError;
  name?: string;
  value?: string;
  onChange: (newValue: string | undefined) => void;
  type: string;
  control?: any | undefined;
  defaultValue?: any;
  isUserInputRequired?: boolean;
  schema: SchemaObject;
  onBlur?: (e: any) => void;
}

export default ({
  disabled,
  error,
  type,
  name,
  onChange,
  defaultValue,
  isUserInputRequired,
  onBlur,
  schema,
  value,
}: IClearableStringFormControlProps) => (
  <Form.Control
    className="clearable-string-form"
    as={name && name.endsWith("Comment") ? TextareaAutosize : "input"}
    name={name}
    defaultValue={defaultValue}
    isInvalid={!!error}
    disabled={disabled}
    required={isUserInputRequired}
    //@ts-ignore
    pattern={schema.pattern}
    onChange={(e) => {
      onChange(e.target.value || undefined);
    }}
    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
      const trimmedValue = e.target.value.trim();
      if (value !== trimmedValue) {
        e.target.value = trimmedValue;
        onChange(trimmedValue || undefined);
      }
      if (onBlur) {
        onBlur(e);
      }
    }}
    type={type}
    value={value || ""}
  />
);
