import { Form } from "react-bootstrap";
import React from "react";
import { FieldError } from "react-hook-form";

interface IBooleanFormControlProps {
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  error?: FieldError;
  onChange: (newValue: boolean) => void;
}

export default ({
  disabled,
  error,
  label,
  name,
  onChange,
  checked,
}: IBooleanFormControlProps) => (
  <Form.Check
    isInvalid={!!error}
    name={name}
    id={name}
    label={label}
    checked={!!checked}
    custom={true}
    disabled={disabled}
    onChange={() => {
      onChange(!checked);
    }}
  />
);
