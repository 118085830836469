import axios from "axios";

export interface IFile {
  id: string;
  name?: string;
}

export function download(file: IFile) {
  return axios.get(`/file/${file.id}`, { responseType: "blob" }).then((res) => {
    // https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication
    const anchor = document.createElement("a");
    const objectUrl = window.URL.createObjectURL(res.data);
    anchor.href = objectUrl;
    anchor.download = file.name || file.id;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  });
}
