import React from "react";
import { Alert, Card, Spinner } from "react-bootstrap";
import { ApiContext } from "../../../provider/ApiProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import SchemaTable from "../../../components/SchemaTable";
import { Components, dereferencedSchema } from "shared.wenckebachfonds.nl";
import { getDefaultSchemaConfig } from "../../../components/SchemaTable/config";
import LoggedPaymentHistory from "./LoggedPaymentHistory";
import * as Promise from "bluebird";
import { downloadPaymentFile } from "../../../inc/claim";
import { useToasts } from "react-toast-notifications";

export default () => {
  const { jwtUser } = React.useContext(AuthContext);
  const {
    claims,
    hydrateClaims,
    updateClaim,
    updateLoggedPayment,
  } = React.useContext(ApiContext);

  const { addToast } = useToasts();
  React.useEffect(() => {
    if (claims === undefined) {
      hydrateClaims();
    }
  }, [hydrateClaims, claims]);

  if (!claims || !jwtUser) {
    return <Spinner animation={"border"} />;
  }
  if (jwtUser.role === "auditor") {
    return (
      <div className="flex-grow-1">
        <Card>
          <Card.Body>
            <Alert variant="warning">
              <Alert.Heading>
                Onvoldoende rechten voor maken van betaalbestanden
              </Alert.Heading>
              <p>
                U dient een administrator te zijn om betalingsbestanden te
                kunnen opstellen.
              </p>
            </Alert>
            <LoggedPaymentHistory />
          </Card.Body>
        </Card>
      </div>
    );
  }
  if (jwtUser.role !== "administrator") {
    return (
      <div className="flex-grow-1">
        <Card>
          <Card.Body>
            <Alert variant="warning">
              <Alert.Heading>Onvoldoende rechten</Alert.Heading>
              <p>
                U dient een administrator te zijn om betalingsbestanden te
                kunnen opstellen.
              </p>
            </Alert>
          </Card.Body>
        </Card>
      </div>
    );
  }
  return (
    <div className="flex-grow-1">
      <Card>
        <Card.Body>
          <SchemaTable
            isConfigurable={true}
            isFilterable={true}
            onSubmit={(ids: string[]) => {
              const paymentDate = new Date();
              const paymentClaims = claims.filter(
                (claim) => ids.indexOf(claim.id as string) >= 0
              );
              downloadPaymentFile(
                paymentClaims,
                paymentDate,
                jwtUser.id as string
              )
                .then((loggedPayment) => updateLoggedPayment(loggedPayment))
                .then(() =>
                  // @ts-ignore
                  Promise.mapSeries(
                    paymentClaims,
                    (claim: Components.Schemas.Claim) =>
                      updateClaim({
                        ...claim,
                        paymentFileCreatedAt: paymentDate.toISOString(),
                      })
                  )
                )
                .then(() => {
                  addToast("Actie succesvol. De aanvragen zijn bijgewerkt.", {
                    appearance: "success",
                    autoDismiss: true,
                  });
                })
                .catch((err: Error) => {
                  addToast(err.message, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                });
            }}
            schema={dereferencedSchema.components.schemas.Claim}
            schemaTitle="ToBePaidClaims"
            data={claims}
            config={
              // always use default config, prevent https://jira.tools.kingsquare.nl/browse/WBF-3
              getDefaultSchemaConfig("ToBePaidClaims")
            }
          />
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <LoggedPaymentHistory />
        </Card.Footer>
      </Card>
    </div>
  );
};
