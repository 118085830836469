import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Bluebird from "bluebird";
import { Button, ListGroup, Spinner } from "react-bootstrap";
import { FieldError } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { AuthContext } from "../../../provider/AuthProvider";
import { useParams } from "react-router-dom";
import { download, IFile } from "../../../inc/file";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Components } from "shared.wenckebachfonds.nl";

interface IFilesUploadControlProps {
  disabled?: boolean;
  error?: FieldError;
  name: string;
  value?: string[];
  onChange: (newValue: string[]) => void;
}

let publicFilesPromise: Promise<Components.Schemas.File[]>;

export default (props: IFilesUploadControlProps) => {
  const { addToast } = useToasts();
  const { disabled, error, onChange, value } = props;
  const [inProgress, setInProgress] = React.useState(false);
  const [files, setFiles] = useState<IFile[]>(
    (value || []).map((id: string) => ({ id }))
  );
  const { isLoggedIn } = React.useContext(AuthContext);
  // no, this can not be simplified
  // noinspection PointlessBooleanExpressionJS
  const { public_edit_token } = useParams<{ public_edit_token: string }>();

  React.useEffect(() => {
    let fileNameUpdated = false;
    Bluebird.map(
      files,
      (file): Promise<IFile> => {
        if (!file.name) {
          fileNameUpdated = true;

          if (isLoggedIn()) {
            return axios
              .get(`/file/${file.id}/meta`)
              .then((res) => ({
                id: file.id,
                name: res.data.name,
              }))
              .catch(() => ({
                id: file.id,
                name: "? - Bestand niet gevonden -",
              }));
          }

          if (public_edit_token) {
            if (!publicFilesPromise) {
              publicFilesPromise = axios
                .get("/file/mine", {
                  headers: { Authorization: `Bearer ${public_edit_token}` },
                })
                .then((res) => res.data);
            }

            return publicFilesPromise.then((publicFiles) => {
              const publicFile = publicFiles.find(
                (publicFile) => publicFile.id === file.id
              );
              return {
                ...file,
                name: publicFile
                  ? publicFile.name
                  : "? - Bestand niet gevonden",
              };
            });
          }
          return Promise.resolve({
            id: file.id,
            name: "? - Bestand niet gevonden -",
          });
        }

        return Promise.resolve(file);
      }
    ).then((updatedFiles: IFile[]) => {
      if (fileNameUpdated) {
        setFiles(updatedFiles);
      }
    });
  }, [public_edit_token, isLoggedIn, files]);

  const updateFiles = React.useCallback(
    (newFiles: IFile[]) => {
      onChange(newFiles.map((newFile) => newFile.id));
      setFiles(newFiles);
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (droppedFiles) => {
      setInProgress(true);
      Bluebird.map(droppedFiles, (file) => {
        const formData = new FormData();
        formData.append("file", file);
        return axios.post("/file", formData);
      })
        .then((uploadedFileResponses) => {
          const newIds = uploadedFileResponses.map((res) => res.data.id);
          updateFiles([
            ...files,
            ...newIds.map((newId, index) => ({
              id: newId,
              name: droppedFiles[index].name,
            })),
          ]);
          setInProgress(false);
        })
        .catch((e) => {
          window.alert(JSON.stringify(e));
        });
    },
  });

  if (inProgress) {
    return (
      <Spinner animation="border" role="status" className="ml-auto">
        <span className="sr-only">Bezig met uploaden...</span>
      </Spinner>
    );
  }

  return (
    <div className={error ? "is-invalid" : undefined}>
      <ListGroup>
        {files.map((file) => (
          <ListGroup.Item key={file.id}>
            {isLoggedIn() ? (
              <Button
                variant="link"
                onClick={() => {
                  download(file).catch((err) => {
                    addToast(err.message, {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  });
                }}
              >
                {file.name || file.id}
              </Button>
            ) : (
              file.name || file.id
            )}
            <Button
              size="sm"
              className="float-right mt-1"
              disabled={disabled}
              onClick={() => {
                updateFiles([
                  ...files.filter((oldFile) => oldFile.id !== file.id),
                ]);
              }}
            >
              <FontAwesomeIcon icon="trash" aria-hidden="true" />
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <div
        {...getRootProps({ className: "dropzone", style: { outline: "none" } })}
      >
        <input {...getInputProps()} disabled={disabled} />
        <Button className="mt-3" variant="secondary" disabled={disabled}>
          Upload {files.length > 0 ? "extra " : ""}bestand
        </Button>
      </div>
    </div>
  );
};
