import { Button, Modal, Spinner } from "react-bootstrap";
import React from "react";
import { ApiContext } from "../../../provider/ApiProvider";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../provider/AuthProvider";
import TextareaAutosize from "react-textarea-autosize";
import { Components } from "shared.wenckebachfonds.nl";
import { AxiosError } from "axios";
import "./rejectDialog.scss";

export default (props: { claim: Partial<Components.Schemas.Claim> }) => {
  const { claim } = props;
  const { addToast } = useToasts();
  const [show, setShow] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const { rejectClaim } = React.useContext(ApiContext);
  const { jwtUser } = React.useContext(AuthContext);
  const history = useHistory();
  const { register, getValues } = useForm();

  const handleClose = React.useCallback(() => {
    setInProgress(false);
    setShow(false);
  }, []);
  const isClaimAdministrator = jwtUser && claim.adminUserId === jwtUser.id;

  if (
    !jwtUser ||
    !claim.id ||
    claim.status === "open" ||
    (claim.status === "intake" && !isClaimAdministrator) ||
    (claim.status === "evaluating" && claim.evaluatorUserId !== jwtUser.id) ||
    (claim.status === "finalReviewing" &&
      claim.finalReviewerUserId !== jwtUser.id) ||
    (claim.status &&
      ["rejectedConfirmed", "approved"].indexOf(claim.status) >= 0)
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <Button
        disabled={inProgress}
        variant="danger"
        className="float-right"
        onClick={() => {
          setShow(true);
        }}
      >
        Weigeren
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Weiger aanvraag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {inProgress ? (
            <Spinner animation="border" />
          ) : (
            <React.Fragment>
              <div className="form-group">
                <label htmlFor="message">
                  Weet u zeker dat u deze aanvraag
                  {isClaimAdministrator ? (
                    <strong> definitief</strong>
                  ) : null}{" "}
                  wilt weigeren?
                </label>
                <TextareaAutosize
                  className="form-control pages__admin__claim__rejectDialog__message"
                  id="message"
                  ref={register}
                  name="message"
                  placeholder={`Geef een persoonlijke uitleg in de mail richting de ${
                    isClaimAdministrator ? "aanvrager" : "beoordelaar"
                  } waarom de aanvraag geweigerd is.`}
                />
              </div>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button
            className="mr-auto"
            variant="secondary"
            onClick={handleClose}
            disabled={inProgress}
          >
            Annuleren
          </Button>
          <Button
            disabled={inProgress}
            variant="danger"
            onClick={() => {
              const { message } = getValues();
              setInProgress(true);
              rejectClaim(claim as Components.Schemas.Claim, message)
                .then(() => {
                  addToast("De aanvraag is geweigerd.", {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  history.push(`/admin/claims/`);
                  handleClose();
                })
                .catch((err: AxiosError) => {
                  handleClose();
                  addToast(
                    err.response?.data.message ||
                      err.message ||
                      "Er is iets mis gegaan bij het weigeren van de aanvraag.",
                    {
                      appearance: "error",
                      autoDismiss: true,
                    }
                  );
                });
            }}
          >
            {isClaimAdministrator ? "Definitief weigeren" : "Weigeren"}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
