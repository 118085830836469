import React from "react";
import { Alert, Button, Card, Spinner } from "react-bootstrap";
import axios from "axios";
import { format } from "date-fns";
import PatchLogMessage from "./PatchLogMessage";
import { ApiContext } from "../../../../provider/ApiProvider";
import { AuthContext } from "../../../../provider/AuthProvider";
import "./index.scss";
import { Components } from "shared.wenckebachfonds.nl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IClaimLogPaneProps {
  claim: Components.Schemas.Claim;
}

export default ({ claim }: IClaimLogPaneProps) => {
  const [events, setEvents] = React.useState<Components.Schemas.Event[]>();
  const { jwtUser } = React.useContext(AuthContext);
  const { users } = React.useContext(ApiContext);
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);
  const onCollapseToggleClick = React.useCallback(() => {
    setIsCollapsed((isCollapsed) => !isCollapsed);
  }, []);
  React.useEffect(() => {
    if (!claim.id) {
      return;
    }
    axios
      .get(`/claim/${claim.id}/events`)
      .then((res) => {
        setEvents(res.data.reverse());
      })
      .catch(() => {
        setEvents([]);
        return;
      });
  }, [claim.id]);
  if (!claim.id) {
    return null;
  }
  return (
    <Card
      className={`pages__admin__claim__claim-log-pane pages__admin__claim__claim-log-pane--${
        isCollapsed ? "collapsed" : "expanded"
      }`}
    >
      <Card.Body>
        <Card.Title>
          <Button
            variant="outline-primary"
            className="mr-2"
            onClick={onCollapseToggleClick}
            size="sm"
          >
            <FontAwesomeIcon
              icon={isCollapsed ? "chevron-left" : "chevron-right"}
            />
          </Button>
          <span>Tijdlijn</span>
        </Card.Title>
        <div className="pages__admin__claim__claim-log-pane__body-contents">
          {!events || !users ? (
            <Spinner animation={"border"} />
          ) : (
            <ol className="list-unstyled">
              {events.map((event, eventIndex) => (
                <li key={eventIndex}>
                  <Alert
                    variant={
                      jwtUser && event.userId === jwtUser.id
                        ? "success"
                        : "warning"
                    }
                    style={{ overflow: "hidden" }}
                  >
                    <div className="d-flex">
                      <time className="flex-grow-1">
                        {format(new Date(event.datetime), "dd-MM-yyyy HH:mm")}
                      </time>
                      <div className="text-right">
                        {users.find((user) => user.id === event.userId)?.name}
                      </div>
                    </div>
                    <dl>
                      {event.payload.map((patch, patchIndex) => (
                        <PatchLogMessage
                          claim={claim}
                          patch={patch}
                          key={patchIndex}
                        />
                      ))}
                    </dl>
                    {event.eventContext?.message ? (
                      <div className="border p-1" style={{ clear: "left" }}>
                        <strong>{event.eventContext?.message}</strong>
                      </div>
                    ) : null}
                  </Alert>
                </li>
              ))}
            </ol>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
