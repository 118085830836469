import { openapi } from "shared.wenckebachfonds.nl";
import { EFilterCompare, IFilterConfig } from "../../index";
import { Form } from "react-bootstrap";
import NumberFormControl from "../../../form/NumberFormControl";
import EnumStringFormControl from "../../../form/EnumStringFormControl";
import React from "react";

interface IDateFilterThProps {
  propName: string;
  onChange: (newValue: IFilterConfig[]) => void;
  value: IFilterConfig[];
}

export default ({ onChange, value, propName }: IDateFilterThProps) => {
  const valueColumnFilter = value.find(
    (columnFilter) => columnFilter.compare === EFilterCompare.CHECK_MIN_AMOUNT
  );
  const typeColumnFilter = value.find(
    (columnFilter) => columnFilter.compare === EFilterCompare.CHECK_TYPE
  );
  return (
    <th className="components__schema-table__filter-tr__date-filter-th">
      <Form.Group className="d-flex" controlId={`${propName}_gt`}>
        <Form.Label style={{ width: 80 }}>Min.</Form.Label>
        <NumberFormControl
          onChange={(inputValue) => {
            const newValue = [];
            if (inputValue !== undefined) {
              newValue.push({
                compare: EFilterCompare.CHECK_MIN_AMOUNT,
                value: inputValue,
              });
            }
            if (typeColumnFilter) {
              newValue.push(typeColumnFilter);
            }
            return onChange(newValue);
          }}
          value={valueColumnFilter?.value as number}
        />
      </Form.Group>
      <EnumStringFormControl
        isClearable={true}
        onChange={(value) => {
          const newValue = [];
          if (valueColumnFilter) {
            newValue.push(valueColumnFilter);
          }
          if (value) {
            newValue.push({
              compare: EFilterCompare.CHECK_TYPE,
              value,
            });
          }
          return onChange(newValue);
        }}
        schema={{
          enum: Object.keys(
            (openapi.components.schemas.Claim.allOf[1].properties as any).parts
              .items.discriminator.mapping
          ),
        }}
        value={typeColumnFilter?.value as string}
      />
    </th>
  );
};
