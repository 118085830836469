import React from "react";
import { stxt as t } from "shared.wenckebachfonds.nl/dist/stxt";
import {
  Components,
  getSchemaFromClaimPartType,
  openapi,
} from "shared.wenckebachfonds.nl";
import mergeAllOf from "json-schema-merge-allof";
import { SchemaObject } from "openapi3-ts";
import { ApiContext } from "../../../../../provider/ApiProvider";
import ucfirst from "locutus/php/strings/ucfirst";
import is_numeric from "locutus/php/var/is_numeric";
import number_format from "locutus/php/strings/number_format";
import { format as formatDate } from "date-fns";
import DownloadButton from "./DownloadButton";

interface IEventProps {
  claim: Components.Schemas.Claim;
  patch: Components.Schemas.JsonPatchOperation;
}

const mergedClaimSchema = mergeAllOf(
  openapi.components.schemas.Claim as any
) as SchemaObject;
const { properties = {} } = mergedClaimSchema;

const formatValue = (value: any, schema?: SchemaObject, propName?: string) => {
  if (!schema || !propName) {
    return JSON.stringify(value, null, "\t");
  }
  const { properties = {} } = schema;

  if (propName.endsWith("FileIds")) {
    if (!value) {
      return "<Leeg>";
    }
    return Array.isArray(value) ? (
      value.map((fileId: string) => (
        <DownloadButton fileId={fileId} key={fileId} />
      ))
    ) : (
      <DownloadButton fileId={value} />
    );
  }

  switch (schema.type) {
    case "string":
      if (schema.format === "date-time" && value) {
        return formatDate(new Date(value), "dd-MM-yyyy HH:mm:ss");
      }
      if (schema.enum) {
        return t(value);
      }
      return ucfirst(value);

    case "boolean":
      return value ? "Ja" : "Nee";

    case "number":
      return typeof value === "number"
        ? `€ ${number_format(value, 2, ",", ".")}`
        : value;

    case "integer":
      return value;

    case "object": {
      if (typeof value === "string") {
        return value;
      }
      return Object.keys(value).length ? (
        <dl className="pl-3">
          {Object.keys(value).map((claimPartProp) => {
            const propSchema: SchemaObject | null =
              schema && schema.properties
                ? schema.properties[claimPartProp]
                : null;
            const title = propSchema?.description || t(claimPartProp);
            return (
              <React.Fragment key={claimPartProp}>
                <dt title={title}>{title}</dt>
                <dd>
                  {formatValue(
                    (value as any)[claimPartProp],
                    properties[claimPartProp],
                    claimPartProp
                  )}
                </dd>
              </React.Fragment>
            );
          })}
        </dl>
      ) : (
        JSON.stringify(value)
      );
    }

    default:
      return JSON.stringify(value, null, "\t");
  }
};

export default ({ claim, patch }: IEventProps) => {
  const { users } = React.useContext(ApiContext);
  const { op, path, value } = patch;

  const getPropSchema = React.useCallback(
    (pathNibbles: string[]): SchemaObject | null => {
      const nibblesLength = pathNibbles.length;
      if (nibblesLength < 4) {
        return null;
      }
      // eslint-disable-next-line prefer-destructuring
      const claimPropName = pathNibbles[3];
      const claimPropSchema: SchemaObject = properties[claimPropName];
      if (nibblesLength === 4) {
        return claimPropSchema;
      }
      if (claimPropName === "parts") {
        const claimPart = claim.parts[parseInt(pathNibbles[4], 10)];
        if (!claimPart) {
          return null;
        }
        const claimPartSchema = getSchemaFromClaimPartType(claimPart.type);
        const claimPartSchemaProperties = claimPartSchema.properties || {};
        const claimPartPropertySchema = claimPartSchemaProperties[
          pathNibbles[5]
        ] as SchemaObject;
        if (!claimPartPropertySchema) {
          return null;
        }
        const isArrayItem = !!pathNibbles[pathNibbles.length - 1].match(
          /^\d+$/
        );
        return nibblesLength === 7 || (isArrayItem && nibblesLength === 8)
          ? claimPartPropertySchema.items ||
              (claimPartPropertySchema.properties || {})[pathNibbles[6]]
          : claimPartPropertySchema;
      }
      if (nibblesLength === 5 && claimPropSchema.items) {
        return claimPropSchema.items;
      }

      throw new Error("Not yet implemented");
    },
    [claim]
  );

  const patchPathNibbles = path.split("/");
  // eslint-disable-next-line prefer-destructuring
  const claimPropNibble = patchPathNibbles[3];
  // eslint-disable-next-line prefer-destructuring
  const calculationPropNibble = patchPathNibbles[6];
  const claimPropertySchema = properties[claimPropNibble] as SchemaObject;
  const propSchema = getPropSchema(patchPathNibbles);
  const renderPropName = React.useCallback(() => {
    let nestingLevel = 3;
    const namedPathNibbles = patchPathNibbles.slice(nestingLevel);
    return namedPathNibbles
      .map((pathNibble) => {
        const propSchema = getPropSchema(
          patchPathNibbles.slice(0, ++nestingLevel)
        );
        if (is_numeric(pathNibble)) {
          return ` ${parseInt(pathNibble, 10) + 1}:`;
        }
        return propSchema?.description || t(pathNibble);
      })
      .join(" ");
  }, [getPropSchema, patchPathNibbles]);

  const renderValue = React.useCallback(() => {
    if (!users) {
      return "...";
    }
    if (claimPropNibble && claimPropNibble.endsWith("UserId")) {
      return users.find((user) => user.id === value)?.name;
    }
    if (calculationPropNibble && calculationPropNibble.endsWith("FileIds")) {
      return "<Nieuw bestand>";
    }
    const isArrayItem = !!patchPathNibbles[patchPathNibbles.length - 1].match(
      /^\d+$/
    );
    return propSchema
      ? formatValue(
          value,
          propSchema,
          patchPathNibbles[patchPathNibbles.length - (isArrayItem ? 2 : 1)]
        )
      : JSON.stringify(propSchema);
  }, [
    users,
    claimPropNibble,
    calculationPropNibble,
    patchPathNibbles,
    propSchema,
    value,
  ]);

  if (!claimPropNibble) {
    return <dt title={"Claim toegvoegd"}>Claim toegvoegd</dt>;
  }
  if (claimPropNibble === "updatedAt") {
    return null;
  }
  if (!claimPropertySchema) {
    return <pre>{JSON.stringify(patch, null, "\t")}</pre>;
  }
  return (
    <>
      <dt title={renderPropName()}>{renderPropName()}</dt>
      <dd
        className={`pages__admin__claim__claim-log-pane__patch-log-message__dd${
          propSchema ? `--${propSchema.type}` : ""
        }`}
      >
        {op === "remove" ? <em>&lt; leeg &gt;</em> : renderValue()}
      </dd>
    </>
  );
};
