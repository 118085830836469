import { Button, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelpPopover from "../../../../components/popover/Help";
import React from "react";
import { format as formatDate } from "date-fns";
import { ApiContext } from "../../../../provider/ApiProvider";
import Checksum from "./Checksum";

export default () => {
  const { paymentChecksums, hydratePaymentChecksums } = React.useContext(
    ApiContext
  );
  const [isLoadDialogOpen, setIsLoadDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (paymentChecksums === undefined) {
      hydratePaymentChecksums();
    }
  }, [hydratePaymentChecksums, paymentChecksums]);

  const toggleLoadDialog = React.useCallback(() => {
    setIsLoadDialogOpen((open) => !open);
  }, []);

  return (
    <>
      <Button variant="secondary" title="Checksums" onClick={toggleLoadDialog}>
        <FontAwesomeIcon icon="fingerprint" /> Toon betaalbestanden geschiedenis
        en controles
      </Button>
      <Modal
        show={isLoadDialogOpen}
        onHide={toggleLoadDialog}
        size="xl"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Betaalbestanden controle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Bestand</th>
                <th>Datum</th>
                <th>
                  Controle code
                  <HelpPopover title="Controle code" placement="auto-end">
                    <p>
                      Per betaalbestand wordt er een hashing algoritme over het
                      bestand uitgevoerd.
                    </p>
                    <p>
                      De uitkomt hiervan kan gebruikt worden ter verificatie of
                      er in de tussentijd bij het verwerken van de betalingen
                      geen wijzigingen zijn uitgevoerd.
                    </p>
                  </HelpPopover>
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentChecksums?.map((paymentChecksum) => (
                <tr key={paymentChecksum.filename}>
                  <td>{paymentChecksum.filename}</td>
                  <td>
                    {formatDate(
                      new Date(paymentChecksum.createdAt as string),
                      "dd-MM-yyyy HH:mm:ss"
                    )}
                  </td>
                  <td>
                    <Checksum checksum={paymentChecksum.checksum} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={toggleLoadDialog}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
