import React from "react";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import { InputGroup } from "react-bootstrap";
import nl from "date-fns/locale/nl";
import { FieldError } from "react-hook-form";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

setDefaultLocale(nl as any);

interface IDatePickerFormControlProps {
  disabled?: boolean;
  error?: FieldError;
  value?: string;
  onChange: (newValue: string | undefined) => void;
}

export default ({
  disabled,
  error,
  onChange,
  value,
}: IDatePickerFormControlProps) => {
  const valueDate = value ? new Date(value) : null;
  return (
    <InputGroup style={{ display: "flex" }}>
      <DatePicker
        disabled={disabled}
        selected={
          valueDate && !isNaN(valueDate.getTime()) ? valueDate : undefined
        }
        onChange={(selectedDate: Date) => {
          onChange(
            selectedDate ? format(selectedDate, "yyyy-MM-dd") : undefined
          );
        }}
        maxDate={new Date()}
        dateFormat="dd-MM-yyyy"
        className={`form-control${error ? " is-invalid" : ""}`}
        wrapperClassName="flex-grow-1"
      />
      <div className="input-group-append">
        <span className="input-group-text" id="basic-addon2">
          <FontAwesomeIcon icon={["far", "calendar-alt"]} />
        </span>
      </div>
    </InputGroup>
  );
};
