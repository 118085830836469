import NumberFormat from "react-number-format";
import React from "react";
import { FieldError } from "react-hook-form";

interface INumberFormControlProps {
  disabled?: boolean;
  error?: FieldError;
  name?: string;
  value?: number;
  onChange: (newValue: number | undefined) => void;
}

export default ({
  disabled,
  error,
  name,
  onChange,
  value,
}: INumberFormControlProps) => (
  <NumberFormat
    className={`form-control${error ? " is-invalid" : ""}`}
    disabled={disabled}
    name={name}
    decimalSeparator=","
    thousandSeparator="."
    decimalScale={2}
    fixedDecimalScale={true}
    prefix="€ "
    onValueChange={(vals) => {
      onChange(vals.floatValue);
    }}
    value={value as number}
  />
);
