import { Form } from "react-bootstrap";
import React from "react";
import DatePickerFormControl from "../../form/DatePickerFormControl";
import IntegerFormControl from "../../form/IntegerFormControl";
import NumberFormControl from "../../form/NumberFormControl";
import BooleanFormControl from "../../form/BooleanFormControl";
import FilesUploadControl from "../../form/FilesUploadControl";
import ClearableStringFormControl from "../../form/ClearableStringFormControl";
import EnumStringFormControl from "../../form/EnumStringFormControl";
import EmailFormControl from "../../form/EmailFormControl";
import MaskedInput from "react-maskedinput";
import { Controller, FieldError } from "react-hook-form";
import { SchemaObject } from "openapi3-ts";

interface ISchemaFormGroupControlProps {
  control?: any | undefined;
  defaultValue?: any;
  disabled?: boolean;
  error?: FieldError;
  inputRef: any;
  isInvalid?: boolean;
  isRequired?: boolean;
  isUserInputRequired?: boolean;
  label?: any;
  name: string;
  schema: SchemaObject;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
}

export default ({
  control,
  defaultValue,
  disabled,
  error,
  inputRef,
  isRequired,
  isUserInputRequired,
  label,
  name,
  onBlur,
  onChange,
  schema,
}: ISchemaFormGroupControlProps) => {
  switch (schema.type) {
    case "boolean":
      if (!control) {
        throw new Error(`"control"-property is required to render ${name}`);
      }
      let actualLabel = label || schema.description;
      if (isUserInputRequired) {
        actualLabel += " *";
      }
      return (
        <Controller
          as={BooleanFormControl}
          name={name}
          disabled={disabled}
          control={control}
          error={error}
          label={actualLabel}
        />
      );

    case "integer":
      if (!control) {
        throw new Error(`"control"-property is required to render ${name}`);
      }
      return (
        <>
          <Controller
            disabled={disabled}
            as={IntegerFormControl}
            name={name}
            control={control}
            error={error}
          />
        </>
      );

    case "number":
      if (!control) {
        throw new Error(`"control"-property is required to render ${name}`);
      }
      return (
        <>
          <Controller
            control={control}
            name={name}
            as={NumberFormControl}
            error={error}
          />
        </>
      );
  }
  if (schema.enum) {
    if (!control) {
      throw new Error(`"control"-property is required to render ${name}`);
    }
    return (
      <Controller
        as={EnumStringFormControl}
        disabled={disabled}
        name={name}
        onChange={onChange}
        control={control}
        schema={schema}
        error={error}
      />
    );
  }

  if (name.endsWith("FileIds")) {
    return (
      <Controller
        as={FilesUploadControl}
        name={name}
        control={control}
        error={error}
        disabled={disabled}
      />
    );
  }
  if (name === "zipcode" || name.endsWith("hospitalZipcode")) {
    if (!control) {
      throw new Error(`"control"-property is required to render ${name}`);
    }
    return (
      <Controller
        as={MaskedInput}
        className="form-control"
        mask={`1111 AA` as any}
        name={name}
        control={control}
        disabled={disabled}
        onBlur={(events) => {
          const [e] = events;
          e.target.value = e.target.value.trim();
          if (onBlur) {
            onBlur(e);
          }
        }}
      />
    );
  }

  let type: string;
  switch (schema.format) {
    case "date":
      if (!control) {
        throw new Error(`"control"-property is required to render ${name}`);
      }
      return (
        <>
          <Controller
            as={DatePickerFormControl}
            disabled={disabled}
            name={name}
            control={control}
            error={error}
            defaultValue={defaultValue}
          />
        </>
      );

    case "email":
      if (control) {
        return (
          <>
            <Controller
              as={EmailFormControl}
              disabled={disabled}
              name={name}
              control={control}
              error={error}
              defaultValue={defaultValue}
            />
          </>
        );
      }
      type = "email";
      break;

    case "password":
      type = "password";
      break;

    default:
      type = "text";
  }

  if (!isRequired) {
    if (!control) {
      throw new Error(`"control"-property is required to render ${name}`);
    }
    return (
      <Controller
        as={ClearableStringFormControl}
        name={name}
        control={control}
        error={error}
        defaultValue={defaultValue}
        schema={schema}
        type={type}
        disabled={disabled}
      />
    );
  }

  return (
    <Form.Control
      disabled={disabled}
      type={type}
      name={name}
      defaultValue={defaultValue}
      ref={inputRef}
      isInvalid={!!error}
      required={isUserInputRequired}
      pattern={schema.pattern}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.trim();
        if (onBlur) {
          onBlur(e);
        }
      }}
      onChange={onChange}
    />
  );
};
