import React, { useEffect } from "react";
import {
  openapi,
  claimSummary,
  stxt as t,
  Components,
} from "shared.wenckebachfonds.nl";
import { Controller, FieldErrors, useForm } from "react-hook-form";
import { Alert, Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import BooleanRadioFormGroup from "../../../components/form/BooleanRadioFormGroup";
import SchemaFormGroup from "../../../components/SchemaFormGroup";
import { AxiosError } from "axios";
import { useToasts } from "react-toast-notifications";
import DeleteDialog from "./deleteDialog";
import { ApiContext } from "../../../provider/ApiProvider";
import { Link, useParams, useHistory } from "react-router-dom";
import { SchemaObject } from "openapi3-ts";
import {
  getClaimValidationResolver,
  scrollToError,
} from "../../../inc/react-hook-form";
import { add, format } from "date-fns";
import { getOnBlurHandler } from "../../../inc/zipcode";
import HelpPopover from "../../../components/popover/Help";

let localStoreKey: string | null = "wenckebachForm";
const localStorageItem = window.localStorage.getItem(localStoreKey);

interface ILocalStorageRecord {
  updatedAt: Date;
  claim?: Partial<Components.Schemas.Claim>;
}

let localStorageRecord: ILocalStorageRecord | undefined = localStorageItem
  ? JSON.parse(localStorageItem)
  : undefined;
if (
  !localStorageRecord ||
  new Date() > add(new Date(localStorageRecord.updatedAt), { days: 2 }) ||
  !localStorageRecord.claim ||
  localStorageRecord.claim.isEmployee === undefined
) {
  localStorageRecord = undefined;
}

const getEmptyClaim = () => ({
  parts: [{}],
});

export default () => {
  const validationResolver = React.useMemo(
    () => getClaimValidationResolver({}),
    []
  );
  const {
    errors,
    handleSubmit,
    reset,
    watch,
    control,
    register,
    setError,
    getValues,
    setValue,
  } = useForm<Components.Schemas.Claim>({
    defaultValues: getEmptyClaim(),
    validationResolver,
  });
  const history = useHistory();
  const { public_edit_token } = useParams<{ public_edit_token: string }>();
  const formValues = watch({ nest: true });
  useEffect(() => {
    if (
      !localStoreKey ||
      formValues.isEmployee === undefined ||
      public_edit_token
    ) {
      return;
    }
    window.localStorage.setItem(
      localStoreKey,
      JSON.stringify({ updatedAt: new Date(), claim: formValues })
    );
  }, [formValues, public_edit_token]);

  const {
    updateClaim,
    hydrateMyClaim,
    myClaim,
    setMyClaim,
    updateMyClaim,
    requestNewLink,
  } = React.useContext(ApiContext);
  const [finishedClaim, setFinishedClaim] = React.useState<
    Components.Schemas.Claim
  >();
  const [submitPending, setSubmitPending] = React.useState(false);
  const [submitError, setSubmitError] = React.useState("");
  const [tokenBasedError, setTokenBasedError] = React.useState<
    React.ReactElement
  >();
  const { addToast } = useToasts();
  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setTimeout(scrollToError, 50);
    }
  }, [errors]);
  const onZipcodeBlur = React.useMemo(
    () => getOnBlurHandler(setValue, getValues),
    [getValues, setValue]
  );

  const [, claimSchema] = openapi.components.schemas.Claim.allOf;
  const { properties, required = [] } = claimSchema as SchemaObject;
  const { isEmployee, name, bsn } = formValues;

  if (!properties) {
    return null;
  }

  // @ts-ignore
  const globalError: FieldErrors | undefined = errors[""];

  React.useEffect(() => {
    if (!public_edit_token) {
      setTokenBasedError(undefined);
      return;
    }
    hydrateMyClaim(public_edit_token).catch(() => {
      // show a warning
      setTokenBasedError(
        <Alert variant="warning">
          <p>Deze link is niet (meer) geldig. Uw link is mogelijk verlopen.</p>
          <p>
            <Button
              variant="primary"
              onClick={() => {
                requestNewLink(public_edit_token).catch(
                  (err: AxiosError<Components.Responses.ApiError>) => {
                    const { response } = err;
                    let message = err.message
                      ? t(err.message)
                      : "Aanvraag mislukt, start een nieuwe aanvraag of neem contact met ons op";
                    if (response && response.data) {
                      // eslint-disable-next-line prefer-destructuring
                      message = t(response.data.message);
                    }
                    addToast(message, {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  }
                );
              }}
            >
              Klik hier om een nieuwe link per e-mail te ontvangen.
            </Button>
          </p>
          <p>
            <Link className="btn btn-success" to="/">
              Start een nieuwe aanvraag
            </Link>
          </p>
        </Alert>
      );
    });
  }, [requestNewLink, public_edit_token, reset, hydrateMyClaim, addToast]);

  React.useEffect(() => {
    if (myClaim) {
      reset(myClaim);
      return;
    }
    if (myClaim === undefined) {
      reset(getEmptyClaim());
    }
  }, [myClaim, reset]);

  if (tokenBasedError) {
    return tokenBasedError;
  }

  if (
    !public_edit_token &&
    localStorageRecord &&
    localStorageRecord.updatedAt
  ) {
    return (
      <Alert variant="primary">
        <p>
          Er is een eerder niet afgemaakte aanvraag gevonden (van{" "}
          <strong>
            {format(
              new Date(localStorageRecord.updatedAt),
              "dd-MM-yyyy HH:mm:ss"
            )}
          </strong>
          )
        </p>
        <Button
          className="float-md-right mb-2 mr-2"
          variant="primary"
          onClick={() => {
            if (localStorageRecord && localStorageRecord.claim) {
              setMyClaim({
                ...localStorageRecord.claim,
                parts:
                  localStorageRecord.claim.parts &&
                  localStorageRecord.claim.parts.length
                    ? localStorageRecord.claim.parts
                    : [{}],
              } as any);
              localStorageRecord = undefined;
            }
          }}
        >
          Doorgaan
        </Button>
        <Button
          className="mb-2"
          variant="danger"
          onClick={() => {
            if (localStoreKey) {
              window.localStorage.removeItem(localStoreKey);
            }
            localStorageRecord = undefined;
            reset();
          }}
        >
          Nieuwe aanvraag
        </Button>
      </Alert>
    );
  }

  if (public_edit_token && formValues.status && formValues.status !== "open") {
    const statusStxtMap: {
      [status: string]: string;
    } = {
      open: "is open",
      intake: "is al in behandeling",
      evaluating: "wordt op dit moment inhoudelijk bekeken",
      finalReviewing: "wordt op dit moment inhoudelijk bekeken",
      rejectedUnconfirmed: "wordt nog in verdere behandeling genomen",
      rejectedConfirmed: "is helaas afgewezen",
      approved: "is goedgekeurd",
    };

    return (
      <React.Fragment>
        <Alert variant="warning">
          <h2>
            Deze aanvraag ({formValues.id}) {statusStxtMap[formValues.status]}.
          </h2>
          <p>
            De huidige status van uw aanvraag voorkomt dat deze nog bewerkt kan
            worden. Mocht u dringend aanpassingen hebben, neem dan contact op.
            Dit kan telefonisch op <a href="tel:+31251491387">0251 49 13 87</a>
          </p>
          <p>
            <a className="btn btn-success" href="/">
              Start een nieuwe aanvraag
            </a>
          </p>
          <p>
            <Button
              className="btn btn-secondary"
              onClick={() => {
                if (localStoreKey) {
                  window.localStorage.removeItem(localStoreKey);
                }
                history.push("/");
                setMyClaim({
                  ...formValues,
                  id: undefined,
                  status: undefined,
                  parts: formValues.parts.map((part) =>
                    Object.keys(part)
                      .filter((partProp) => !partProp.endsWith("FileIds"))
                      .reduce((prev, propName) => {
                        // @ts-ignore
                        prev[propName] = part[propName];
                        return prev;
                      }, {})
                  ) as any,
                });
                addToast(`Uw nieuwe aanvraag is aangemaakt`, {
                  appearance: "success",
                  autoDismiss: true,
                });
              }}
            >
              Maak een nieuwe op basis van deze (oude) aanvraag
            </Button>
          </p>
        </Alert>
        {myClaim && (
          <div dangerouslySetInnerHTML={{ __html: claimSummary(myClaim) }} />
        )}
      </React.Fragment>
    );
  }

  if (finishedClaim) {
    return (
      <Alert variant="success">
        <h2>Uw aanvraag ({finishedClaim.id}) is bij ons ontvangen.</h2>
        <p>
          U ontvangt een bevestiging per e-mail. Wij gaan er mee aan de slag!
        </p>
      </Alert>
    );
  }

  return (
    <React.Fragment>
      {submitPending && <Spinner animation="border" />}
      <Form
        className={submitPending ? "d-none" : undefined}
        onSubmit={handleSubmit((values) => {
          setSubmitError("");
          setSubmitPending(true);

          return (public_edit_token
            ? updateMyClaim(values, public_edit_token)
            : updateClaim(values)
          )
            .then((updatedClaim) => {
              addToast(`De aanvraag (${updatedClaim.id}) is opgeslagen`, {
                appearance: "success",
                autoDismiss: true,
              });
              setSubmitPending(false);
              setFinishedClaim(updatedClaim);
              if (localStoreKey) {
                window.localStorage.removeItem(localStoreKey);
                // https://trello.com/c/zvm2dhyW/109-afgemaakte-ingediende-aanvraag-blijft-in-mijn-browser-verschijnen-als-niet-afgemaakte-aanvraag
                localStoreKey = null;
              }
            })
            .catch((err: AxiosError<Components.Responses.ApiError>) => {
              const { response } = err;
              let message = err.message
                ? t(err.message)
                : "Opslaan aanvraag mislukt";
              if (response && response.data) {
                if (response.data.validationErrors) {
                  response.data.validationErrors.forEach((validationError) => {
                    setError(
                      validationError.dataPath.substr(1),
                      validationError.keyword,
                      t(validationError.message || "")
                    );
                  });
                }
                // eslint-disable-next-line prefer-destructuring
                message = t(response.data.message);
              }

              addToast(message, {
                appearance: "error",
                autoDismiss: true,
              });
              setSubmitPending(false);
              setSubmitError(message);

              setTimeout(scrollToError, 50);
              throw err;
            });
        })}
      >
        {submitError && <Alert variant="danger">{submitError}</Alert>}
        {globalError && <Alert variant="danger">{globalError.message}</Alert>}
        <Card style={{ width: "100%" }}>
          <Card.Body>
            <h5>Deelnemer</h5>
            <hr />
            <Row>
              <Col sm={12} className="mb-2">
                <Controller
                  as={BooleanRadioFormGroup}
                  control={control}
                  label="Bent u zelf (gepensioneerd) medewerker van Hoogovens, Corus of Tata Steel? *"
                  name="isEmployee"
                  error={errors.isEmployee}
                />
              </Col>
            </Row>
            {isEmployee !== undefined && (
              <Row>
                <Col md={6}>
                  <SchemaFormGroup
                    register={register}
                    isRequired={required.indexOf("name") >= 0}
                    name="name"
                    schema={{
                      ...properties.name,
                      minLength: 1,
                    }}
                    error={errors.name}
                  />
                </Col>
                <Col md={6}>
                  <SchemaFormGroup
                    control={control}
                    register={register}
                    isRequired={true}
                    name="birthDate"
                    schema={properties.birthDate as SchemaObject}
                    error={errors.birthDate}
                  />
                </Col>
              </Row>
            )}
            {isEmployee !== undefined && !isEmployee && (
              <Row>
                <Col md={6}>
                  <SchemaFormGroup
                    register={register}
                    isRequired={true}
                    name="partnerName"
                    schema={properties.partnerName as SchemaObject}
                    error={errors.partnerName}
                  />
                </Col>
                <Col md={6}>
                  <SchemaFormGroup
                    control={control}
                    register={register}
                    isRequired={true}
                    name="partnerBirthDate"
                    schema={properties.partnerBirthDate as SchemaObject}
                    error={errors.partnerBirthDate}
                  />
                </Col>
              </Row>
            )}
            {isEmployee !== undefined && (
              <Row>
                <Col md={6}>
                  <SchemaFormGroup
                    label={
                      <span>
                        {isEmployee
                          ? "Uw personeelsnummer"
                          : "Personeelsnummer partner"}
                        <HelpPopover title="Personeelsnummer">
                          <p>
                            Een personeelsnummer bestaat uit 6 cijfers en maakt
                            het mogelijk uw aanvraag sneller te behandelen.
                          </p>
                          <p>Graag invullen indien bekend</p>
                        </HelpPopover>
                      </span>
                    }
                    register={register}
                    isRequired={false}
                    name="personnelNumber"
                    schema={properties.personnelNumber as SchemaObject}
                    control={control}
                    error={errors.personnelNumber}
                  />
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
        {name && (
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <h5>Uw persoonlijke gegevens</h5>
              <hr />
              <Row>
                <Col sm={4}>
                  <SchemaFormGroup
                    control={control}
                    register={register}
                    isRequired={required.indexOf("zipcode") >= 0}
                    name="zipcode"
                    schema={properties.zipcode as SchemaObject}
                    error={errors.zipcode}
                    onBlur={onZipcodeBlur}
                  />
                </Col>
                <Col sm={4}>
                  <SchemaFormGroup
                    register={register}
                    isRequired={true}
                    name="streetNumber"
                    schema={properties.streetNumber as SchemaObject}
                    error={errors.streetNumber}
                    control={control}
                  />
                </Col>
                <Col sm={4}>
                  <SchemaFormGroup
                    register={register}
                    isRequired={true}
                    name="streetNumberAddition"
                    schema={properties.streetNumberAddition as SchemaObject}
                    error={errors.streetNumberAddition}
                    control={control}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <SchemaFormGroup
                    register={register}
                    isRequired={required.indexOf("street") >= 0}
                    name="street"
                    schema={properties.street as SchemaObject}
                    error={errors.street}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <SchemaFormGroup
                    register={register}
                    isRequired={required.indexOf("city") >= 0}
                    name="city"
                    schema={properties.city as SchemaObject}
                    error={errors.city}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <SchemaFormGroup
                    register={register}
                    isRequired={required.indexOf("bankAccount") >= 0}
                    name="bankAccount"
                    schema={properties.bankAccount as SchemaObject}
                    error={errors.bankAccount}
                  />
                </Col>
                <Col sm={6}>
                  <SchemaFormGroup
                    register={register}
                    isRequired={required.indexOf("bankAccountName") >= 0}
                    name="bankAccountName"
                    schema={properties.bankAccountName as SchemaObject}
                    error={errors.bankAccountName}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <SchemaFormGroup
                    control={control}
                    register={register}
                    isRequired={required.indexOf("phone") >= 0}
                    name="phone"
                    schema={properties.phone as SchemaObject}
                    error={errors.phone}
                    label={
                      <span>
                        Telefoonnummer
                        <HelpPopover title="Telefoonnummer">
                          <p>
                            Een telefoonnummer dient te bestaan uit ten minste
                            10 karakters.
                          </p>
                          <p>
                            De karakters "+", "-", spaties en cijfers zijn
                            toegestaan
                          </p>
                        </HelpPopover>
                      </span>
                    }
                  />
                </Col>
                <Col sm={6}>
                  <SchemaFormGroup
                    register={register}
                    isRequired={required.indexOf("email") >= 0}
                    name="email"
                    schema={properties.email as SchemaObject}
                    error={errors.email}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <SchemaFormGroup
                    register={register}
                    isRequired={required.indexOf("bsn") >= 0}
                    name="bsn"
                    schema={properties.bsn as SchemaObject}
                    error={errors.bsn}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
        {bsn && (
          <React.Fragment>
            <SchemaFormGroup
              error={errors.parts}
              name="parts"
              schema={properties.parts}
              control={control}
              register={register}
              isRequired={true}
            />
            <SchemaFormGroup
              error={errors.agreeToTerms}
              name="agreeToTerms"
              schema={properties.agreeToTerms}
              control={control}
              register={register}
              isRequired={true}
            />
            <Button type="submit">
              {public_edit_token ? "Aanvraag bijwerken" : "Aanvraag indienen"}
            </Button>
            {public_edit_token && <DeleteDialog token={public_edit_token} />}
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  );
};
