import { Form } from "react-bootstrap";
import React from "react";
import DatePickerFormControl from "../../form/DatePickerFormControl";
import IntegerFormControl from "../../form/IntegerFormControl";
import NumberFormControl from "../../form/NumberFormControl";
import BooleanFormControl from "../../form/BooleanFormControl";
import FilesUploadControl from "../../form/FilesUploadControl";
import ClearableStringFormControl from "../../form/ClearableStringFormControl";
import EnumStringFormControl from "../../form/EnumStringFormControl";
import { FieldError } from "react-hook-form";
import { SchemaObject } from "openapi3-ts";

interface IControlledControlProps {
  disabled?: boolean;
  error?: FieldError;
  isInvalid?: boolean;
  isRequired?: boolean;
  isUserInputRequired?: boolean;
  label?: any;
  name?: string;
  schema: SchemaObject;
  onBlur?: (e: any) => void;
  onChange: (e: any) => void;
  value?: any;
}

export default ({
  disabled,
  error,
  isRequired,
  isUserInputRequired,
  label,
  name,
  onBlur,
  onChange,
  schema,
  value,
}: IControlledControlProps) => {
  switch (schema.type) {
    case "boolean":
      let actualLabel = label || schema.description;
      if (isUserInputRequired) {
        actualLabel += " *";
      }
      return (
        <BooleanFormControl
          disabled={disabled}
          name={name}
          error={error}
          label={actualLabel}
          checked={value}
          onChange={onChange}
        />
      );

    case "integer":
      return (
        <IntegerFormControl
          disabled={disabled}
          name={name}
          error={error}
          value={value}
          onChange={onChange}
        />
      );

    case "number":
      return (
        <NumberFormControl
          disabled={disabled}
          name={name}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
  }
  if (schema.enum) {
    return (
      <EnumStringFormControl
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        schema={schema}
        error={error}
      />
    );
  }

  if (name && name.endsWith("FileIds")) {
    return (
      <FilesUploadControl
        disabled={disabled}
        name={name}
        error={error}
        value={value}
        onChange={onChange}
      />
    );
  }

  let type: string;
  switch (schema.format) {
    case "date":
      return (
        <DatePickerFormControl
          disabled={disabled}
          error={error}
          value={value}
          onChange={onChange}
        />
      );

    case "email":
      type = "email";
      break;

    case "password":
      type = "password";
      break;

    default:
      type = "text";
  }

  if (!isRequired || (name && name.endsWith("situationDescriptionComment"))) {
    return (
      <ClearableStringFormControl
        disabled={disabled}
        name={name}
        error={error}
        schema={schema}
        type={type}
        value={value}
        onChange={onChange}
      />
    );
  }

  return (
    <Form.Control
      disabled={disabled}
      type={type}
      name={name}
      value={value}
      isInvalid={!!error}
      required={isUserInputRequired}
      pattern={schema.pattern}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.trim();
        if (onBlur) {
          onBlur(e);
        }
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
      }}
    />
  );
};
