import { Card } from "react-bootstrap";
import SchemaFormGroup from "../../../../components/SchemaFormGroup";
import React from "react";
import { Components, openapi } from "shared.wenckebachfonds.nl";
import { SchemaObject } from "openapi3-ts";
import { ApiContext } from "../../../../provider/ApiProvider";
import { Control } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types";

const [, parentSchema] = openapi.components.schemas.Claim.allOf;
const { properties } = parentSchema as SchemaObject;

interface IAdminCardProperties {
  adminUserId?: string;
  disabled: boolean;
  errors: FieldErrors<Components.Schemas.Claim>;
  evaluatorUserId?: string;
  finalReviewerUserId?: string;
  control: Control;
  register: any;
}

export default (props: IAdminCardProperties) => {
  const {
    adminUserId,
    control,
    disabled,
    errors,
    evaluatorUserId,
    finalReviewerUserId,
    register,
  } = props;
  const { users } = React.useContext(ApiContext);
  if (!properties || !users) {
    return null;
  }
  return (
    <Card>
      <Card.Body>
        <Card.Title>Beoordeling</Card.Title>
        <SchemaFormGroup
          schema={{
            ...properties.adminUserId,
            enum: users
              ? [
                  ...users
                    .filter(
                      (user) =>
                        user.role === "administrator" &&
                        [evaluatorUserId, finalReviewerUserId].indexOf(
                          user.id
                        ) === -1
                    )
                    .map((user) => user.id),
                ]
              : [],
          }}
          name="adminUserId"
          control={control}
          register={register}
          isRequired={true}
          error={errors.evaluatorUserId}
          disabled={disabled}
        />
        <SchemaFormGroup
          schema={{
            ...properties.evaluatorUserId,
            enum: users
              ? [
                  ...users
                    .filter(
                      (user) =>
                        ["reviewer", "administrator"].indexOf(user.role) >= 0 &&
                        [adminUserId, finalReviewerUserId].indexOf(user.id) ===
                          -1
                    )
                    .map((user) => user.id),
                ]
              : [],
          }}
          name="evaluatorUserId"
          control={control}
          register={register}
          isRequired={true}
          error={errors.evaluatorUserId}
          disabled={disabled}
        />
        <SchemaFormGroup
          schema={{
            ...properties.finalReviewerUserId,
            enum: users
              ? [
                  ...users
                    .filter(
                      (user) =>
                        ["reviewer", "administrator"].indexOf(user.role) >= 0 &&
                        [adminUserId, evaluatorUserId].indexOf(user.id) === -1
                    )
                    .map((user) => user.id),
                ]
              : [],
          }}
          name="finalReviewerUserId"
          control={control}
          register={register}
          isRequired={true}
          error={errors.finalReviewerUserId}
          disabled={disabled}
        />
        <SchemaFormGroup
          key="internalComment"
          schema={{
            ...properties["internalComment"],
          }}
          name="internalComment"
          control={control}
          register={register}
          error={errors["internalComment"]}
          disabled={disabled}
        />
        <SchemaFormGroup
          className="border border-secondary rounded p-2"
          key="originalPaperEmailClaimFileIds"
          schema={{
            ...properties["originalPaperEmailClaimFileIds"],
          }}
          name="originalPaperEmailClaimFileIds"
          control={control}
          register={register}
          error={errors["internalComment"]}
          disabled={disabled}
        />
      </Card.Body>
    </Card>
  );
};
