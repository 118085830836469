import { Dropdown } from "react-bootstrap";
import React, { Dispatch, SetStateAction } from "react";
import { ISchemaTableConfig } from "../../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDefaultSchemaConfig,
  getSchemaTableConfigLocalStorageIdentifier,
} from "../../config";

export interface IResetButtonProps {
  close: () => void;
  schemaTitle: "Claim" | "ClaimPart" | "UngroupedClaim" | "ToBePaidClaims";
  setConfig: Dispatch<SetStateAction<Partial<ISchemaTableConfig>>>;
}

export const ResetButton = ({
  close,
  schemaTitle,
  setConfig,
}: IResetButtonProps) => (
  <Dropdown.Item
    size="sm"
    onClick={() => {
      localStorage.removeItem(
        getSchemaTableConfigLocalStorageIdentifier(schemaTitle)
      );
      setConfig(getDefaultSchemaConfig(schemaTitle));
      close();
    }}
  >
    <span className="fa-layers fa-fw mr-1 text-danger" title="Reset overzicht">
      <FontAwesomeIcon icon="bookmark" fixedWidth className="mr-1" />
      <FontAwesomeIcon
        icon="broom"
        className="text-body"
        transform="shrink-4 down-1 right-5"
      />
    </span>
    Reset overzicht
  </Dropdown.Item>
);
