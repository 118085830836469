import React from "react";
import { ApiContext } from "../../../provider/ApiProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import { Alert, Button, Card, Form, Spinner } from "react-bootstrap";
import { Redirect, useHistory } from "react-router-dom";
import SchemaFormGroup from "../../../components/SchemaFormGroup";
import { openapi, ajv, stxt as t, Components } from "shared.wenckebachfonds.nl";
import { FieldError, useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { useToasts } from "react-toast-notifications";
import { SchemaObject } from "openapi3-ts";
import localizeAjv from "ajv-i18n/localize/nl";

interface IUserProps {
  match: {
    params: {
      id: string | undefined;
    };
  };
}

const ajvValidator = ajv.compile({
  $ref: "openapi#/components/schemas/User",
});

const validationResolver = (values: any) => {
  const isValid = ajvValidator(values);
  const errors: { [path: string]: FieldError } = {};
  if (!isValid && ajvValidator.errors) {
    localizeAjv(ajvValidator.errors);
    ajvValidator.errors.forEach((ajvError) => {
      errors[ajvError.dataPath.substr(1)] = {
        type: "AjvError",
        message: t(ajvError.message || "Controleer uw invoer"),
      };
    });
  }
  return { values, errors };
};

export default (props: IUserProps) => {
  const { jwtUser } = React.useContext(AuthContext);
  const { hydrateUsers, users, updateUser } = React.useContext(ApiContext);
  const { addToast } = useToasts();
  const [submitPending, setSubmitPending] = React.useState(false);
  const [submitError, setSubmitError] = React.useState("");
  const history = useHistory();
  React.useEffect(() => {
    if (users === undefined) {
      hydrateUsers();
    }
  }, [hydrateUsers, users]);
  const { register, handleSubmit, errors, control, reset } = useForm<
    Components.Schemas.User
  >({
    validationResolver,
  });
  const { id } = props.match.params;
  const user:
    | Partial<Components.Schemas.User>
    | undefined = React.useMemo(() => {
    if (!users) {
      return undefined;
    }
    if (!id) {
      return {};
    }
    return users.find((user) => user.id === id);
  }, [users, id]);
  React.useEffect(() => {
    if (user) {
      console.log("Initializing new user");
      reset(user);
    }
  }, [user, reset]);

  if (!users || submitPending) {
    return <Spinner animation={"border"} />;
  }

  if (id && !user) {
    return <Redirect to="/admin/users" />;
  }

  const [, parentSchema] = openapi.components.schemas.User.allOf;
  const { properties, required = [] } = parentSchema as SchemaObject;

  if (!properties || !jwtUser) {
    return null;
  }

  if (jwtUser.role !== "administrator") {
    return (
      <div className="flex-grow-1">
        <Card>
          <Card.Body>
            <Alert variant="warning">
              <Alert.Heading>Onvoldoende rechten</Alert.Heading>
              <p>
                U dient een administrator te zijn voor het beheren van
                gebruikers
              </p>
            </Alert>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <Card style={{ width: "100%" }}>
      <Card.Body>
        <Form
          onSubmit={handleSubmit((values) => {
            setSubmitError("");
            setSubmitPending(true);
            return updateUser({
              ...values,
              id,
            })
              .then(() => {
                addToast("De gebruiker is opgeslagen", {
                  appearance: "success",
                  autoDismiss: true,
                });
                history.push("/admin/users");
              })
              .catch((err: AxiosError) => {
                const message =
                  t(err.response?.data.message) ||
                  t(err.message) ||
                  "Opslaan gebruiker mislukt";
                addToast(message, {
                  appearance: "error",
                  autoDismiss: true,
                });
                setSubmitPending(false);
                setSubmitError(message);
              });
          })}
        >
          {submitError && <Alert variant="danger">{submitError}</Alert>}
          {Object.keys(properties)
            .filter(
              (propertyName) =>
                ["password", "settings"].indexOf(propertyName) === -1
            )
            .map((propertyName) => (
              <SchemaFormGroup
                key={propertyName}
                schema={properties[propertyName] as SchemaObject}
                name={propertyName}
                control={control}
                register={register}
                isRequired={required.indexOf(propertyName) >= 0}
                error={
                  // @ts-ignore
                  errors[propertyName]
                }
              />
            ))}
          <Button variant="primary" type="submit">
            Gebruiker opslaan
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};
