import React from "react";
import { Form, Row } from "react-bootstrap";
import { FieldError } from "react-hook-form";

interface IBooleanRadioFormGroup {
  error?: FieldError;
  checked?: boolean;
  label: string;
  name: string;
  onChange: (newValue: boolean) => void;
}

export default (props: IBooleanRadioFormGroup) => {
  const { checked, error, label, name, onChange } = props;

  return (
    <Row className="px-3">
      <div>{label}</div>
      <div className="ml-5">
        <div className="custom-radio">
          <input
            className={`custom-control-input${error ? " is-invalid" : ""}`}
            checked={checked === true}
            type="radio"
            name={name}
            id={`${name}_true`}
            onChange={(e) => {
              onChange(true);
            }}
          />
          <label htmlFor={`${name}_true`} className="custom-control-label">
            Ja
          </label>
        </div>
        <div className="custom-radio">
          <input
            type="radio"
            checked={checked === false}
            className={`custom-control-input${error ? " is-invalid" : ""}`}
            id={`${name}_false`}
            name={name}
            onChange={(e) => {
              onChange(false);
            }}
          />
          <label htmlFor={`${name}_false`} className="custom-control-label">
            Nee
          </label>
        </div>
      </div>
      {error ? (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      ) : null}
    </Row>
  );
};
