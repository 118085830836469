import { SchemaObject } from "openapi3-ts";
import { openapi } from "shared.wenckebachfonds.nl";
import mergeAllOf from "json-schema-merge-allof";

export function getEmptyObject<T>(schema: SchemaObject): T {
  // const merging is required for e.g. ClaimSchema
  const { properties = {}, required = [] } = mergeAllOf(
    schema as any
  ) as SchemaObject;
  return Object.keys(properties).reduce((prev, propName) => {
    if (required.indexOf(propName) === -1) {
      return prev;
    }
    const prop = properties[propName] as SchemaObject;
    let propValue: any;
    switch (prop.type) {
      case "string":
        propValue = "";
        break;

      case "array":
        propValue = [];
        if (propName === "parts") {
          propValue.push(getEmptyObject(openapi.components.schemas.ClaimPart));
        }
        break;

      case "number":
      case "integer":
        propValue = 0;
        break;

      case "boolean":
        propValue = false;
        break;

      default:
        console.log(prop);
        throw new Error("Unsupported property");
    }

    // @ts-ignore
    prev[propName] = propValue;
    return prev;
  }, {}) as T;
}
