import React from "react";
import "./index.scss";

interface IChecksum {
  checksum:
    | string
    | /* ^[0-9A-F]{40}$ */ {
        sha1: string; // ^[0-9A-F]{40}$
        sha256: string; // ^[0-9A-F]{64}$
      };
}

export default ({ checksum }: IChecksum) => {
  const cleanChecksum =
    typeof checksum === "string" ? { sha1: checksum } : checksum;
  return (
    <>
      {Object.keys(cleanChecksum).map((type) => (
        <React.Fragment key={type}>
          <dt>{type}</dt>
          <dd>
            {
              //@ts-ignore
              cleanChecksum[type]
            }
          </dd>
        </React.Fragment>
      ))}
    </>
  );
};
