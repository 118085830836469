import React from "react";
import { Alert, Button, Card, Spinner, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ApiContext } from "../../../provider/ApiProvider";
import { AxiosError } from "axios";
import { stxt as t } from "shared.wenckebachfonds.nl";
import { useToasts } from "react-toast-notifications";
import { AuthContext } from "../../../provider/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default () => {
  const { jwtUser } = React.useContext(AuthContext);
  const history = useHistory();
  const { addToast } = useToasts();
  const { users, deleteUser, hydrateUsers } = React.useContext(ApiContext);
  React.useEffect(() => {
    if (users === undefined) {
      hydrateUsers();
    }
  }, [hydrateUsers, users]);

  if (!users || !jwtUser) {
    return <Spinner animation={"border"} />;
  }

  if (jwtUser.role !== "administrator") {
    return (
      <div className="flex-grow-1">
        <Card>
          <Card.Body>
            <Alert variant="warning">
              <Alert.Heading>Onvoldoende rechten</Alert.Heading>
              <p>
                U dient een administrator te zijn om betalingsbestanden te
                kunnen opstellen.
              </p>
            </Alert>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Card>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Naam</th>
                <th>E-mail adres</th>
                <th>Rol</th>
                <th style={{ width: 100 }} />
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td
                    onClick={() => {
                      history.push(`/admin/user/${user.id}`);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {user.name}
                  </td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td
                    onClick={() => {
                      history.push(`/admin/user/${user.id}`);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {t(user.role)}
                  </td>
                  <td className="text-center">
                    <Link to={`/admin/user/${user.id}`}>
                      <Button variant="primary" size="sm">
                        <FontAwesomeIcon icon="user-edit" />
                      </Button>
                    </Link>
                    <Button
                      variant="danger"
                      className="ml-1"
                      size="sm"
                      onClick={() => {
                        if (
                          !user.id ||
                          !window.confirm(
                            "Weet u zeker dat u deze gebruiker wilt verwijderen?"
                          )
                        ) {
                          return;
                        }
                        deleteUser(user.id)
                          .then(() => {
                            addToast("De gebruiker is verwijderd", {
                              appearance: "success",
                              autoDismiss: true,
                            });
                          })
                          .catch((err: AxiosError) => {
                            const message =
                              t(err.response?.data.message) ||
                              t(err.message) ||
                              "Verwijderen gebruiker mislukt";
                            addToast(message, {
                              appearance: "error",
                              autoDismiss: true,
                            });
                          });
                      }}
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Link to="/admin/user">
            <Button variant="secondary">
              <FontAwesomeIcon icon="user-plus" className="mr-2" />
              Gebruiker toevoegen
            </Button>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
};
