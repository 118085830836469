import { AxiosError } from "axios";
import { stxt as t } from "shared.wenckebachfonds.nl/dist/stxt";
import { Button, Form, Modal } from "react-bootstrap";
import React from "react";
import { ApiContext } from "../../../../../provider/ApiProvider";
import { useToasts } from "react-toast-notifications";
// useForm from react-hooks-form isn't usable due components/SchemaTable using it's own hook.
// Nested form hooks cause trouble in production build?!
import { FieldError } from "react-hook-form";
import { ISchemaTableConfig } from "../../../index";
import { Components } from "shared.wenckebachfonds.nl";

interface IUserSettingsFormProps {
  schemaTitle: string;
  schemaTableConfig: ISchemaTableConfig;
  toggleSaveDialog: () => void;
}

export default ({
  schemaTableConfig,
  schemaTitle,
  toggleSaveDialog,
}: IUserSettingsFormProps) => {
  const { hydrateSettings, settings, updateSettings } = React.useContext(
    ApiContext
  );
  const { addToast } = useToasts();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [errors, setErrors] = React.useState<{ title?: FieldError }>({});
  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const title = inputRef.current?.value;
      if (!title || !settings) {
        setErrors({
          title: {
            type: "CustomError",
            message: "Dit veld is verplicht",
          },
        });
        return;
      }
      setErrors({});
      const { schemaTableConfigs = {} } = settings;
      const schemaTitleConfigs = schemaTableConfigs[schemaTitle] || [];
      updateSettings({
        ...settings,
        schemaTableConfigs: {
          ...schemaTableConfigs,
          [schemaTitle]: [
            ...schemaTitleConfigs,
            {
              ...schemaTableConfig,
              title,
            },
          ],
        },
      })
        .then(() => {
          addToast(`De tabelconfiguratie is opgeslagen`, {
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((err: AxiosError<Components.Responses.ApiError>) => {
          const { response } = err;
          let message = err.message
            ? t(err.message)
            : "Opslaan tabelconfiguratie mislukt";
          if (response && response.data) {
            // eslint-disable-next-line prefer-destructuring
            message = t(response.data.message);
          }

          addToast(message, {
            appearance: "error",
            autoDismiss: true,
          });
        });
      toggleSaveDialog();
    },
    [
      addToast,
      schemaTableConfig,
      schemaTitle,
      settings,
      toggleSaveDialog,
      updateSettings,
    ]
  );
  React.useEffect(() => {
    if (settings === undefined) {
      hydrateSettings();
    }
  }, [hydrateSettings, settings]);
  React.useEffect(() => {
    // current property is referred to input element
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>Instellingen als favoriet opslaan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="naam">
          <Form.Label>Naam</Form.Label>
          <Form.Control
            type="text"
            name="title"
            placeholder="Naam favoriet"
            required={true}
            ref={inputRef}
            isInvalid={!!errors.title}
          />
          {errors.title ? (
            <Form.Control.Feedback type="invalid">
              {errors.title.message}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit">
          Instellingen opslaan
        </Button>
      </Modal.Footer>
    </Form>
  );
};
