import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FieldError } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IEmailFormControlProps {
  disabled?: boolean;
  error?: FieldError;
  name?: string;
  value?: number;
  onChange: (newValue?: string) => void;
}

export default ({
  disabled,
  error,
  name,
  value,
  onChange,
}: IEmailFormControlProps) => (
  <InputGroup style={{ display: "flex" }}>
    <Form.Control
      disabled={disabled}
      type="email"
      name={name}
      defaultValue={value}
      isInvalid={!!error}
      onChange={(e) => {
        onChange(e.currentTarget.value);
      }}
    />
    <div className="input-group-append">
      <a
        className="input-group-text"
        href={`mailto:${value}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon="envelope-open-text" />
      </a>
    </div>
  </InputGroup>
);
