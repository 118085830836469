import { Button, Spinner } from "react-bootstrap";
import React from "react";
import { ajv, stxt as t } from "shared.wenckebachfonds.nl";
import axios, { AxiosError } from "axios";
import { useToasts } from "react-toast-notifications";

interface IForgotPasswordProps {
  username: string;
}

export default ({ username }: IForgotPasswordProps) => {
  const { addToast } = useToasts();
  const [inProgress, setInProgress] = React.useState(false);
  const target = React.useRef(null);
  const emailAddressValid = ajv.validate(
    {
      type: "string",
      format: "email",
    },
    username
  ) as boolean;
  if (inProgress) {
    return (
      <Spinner animation="border" role="status" className="ml-auto">
        <span className="sr-only">Bezig met aanvragen...</span>
      </Spinner>
    );
  }
  return (
    <Button
      disabled={!emailAddressValid || inProgress}
      className="ml-auto"
      variant="link"
      title={`U ontvangt een email met een nieuw wachtwoord`}
      ref={target}
      onClick={() => {
        setInProgress(true);
        axios
          .request({
            method: "post",
            url: "/auth/passwordReset",
            data: {
              username,
            },
          })
          .then(() => {
            setInProgress(false);
            addToast(
              "Indien uw e-mailadres bekend is, heeft u een e-mail met gegevens ontvangen.",
              {
                appearance: "success",
                autoDismiss: true,
              }
            );
          })
          .catch((err: AxiosError) => {
            setInProgress(false);
            addToast(
              t(err.response?.data.message) ||
                t(err.message) ||
                "Aanvraag mislukt",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          });
      }}
    >
      Wachtwoord vergeten?
    </Button>
  );
};
