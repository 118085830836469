import { Button } from "react-bootstrap";
import { saveAs } from "file-saver";
import React from "react";
import { useToasts } from "react-toast-notifications";
import { Parser, transforms } from "json2csv";
import { flatten } from "flat";
import { TColumnsConfig, TExtraExportColumnDecoratorConfig } from "../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IExportButtonProps {
  columnsConfig: TColumnsConfig;
  extraColumns?: TExtraExportColumnDecoratorConfig;
  results: {}[];
}

export default ({
  columnsConfig,
  extraColumns = {},
  results,
}: IExportButtonProps) => {
  const { addToast } = useToasts();
  return (
    <Button
      onClick={() => {
        try {
          // Get all list of __ALL__ fields nested throughout the row data
          const fieldHash = results.reduce<{ [field: string]: any }>(
            (prev, row) => ({ ...prev, ...flatten(row) }),
            {}
          );

          const fields = Object.keys(fieldHash)
            .sort()
            .filter(
              // only show visible columns in the SchemaTable
              (field) =>
                extraColumns[field] ||
                columnsConfig.findIndex((columnConfig) =>
                  // match e.g. "part.calculation", but not "parts"
                  `${field}.`.startsWith(`${columnConfig.propName}.`)
                ) >= 0
            );
          const parser = new Parser({
            fields: [...fields, ...Object.keys(extraColumns)],
            transforms: [
              transforms.flatten({ arrays: true, objects: true }),
              (row) => {
                const transformedItem = { ...row };
                if (transformedItem["part.calculation.amount"]) {
                  transformedItem[
                    "part.calculation.amount"
                  ] = new Intl.NumberFormat("nl-NL", {
                    minimumFractionDigits: 2,
                  }).format(transformedItem["part.calculation.amount"]);
                }
                return transformedItem;
              },
            ],
            delimiter: ";",
          });
          const csv = parser.parse(
            results.map((result) => {
              const decoratedResult = { ...result } as any;
              Object.keys(extraColumns).forEach((extraColumn) => {
                decoratedResult[extraColumn] = extraColumns[extraColumn](
                  result
                );
              });
              return decoratedResult;
            })
          );
          if (!csv) {
            addToast("Deze export is leeg", {
              appearance: "warning",
              autoDismiss: true,
            });
            return;
          }

          saveAs(
            new Blob([csv], {
              type: "text/csv;charset=utf-8",
            }),
            "export.csv"
          );
        } catch (err) {
          addToast(err.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }}
    >
      <FontAwesomeIcon icon="file-csv" className="mr-2" />
      Exporteer .csv
    </Button>
  );
};
