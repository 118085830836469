import { Button, Modal } from "react-bootstrap";
import SchemaTable from "../../../SchemaTable";
import React from "react";
import { ApiContext } from "../../../../provider/ApiProvider";
import { useParams } from "react-router-dom";
import {
  Components,
  getSchemaFromClaimPartType,
} from "shared.wenckebachfonds.nl";
import { ClaimPart } from "../../../../types/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPreviousApprovedClaims } from "../../../../inc/claim";
import { getInitialSchemaConfig } from "../../../SchemaTable/config";

interface ITypeLabelProps {
  claimPartType: string;
  rapCode?: number;
}

type TRapCodeLabelRow = ClaimPart & {
  claimId: string;
};

export default ({ claimPartType, rapCode }: ITypeLabelProps) => {
  const { claims } = React.useContext(ApiContext);
  const { id } = useParams<{ id: string }>();
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const claim: Components.Schemas.Claim | undefined = React.useMemo(() => {
    if (!claims) {
      return undefined;
    }
    return claims.find((claim) => claim.id === id);
  }, [claims, id]);
  const previousUsedClaimParts: TRapCodeLabelRow[] = React.useMemo(
    () =>
      claims && claim && rapCode
        ? getPreviousApprovedClaims(claim, claims).reduce<TRapCodeLabelRow[]>(
            (prev, claim) => {
              claim.parts.forEach((part: ClaimPart) => {
                if (part.calculation?.rapCode === rapCode) {
                  prev.push({
                    createdAt: claim.createdAt,
                    claimId: claim.id as string,
                    ...part,
                  });
                }
              });
              return prev;
            },
            []
          )
        : [],
    [claims, claim, rapCode]
  );
  const claimPartSchema = getSchemaFromClaimPartType(claimPartType);
  if (!previousUsedClaimParts.length) {
    return <React.Fragment>RAP code</React.Fragment>;
  }
  return (
    <div className="mb-1">
      RAP code
      <Button className="ml-1" size="sm" onClick={handleShow}>
        <FontAwesomeIcon icon="search" className="mr-1" />
        {previousUsedClaimParts.length} eerdere{" "}
        {previousUsedClaimParts.length === 1 ? "aanvraag" : "aanvragen"}
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            Eerdere RAP code-gelijke, geaccepteerde onderdelen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SchemaTable
            isConfigurable={true}
            isFilterable={true}
            schema={{
              ...claimPartSchema,
              properties: {
                createdAt: {
                  description: "Gevraagd op",
                  type: "string",
                  format: "date-time",
                  readOnly: true,
                },
                ...claimPartSchema.properties,
              },
            }}
            schemaTitle="ClaimPart"
            renderAction={(row: TRapCodeLabelRow) => (
              <div className="my-1">
                <a
                  href={`/admin/claim/${row.claimId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  Open
                </a>
              </div>
            )}
            config={getInitialSchemaConfig("ClaimPart")}
            data={previousUsedClaimParts}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
