import React from "react";
import axios from "axios";
import { addStxt, Components, stxt as t } from "shared.wenckebachfonds.nl";
import { useToasts } from "react-toast-notifications";
import { IUserSettings } from "../types/api";

interface IApiContext {
  claimClaim: (claim: Components.Schemas.Claim) => Promise<void>;
  claims?: Components.Schemas.Claim[] | null;
  paymentChecksums?: Components.Schemas.LoggedPayment[] | null;
  deleteClaim: (id: string) => Promise<void>;
  deleteMyClaim: (token: string) => Promise<void>;
  deleteUser: (id: string) => Promise<void>;
  hydrateClaims: () => void;
  hydrateMyClaim: (token: string) => Promise<void>;
  hydrateSettings: () => void;
  hydratePaymentChecksums: () => void;
  hydrateUsers: () => void;
  myClaim?: Components.Schemas.Claim | null;
  settings?: IUserSettings | null;
  rejectClaim: (
    claim: Components.Schemas.Claim,
    message: string
  ) => Promise<void>;
  requestNewLink: (token: string) => Promise<void>;
  restartEvaluation: (claim: Components.Schemas.Claim) => Promise<void>;
  setMyClaim: (claim: Components.Schemas.Claim) => void;
  startAccept: (claim: Components.Schemas.Claim) => Promise<void>;
  startEvaluation: (claim: Components.Schemas.Claim) => Promise<void>;
  startFinalReview: (claim: Components.Schemas.Claim) => Promise<void>;
  updateClaim: (
    claim: Components.Schemas.Claim
  ) => Promise<Components.Schemas.Claim>;
  updateLoggedPayment: (pay: Components.Schemas.LoggedPayment) => void;
  updateMyClaim: (
    claim: Components.Schemas.Claim,
    token: string
  ) => Promise<Components.Schemas.Claim>;
  updateSettings: (
    settings: Components.Schemas.UserSettings
  ) => Promise<Components.Schemas.UserSettings>;
  updateUser: (user: Components.Schemas.User) => Promise<void>;
  users?: Components.Schemas.User[] | null;
}

export const ApiContext = React.createContext<IApiContext>({
  claimClaim: () => Promise.resolve(),
  deleteClaim: () => Promise.resolve(),
  deleteMyClaim: () => Promise.resolve(),
  deleteUser: () => Promise.resolve(),
  hydrateClaims: () => {},
  hydrateMyClaim: () => Promise.resolve(),
  hydrateSettings: () => {},
  hydratePaymentChecksums: () => {},
  hydrateUsers: () => {},
  rejectClaim: () => Promise.resolve(),
  requestNewLink: () => Promise.resolve(),
  restartEvaluation: () => Promise.resolve(),
  setMyClaim: () => {},
  updateLoggedPayment: () => {},
  startAccept: () => Promise.resolve(),
  startEvaluation: () => Promise.resolve(),
  startFinalReview: () => Promise.resolve(),
  updateClaim: () => Promise.resolve({} as Components.Schemas.Claim),
  updateMyClaim: () => Promise.resolve({} as Components.Schemas.Claim),
  updateSettings: () => Promise.resolve({} as Components.Schemas.UserSettings),
  updateUser: () => Promise.resolve(),
});

const ApiProvider = ({ children }: any) => {
  const { addToast } = useToasts();
  const [users, setUsers] = React.useState<Components.Schemas.User[] | null>();
  const [settings, setSettings] = React.useState<IUserSettings | null>();
  const [claims, setClaims] = React.useState<
    Components.Schemas.Claim[] | null
  >();
  const [paymentChecksums, setPaymentChecksums] = React.useState<
    Components.Schemas.LoggedPayment[] | null
  >();
  const [
    myClaim,
    setMyClaim,
  ] = React.useState<Components.Schemas.Claim | null>();
  const hydrateUsers = React.useCallback(() => {
    setUsers(null);
    axios
      .get<Components.Schemas.User[]>("/user")
      .then((res) => {
        res.data.forEach((user) => {
          if (user.id) {
            addStxt(user.id, user.name);
          }
        });
        setUsers(res.data);
      })
      .catch((err) => {
        const message = err.response?.data.message || err.message;
        addToast(
          `Ophalen gebruikers mislukt${message ? `: ${t(message)}` : ""}`,
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      });
  }, [addToast]);

  const updateUser = React.useCallback((user: Components.Schemas.User) => {
    const isNew = !user.id;
    return axios
      .request<Components.Schemas.User>({
        method: isNew ? "post" : "put",
        url: `/user${isNew ? "" : `/${user.id}`}`,
        data: user,
      })
      .then((res) => {
        const updatedUser = res.data;
        if (isNew) {
          setUsers((users) => (users ? [...users, updatedUser] : [user]));
          return;
        }
        setUsers((users) =>
          users
            ? users.map((user) =>
                user.id === updatedUser.id ? updatedUser : user
              )
            : [user]
        );
      });
  }, []);

  const deleteUser = React.useCallback(
    (id: string) =>
      axios.delete<Components.Schemas.User>(`/user/${id}`).then(() => {
        setUsers((users) =>
          users ? users.filter((user) => user.id !== id) : []
        );
      }),
    []
  );

  const hydrateClaims = React.useCallback(() => {
    setClaims(null);
    axios
      .get<Components.Schemas.Claim[]>("/claim")
      .then((res) => {
        setClaims(res.data);
      })
      .catch((err) => {
        const message = err.response?.data.message || err.message;
        addToast(`Ophalen claims mislukt${message ? `: ${t(message)}` : ""}`, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  }, [addToast]);

  const hydratePaymentChecksums = React.useCallback(() => {
    setPaymentChecksums(null);
    axios
      .get<Components.Schemas.LoggedPayment[]>("/payment/checksum")
      .then((res) => {
        setPaymentChecksums(res.data.reverse().slice(0, 10));
      })
      .catch((err) => {
        const message = err.response?.data.message || err.message;
        addToast(
          `Ophalen betaalbestanden geschiedenis mislukt${
            message ? `: ${t(message)}` : ""
          }`,
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      });
  }, [addToast]);

  const claimClaim = React.useCallback(
    (claim: Components.Schemas.Claim) =>
      axios
        .post<Components.Schemas.Claim>(`/claim/${claim.id}/claim`)
        .then((res) => {
          const updatedClaim = res.data;
          setClaims((claims) =>
            claims
              ? claims.map((claim) =>
                  claim.id === updatedClaim.id ? updatedClaim : claim
                )
              : [updatedClaim]
          );
        }),
    []
  );

  const rejectClaim = React.useCallback(
    (claim: Components.Schemas.Claim, message: string) =>
      axios
        .post<Components.Schemas.Claim>(`/claim/${claim.id}/reject`, {
          message,
        })
        .then((res) => {
          const updatedClaim = res.data;
          setClaims((claims) =>
            claims
              ? claims.map((claim) =>
                  claim.id === updatedClaim.id ? updatedClaim : claim
                )
              : [updatedClaim]
          );
        }),
    []
  );

  const hydrateSettings = React.useCallback(() => {
    setSettings(null);
    return axios
      .get<IUserSettings>("/settings/mine")
      .then((res) => {
        setSettings(res.data);
      })
      .catch((err) => {
        const message = err.response?.data.message || err.message;
        addToast(
          `Ophalen voorkeuren mislukt${message ? `: ${t(message)}` : ""}`,
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      });
  }, [addToast]);

  const updateSettings = React.useCallback(
    (
      settings: Components.Schemas.UserSettings
    ): Promise<Components.Schemas.UserSettings> =>
      axios
        .post<Components.Schemas.UserSettings>(`/settings/mine`, settings)
        .then((res) => {
          const updatedSettings = res.data;
          setSettings(updatedSettings);
          return updatedSettings;
        }),
    []
  );

  const startEvaluation = React.useCallback(
    (claim: Components.Schemas.Claim) =>
      axios
        .post<Components.Schemas.Claim>(
          `/claim/${claim.id}/startEvaluation`,
          claim
        )
        .then((res) => {
          const updatedClaim = res.data;
          setClaims((claims) =>
            claims
              ? claims.map((claim) =>
                  claim.id === updatedClaim.id ? updatedClaim : claim
                )
              : [updatedClaim]
          );
        }),
    []
  );

  const restartEvaluation = React.useCallback(
    (claim: Components.Schemas.Claim) =>
      axios
        .post<Components.Schemas.Claim>(
          `/claim/${claim.id}/restartEvaluation`,
          claim
        )
        .then((res) => {
          const updatedClaim = res.data;
          setClaims((claims) =>
            claims
              ? claims.map((claim) =>
                  claim.id === updatedClaim.id ? updatedClaim : claim
                )
              : [updatedClaim]
          );
        }),
    []
  );

  const startFinalReview = React.useCallback(
    (claim: Components.Schemas.Claim) =>
      axios
        .post<Components.Schemas.Claim>(
          `/claim/${claim.id}/startFinalReview`,
          claim
        )
        .then((res) => {
          const updatedClaim = res.data;
          setClaims((claims) =>
            claims
              ? claims.map((claim) =>
                  claim.id === updatedClaim.id ? updatedClaim : claim
                )
              : [updatedClaim]
          );
        }),
    []
  );

  const startAccept = React.useCallback(
    (claim: Components.Schemas.Claim) =>
      axios
        .post<Components.Schemas.Claim>(`/claim/${claim.id}/approve`, claim)
        .then((res) => {
          const updatedClaim = res.data;
          setClaims((claims) =>
            claims
              ? claims.map((claim) =>
                  claim.id === updatedClaim.id ? updatedClaim : claim
                )
              : [updatedClaim]
          );
        }),
    []
  );

  const updateClaim = React.useCallback(
    (claim: Components.Schemas.Claim): Promise<Components.Schemas.Claim> => {
      const isNew = !claim.id;
      return axios
        .request<Components.Schemas.Claim>({
          method: isNew ? "post" : "put",
          url: `/claim${isNew ? "" : `/${claim.id}`}`,
          data: claim,
        })
        .then((res) => {
          const updatedClaim = res.data;
          setClaims((claims) =>
            isNew
              ? claims
                ? [...claims, updatedClaim]
                : [claim]
              : claims
              ? claims.map((claim) =>
                  claim.id === updatedClaim.id ? updatedClaim : claim
                )
              : [updatedClaim]
          );
          return updatedClaim;
        });
    },
    []
  );

  const hydrateMyClaim = React.useCallback((token) => {
    setMyClaim(null);
    return axios
      .get("/claim/mine", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setMyClaim(res.data);
      });
  }, []);

  const updateMyClaim = React.useCallback(
    (claim: Components.Schemas.Claim, token: string) =>
      axios
        .request<Components.Schemas.Claim>({
          method: "put",
          url: `/claim/mine`,
          data: claim,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => res.data),
    []
  );

  const deleteMyClaim = React.useCallback(
    (token) =>
      axios
        .delete("/claim/mine", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setMyClaim(undefined);
          return res.data;
        }),
    []
  );

  const requestNewLink = React.useCallback(
    (token) =>
      axios
        .post(
          "/claim/requestNewLink",
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => res.data),
    []
  );

  const deleteClaim = React.useCallback(
    (id: string) =>
      axios.delete<Components.Schemas.Claim>(`/claim/${id}`).then(() => {
        setClaims((claims) =>
          claims ? claims.filter((claim) => claim.id !== id) : []
        );
      }),
    []
  );

  const updateLoggedPayment = React.useCallback(
    (
      loggedPayment: Components.Schemas.LoggedPayment
    ): Promise<Components.Schemas.LoggedPayment> =>
      axios.post(`/payment/checksum`, loggedPayment).then((res) => {
        const updatedLoggedPayment = res.data;
        setPaymentChecksums((loggedPayments) => [
          updatedLoggedPayment,
          ...loggedPayments,
        ]);
        return updatedLoggedPayment;
      }),
    []
  );

  return (
    <ApiContext.Provider
      value={{
        claimClaim,
        claims,
        paymentChecksums,
        deleteClaim,
        deleteMyClaim,
        deleteUser,
        hydrateClaims,
        hydrateMyClaim,
        hydrateSettings,
        hydrateUsers,
        hydratePaymentChecksums,
        myClaim,
        settings,
        rejectClaim,
        requestNewLink,
        restartEvaluation,
        setMyClaim,
        startAccept,
        startEvaluation,
        startFinalReview,
        updateClaim,
        updateLoggedPayment,
        updateMyClaim,
        updateSettings,
        updateUser,
        users,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;
