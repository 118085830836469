import React from "react";
import { Overlay, Popover, Table } from "react-bootstrap";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IPreviousValue {
  date?: string;
  propertyName: string;
  value: any;
  claimId?: string;
}

interface ITrafficLightPopoverProps {
  previouslyDifferentValues: IPreviousValue[];
  setValue: (propName: string, propValue: any) => void;
}

export default ({
  previouslyDifferentValues,
  setValue,
}: ITrafficLightPopoverProps) => {
  const [show, setShow] = React.useState(false);
  const buttonRef = React.useRef(null);
  const popoverRef = React.useRef(null);
  React.useEffect(() => {
    function onMouseUp(event: MouseEvent) {
      // @ts-ignore
      if (popoverRef.current && popoverRef.current.contains(event.target)) {
        return;
      }
      setShow(false);
    }

    // Bind the event listener
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [buttonRef]);
  return (
    <>
      <FontAwesomeIcon
        role="button"
        icon="traffic-light"
        className="mr-2 text-danger"
        forwardedRef={buttonRef}
        onClick={() => setShow(!show)}
      />
      <Overlay target={buttonRef.current} show={show}>
        {(props) => (
          <Popover
            id={"trafficLight"}
            {...props}
            style={{
              ...props.style,
              maxWidth: "80%",
            }}
            // @ts-ignore
            ref={(ref) => {
              popoverRef.current = ref;
              props.ref(ref);
            }}
          >
            <Popover.Title as="h3">
              Eerdere waardes op geaccepteerde aanvragen
            </Popover.Title>
            <Popover.Content>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th>Waarde</th>
                    <th>Aanvraag ID</th>
                  </tr>
                </thead>
                <tbody>
                  {previouslyDifferentValues.map(
                    (previouslyDifferentValue, index) => (
                      <tr key={index}>
                        <td>
                          {previouslyDifferentValue.date
                            ? format(
                                new Date(previouslyDifferentValue.date),
                                "dd-MM-yyyy"
                              )
                            : ""}
                        </td>
                        <td>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setValue(
                                previouslyDifferentValue.propertyName,
                                previouslyDifferentValue.value
                              );
                              setShow(false);
                            }}
                          >
                            {previouslyDifferentValue.value}
                          </button>
                        </td>
                        <td>
                          <a
                            href={`/admin/claim/${previouslyDifferentValue.claimId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {previouslyDifferentValue.claimId}
                          </a>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </Popover.Content>
          </Popover>
        )}
      </Overlay>
    </>
  );
};
