import React from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import { AuthContext } from "../../../provider/AuthProvider";
import { ApiContext } from "../../../provider/ApiProvider";
import difference from "lodash/difference";
import {
  EFilterCompare,
  ISchemaTableConfig,
} from "../../../components/SchemaTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getInitialSchemaConfig } from "../../../components/SchemaTable/config";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from "recharts";
import { openapi, stxt as t } from "shared.wenckebachfonds.nl";

const [, parentSchema] = openapi.components.schemas.Claim.allOf;
const colors = [
  "#007bff",
  "#6c757d",
  "#28a745",
  "#dc3545",
  "#ffc107",
  "#17a2b8",
  "#bababa",
];

export default () => {
  const { claims, hydrateClaims } = React.useContext(ApiContext);
  const { jwtUser } = React.useContext(AuthContext);
  React.useEffect(() => {
    if (claims === undefined) {
      hydrateClaims();
    }
  }, [hydrateClaims, claims]);
  const history = useHistory();
  if (!jwtUser || !claims) {
    return <Spinner animation={"border"} />;
  }
  const newClaimAantal = claims.reduce(
    (prev, claim) => prev + (!claim.status || claim.status === "open" ? 1 : 0),
    0
  );
  const yourClaimAantal = claims.reduce(
    (prev, claim) =>
      prev +
      (claim.status &&
      claim.status === "intake" &&
      claim.adminUserId === jwtUser.id
        ? 1
        : 0),
    0
  );
  const rejectedToBeConfirmedAantal = claims.reduce(
    (prev, claim) =>
      prev +
      (claim.status &&
      claim.status === "rejectedUnconfirmed" &&
      claim.adminUserId === jwtUser.id
        ? 1
        : 0),
    0
  );
  const evaluatingAantal = claims.reduce(
    (prev, claim) =>
      prev +
      (claim.status &&
      claim.status === "evaluating" &&
      claim.evaluatorUserId === jwtUser.id
        ? 1
        : 0),
    0
  );
  const reviewingAantal = claims.reduce(
    (prev, claim) =>
      prev +
      (claim.status &&
      claim.status === "finalReviewing" &&
      claim.finalReviewerUserId === jwtUser.id
        ? 1
        : 0),
    0
  );

  const config = getInitialSchemaConfig("Claim");
  const { columnsConfig = [] } = config;
  const visibleColumnNames = columnsConfig.map(
    (columnConfig) => columnConfig.propName
  );

  return (
    <div className="flex-grow-1">
      {jwtUser.role === "administrator" && (
        <Row>
          <Col xs={4}>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => {
                const schemaTableConfigPreset: Partial<ISchemaTableConfig> = {
                  ...config,
                  columnsConfig: [
                    ...columnsConfig,
                    ...difference(
                      ["status"],
                      visibleColumnNames
                    ).map((propName) => ({ propName })),
                  ],
                  filtersConfig: {
                    status: [
                      {
                        compare: EFilterCompare.EQUALS,
                        value: "open",
                      },
                    ],
                  },
                };
                history.push("/admin/claims", {
                  schemaTableConfigPreset,
                });
              }}
            >
              <Card.Body>
                <div className="p-10 no-block text-center">
                  <div className="align-slef-center">
                    <h2 className="m-b-0">{newClaimAantal}</h2>
                    <h6 className="text-muted m-b-25">Nieuwe claims</h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={4}>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => {
                const schemaTableConfigPreset: Partial<ISchemaTableConfig> = {
                  ...config,
                  columnsConfig: [
                    ...columnsConfig,
                    ...difference(
                      ["adminUserId", "status"],
                      visibleColumnNames
                    ).map((propName) => ({ propName })),
                  ],
                  filtersConfig: {
                    status: [
                      {
                        compare: EFilterCompare.EQUALS,
                        value: "intake",
                      },
                    ],
                    adminUserId: [
                      {
                        compare: EFilterCompare.EQUALS,
                        value: jwtUser.id as string,
                      },
                    ],
                  },
                };
                history.push("/admin/claims", { schemaTableConfigPreset });
              }}
            >
              <Card.Body>
                <div className="p-10 no-block text-center">
                  <div className="align-slef-center">
                    <h2 className="m-b-0">{yourClaimAantal}</h2>
                    <h6 className="text-muted m-b-25">
                      Door jou in behandeling
                    </h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={4}>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => {
                const schemaTableConfigPreset: Partial<ISchemaTableConfig> = {
                  ...config,
                  columnsConfig: [
                    ...columnsConfig,
                    ...difference(
                      ["adminUserId", "status"],
                      visibleColumnNames
                    ).map((propName) => ({ propName })),
                  ],
                  filtersConfig: {
                    status: [
                      {
                        compare: EFilterCompare.EQUALS,
                        value: "rejectedUnconfirmed",
                      },
                    ],
                    adminUserId: [
                      {
                        compare: EFilterCompare.EQUALS,
                        value: jwtUser.id as string,
                      },
                    ],
                  },
                };
                history.push("/admin/claims", {
                  schemaTableConfigPreset,
                });
              }}
            >
              <Card.Body>
                <div className="p-10 no-block text-center">
                  <div className="align-slef-center">
                    <h2 className="m-b-0">{rejectedToBeConfirmedAantal}</h2>
                    <h6 className="text-muted m-b-25">
                      Afgewezen, te bevestigen
                    </h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {["administrator", "reviewer"].indexOf(jwtUser.role) >= 0 && (
        <Row>
          <Col xs={4}>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => {
                const schemaTableConfigPreset: Partial<ISchemaTableConfig> = {
                  ...config,
                  columnsConfig: [
                    ...columnsConfig,
                    ...difference(
                      ["evaluatorUserId", "status"],
                      visibleColumnNames
                    ).map((propName) => ({ propName })),
                  ],
                  filtersConfig: {
                    status: [
                      {
                        compare: EFilterCompare.EQUALS,
                        value: "evaluating",
                      },
                    ],
                    evaluatorUserId: [
                      {
                        compare: EFilterCompare.EQUALS,
                        value: jwtUser.id as string,
                      },
                    ],
                  },
                };
                history.push("/admin/claims", {
                  schemaTableConfigPreset,
                });
              }}
            >
              <Card.Body>
                <div className="p-10 no-block text-center">
                  <div className="align-slef-center">
                    <h2 className="m-b-0">{evaluatingAantal}</h2>
                    <h6 className="text-muted m-b-25">Te evalueren claims</h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={4}>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => {
                const schemaTableConfigPreset: Partial<ISchemaTableConfig> = {
                  ...config,
                  columnsConfig: [
                    ...columnsConfig,
                    ...difference(
                      ["finalReviewerUserId", "status"],
                      visibleColumnNames
                    ).map((propName) => ({ propName })),
                  ],
                  filtersConfig: {
                    status: [
                      {
                        compare: EFilterCompare.EQUALS,
                        value: "finalReviewing",
                      },
                    ],
                    finalReviewerUserId: [
                      {
                        compare: EFilterCompare.EQUALS,
                        value: jwtUser.id as string,
                      },
                    ],
                  },
                };
                history.push("/admin/claims", {
                  schemaTableConfigPreset,
                });
              }}
            >
              <Card.Body>
                <div className="p-10 no-block text-center">
                  <div className="align-slef-center">
                    <h2 className="m-b-0">{reviewingAantal}</h2>
                    <h6 className="text-muted m-b-25">Te controleren claims</h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <div className="row">
        <div className="col-md-12">
          <div className="mx-auto" style={{ width: 850 }}>
            <Card style={{ cursor: "pointer" }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="d-flex m-b-10 no-block">
                      <h5 className="card-title m-b-0 align-self-center text-uppercase">
                        Claims per status ({claims.length})
                      </h5>
                    </div>
                    <ul className="list-inline m-b-10 text-uppercase lp-5 font-medium font-12">
                      {parentSchema.properties.status.enum.map(
                        (status: string, statusIndex: number) => (
                          <li key={status}>
                            <FontAwesomeIcon
                              icon="square"
                              className="mr-2"
                              style={{ color: colors[statusIndex] }}
                            />
                            {t(status)}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="clearfix" />
                <BarChart
                  width={800}
                  height={400}
                  data={parentSchema.properties.status.enum.map(
                    (status: string) => ({
                      name: t(status),
                      status,
                      Aantal: claims.filter((claim) => claim.status === status)
                        .length,
                    })
                  )}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" hide={true} />
                  <YAxis
                    scale="log"
                    domain={[0.01, claims.length]}
                    allowDataOverflow
                  />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="Aantal"
                    onClick={({ payload }) => {
                      const schemaTableConfigPreset: Partial<ISchemaTableConfig> = {
                        ...config,
                        columnsConfig: [
                          ...columnsConfig,
                          ...difference(
                            ["status"],
                            visibleColumnNames
                          ).map((propName) => ({ propName })),
                        ],
                        filtersConfig: {
                          status: [
                            {
                              compare: EFilterCompare.EQUALS,
                              value: payload.status,
                            },
                          ],
                        },
                      };
                      history.push("/admin/claims", {
                        schemaTableConfigPreset,
                      });
                    }}
                  >
                    {parentSchema.properties.status.enum.map(
                      (status: string, statusIndex: number) => (
                        <Cell
                          key={`cell-${statusIndex}`}
                          fill={colors[statusIndex % 20]}
                        />
                      )
                    )}
                  </Bar>
                </BarChart>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
