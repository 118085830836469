import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import History from "./pages/History";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import { AuthContext } from "./provider/AuthProvider";
import ScrollToTop from "./components/ScrollToTop";
import { buildIconLibrary } from "./inc/fontawesome";

const PrivateRoute = ({ children, ...rest }: any) => {
  const { isLoggedIn } = React.useContext(AuthContext);
  // no, this can not be simplified
  // noinspection PointlessBooleanExpressionJS
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const LoginPage = () => {
  const { isLoggedIn } = React.useContext(AuthContext);
  const history = useHistory();
  const location = useLocation<{ from: string }>();
  const { from } = location.state || {
    from: { pathname: "/admin" },
  };
  const loginsuccessful = isLoggedIn();
  React.useEffect(() => {
    if (loginsuccessful) {
      history.replace(from || "/admin");
    }
  }, [from, history, loginsuccessful]);
  return <Login />;
};

buildIconLibrary();

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path="/admin">
          <Admin />
        </PrivateRoute>
        <Route path="/history/:public_edit_token?" component={History} />
        <Route path="/:public_edit_token?" component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
