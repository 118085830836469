import { Button, Dropdown, Modal, Spinner, Table } from "react-bootstrap";
import React from "react";
import { ApiContext } from "../../../../provider/ApiProvider";
import { AuthContext } from "../../../../provider/AuthProvider";
import { AxiosError } from "axios";
import { stxt as t } from "shared.wenckebachfonds.nl/dist/stxt";
import { useToasts } from "react-toast-notifications";
import { ISchemaTableConfig } from "../../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Components } from "shared.wenckebachfonds.nl";

export interface ILoadButtonProps {
  schemaTitle: "Claim" | "ClaimPart" | "UngroupedClaim" | "ToBePaidClaims";
  setConfig: (config: Partial<ISchemaTableConfig>) => void;
}

export const LoadButton = ({ schemaTitle, setConfig }: ILoadButtonProps) => {
  const { addToast } = useToasts();
  const { hydrateSettings, settings, users, updateSettings } = React.useContext(
    ApiContext
  );
  const { jwtUser } = React.useContext(AuthContext);
  const [isLoadDialogOpen, setIsLoadDialogOpen] = React.useState(false);
  const toggleLoadDialog = React.useCallback(() => {
    setIsLoadDialogOpen((open) => !open);
  }, []);
  React.useEffect(() => {
    if (settings === undefined) {
      hydrateSettings();
    }
  }, [hydrateSettings, settings]);

  if (!users || !jwtUser || !settings) {
    return <Spinner animation="border" />;
  }
  const user = users.find((user) => user.id === jwtUser.id);
  if (!user) {
    return null;
  }
  const { schemaTableConfigs = {} } = settings;
  const applicableSchemaTableConfigs: ISchemaTableConfig[] =
    schemaTableConfigs[schemaTitle] || [];
  return (
    <>
      <Dropdown.Item
        size="sm"
        onClick={toggleLoadDialog}
        disabled={!applicableSchemaTableConfigs.length}
      >
        <span
          className="fa-layers fa-fw mr-1 text-warning"
          title="Favorieten openen"
        >
          <FontAwesomeIcon icon="bookmark" fixedWidth className="mr-1" />
          <FontAwesomeIcon
            icon="search"
            className="text-body"
            transform="shrink-4 down-1 right-5"
          />
        </span>
        Favorieten openen
      </Dropdown.Item>

      <Modal show={isLoadDialogOpen} onHide={toggleLoadDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Overzicht openen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Naam</th>
                <th style={{ width: 130 }} />
              </tr>
            </thead>
            <tbody>
              {applicableSchemaTableConfigs.map(
                (selectedConfiguration, index) => (
                  <tr key={index}>
                    <td>{selectedConfiguration.title}</td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          // unserialize dates!!!
                          Object.keys(
                            selectedConfiguration.filtersConfig
                          ).forEach((filteredProp) => {
                            selectedConfiguration.filtersConfig[
                              filteredProp
                            ].forEach((filter) => {
                              if (
                                typeof filter.value === "string" &&
                                // stolen from https://www.myintervals.com/blog/2009/05/20/iso-8601-date-validation-that-doesnt-suck/
                                filter.value.match(
                                  /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/
                                )
                              ) {
                                filter.value = new Date(filter.value);
                              }
                            });
                          });
                          setConfig(selectedConfiguration);
                          toggleLoadDialog();
                        }}
                      >
                        Openen
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        className="ml-1"
                        onClick={() => {
                          if (
                            !user ||
                            !settings ||
                            !window.confirm(
                              "Weet je zeker dat je deze configuratie wilt verwijderen?"
                            )
                          ) {
                            return;
                          }
                          const { schemaTableConfigs = {} } = settings;
                          const applicableSchemaTableConfigs: ISchemaTableConfig[] =
                            schemaTableConfigs[schemaTitle] || [];
                          updateSettings({
                            schemaTableConfigs: {
                              ...schemaTableConfigs,
                              [schemaTitle]: applicableSchemaTableConfigs.filter(
                                (schemaTitleConfigs) =>
                                  schemaTitleConfigs.title !==
                                  selectedConfiguration.title
                              ),
                            },
                          })
                            .then(() => {
                              addToast(`De tabelconfiguratie is verwijderd`, {
                                appearance: "success",
                                autoDismiss: true,
                              });
                            })
                            .catch(
                              (
                                err: AxiosError<Components.Responses.ApiError>
                              ) => {
                                const { response } = err;
                                let message = err.message
                                  ? t(err.message)
                                  : "Opslaan tabelconfiguratie mislukt";
                                if (response && response.data) {
                                  // eslint-disable-next-line prefer-destructuring
                                  message = t(response.data.message);
                                }

                                addToast(message, {
                                  appearance: "error",
                                  autoDismiss: true,
                                });
                              }
                            );
                          toggleLoadDialog();
                        }}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </Button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={toggleLoadDialog}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
