import React from "react";
import { FieldError } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import ClaimPartForm from "../ClaimPartFormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Components } from "shared.wenckebachfonds.nl";

interface IClaimPartFormsProps {
  disabled?: boolean;
  error?: FieldError;
  name: string;
  value?: Components.Schemas.Claim["parts"];
  onChange: (newValue: Components.Schemas.ClaimPart[]) => void;
}

export default ({
  disabled,
  error,
  name,
  value,
  onChange,
}: IClaimPartFormsProps) => {
  if (!value) {
    return null;
  }
  return (
    <>
      {value.map((claimPart, index) => (
        <React.Fragment key={index}>
          <ClaimPartForm
            disabled={disabled}
            value={claimPart}
            onChange={(newClaimPart) => {
              const newClaimParts = value.map(
                (currentClaimPart, currentClaimPartIndex) =>
                  currentClaimPartIndex !== index
                    ? currentClaimPart
                    : newClaimPart
              );
              onChange(newClaimParts);
            }}
            name={`${name}[${index}]`}
            // @ts-ignore
            error={error && error[index] ? error[index] : undefined}
          />
          {value.length > 1 ? (
            <>
              <Button
                disabled={disabled}
                variant="danger"
                className="d-block ml-auto mb-4"
                onClick={() => {
                  onChange([
                    ...value.slice(0, index),
                    ...value.slice(index + 1),
                  ]);
                }}
              >
                <FontAwesomeIcon
                  icon="trash"
                  aria-hidden="true"
                  className="mr-2"
                />
                Aanvraag {index + 1} verwijderen
              </Button>
            </>
          ) : null}
        </React.Fragment>
      ))}
      {error && error.message ? (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      ) : null}
      <Button
        variant="secondary"
        className="mb-4"
        disabled={disabled}
        onClick={() => {
          onChange([...value, {}]);
        }}
      >
        Extra aanvraag toevoegen
      </Button>
    </>
  );
};
